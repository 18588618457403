export default {
  getMetrics: (state, getters) => {
    const metrics = [];
    state.entities.forEach((element) => {
      metrics.push({
        label: element.name.split("/")[1],
        field: element.name,
        type: "Select",
        icon: "phone",
      });
    });
    return metrics;
  },
  getCallMetricTypes: (state, getters) => {
    const callMetricTypes = state.entities.find((el) => el.name.includes("call"));
    function getIcon(el) {
      if (el.calculationType === "SUM") {
        return "add";
      }
      if (el.calculationType === "AVERAGE") {
        return "functions";
      }
      if (el.calculationType === "PERCENTAGE") {
        return "";
      }
    }
    const metricTypes = [];
    callMetricTypes?.metrics.forEach((element) => {
      metricTypes.push({
        label: element.displayName,
        field: element.name,
        calculationType: element.calculationType,
        type: "Select",
        icon: getIcon(element),
      });
    });
    return metricTypes;
  },
  getCallFilters: (state, getters) => {
    const callMetricTypes = state.entities.find((el) => el.name.includes("call"));
    const filters = [];
    callMetricTypes?.fields.forEach((element) => {
      if (element.filterable) {
        filters.push({
          label: element.displayName,
          field: element.fieldId,
          options: element.values,
          type: !element.fieldId.includes("state") ? element.dataType : "Multi",
        });
      }
    });
    return filters;
  },
  getCallGroups: (state, getters) => {
    const callMetricTypes = state.entities.find((el) => el.name.includes("call"));
    const filters = [];
    callMetricTypes?.fields.forEach((element) => {
      if (element.groupable) {
        filters.push({
          label: element.displayName,
          field: element.fieldId,
          options: element.values,
          type: !element.fieldId.includes("label") ? "Select" : "Map",
        });
      }
    });
    return filters;
  },
};
