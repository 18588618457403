import store from "@/store";
import axios from "axios";
const httpClient = axios;

const authInterceptor = async (config: any) => {
  // add auth token this is automatically cached and will refresh by its self
  // @ts-ignore

  // if there is no current user then force logout.
  if (!store.dispatch("user/getCurrentUser")) {
    return config;
  }
  const token = await store.dispatch("user/getToken");
  config.headers.Authorization = `Bearer ${token}`;
  return config;
};

const loggerInterceptor = (config: any) => {
  // Add logging here
  return config;
};

// Adding the request interceptors
httpClient.interceptors.request.use(authInterceptor);
httpClient.interceptors.request.use(loggerInterceptor);

// Adding the response interceptors
httpClient.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error) {
      if (error.response) {
        if (error.response.headers["grpc-message"] && error.response.headers["grpc-status"]) {
          return Promise.reject({
            error,
            code: error.response.headers["grpc-status"],
            message: error.response.headers["grpc-message"],
            details: [],
          });
        } else {
          // If there is a 404 error redirect the user to the 404 page
          if (error.response.status === 404) {
            // TODO: implement a better solution for handling 404 errors
            if (!location.href.includes("/insights")) {
              // router.push("/404");
            }
          }

          if (error.response.status === 403 || error.response.status === 401) {
            // router.push('/login');
          }
          // Return the error
          return Promise.reject({
            error,
            ...error.response.data,
          });
        }
      }
      return Promise.reject(error);
    }
  }
);

export { httpClient };
