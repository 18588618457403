import { voiceStateOptions, voiceDirectionOptions } from "@/consts/voice";

// Voice Routes
const CallsList = () => import("@/views/Calls/CallsList");
const CallsOverview = () => import("@/views/Calls/CallsOverview");
const CallsView = () => import("@/views/Calls/CallsView");
const CallsRecordingList = () => import("@/views/Calls/CallsRecordingList");

export default [
  {
    path: "/projects/:projectId/voice/overview",
    name: "Voice Overview",
    component: CallsOverview,
    meta: {
      pageTitle: "Voice overview",
      featureFlipping: {
        key: "calls.view",
        default: false,
      },
      headerImg: "./header-voice.png",
      layout: "LayoutMain",
      tabs: "voice",
      dispatchAction: "calls/initializeVoiceOverview",
    },
  },
  {
    path: "/projects/:projectId/voice/list",
    name: "Voice List",
    component: CallsList,
    meta: {
      pageTitle: "Calls",
      featureFlipping: {
        key: "calls.list",
        default: false,
      },
      headerImg: "./header-voice.png",
      layout: "LayoutMain",
      tabs: "voice",
      filters: [
        {
          label: "Date",
          field: "date",
          type: "Date",
        },
        {
          label: "To",
          field: "to",
          type: "String",
        },
        {
          label: "From",
          field: "from",
          type: "String",
        },
        {
          label: "State",
          field: "state",
          type: "Multi",
          options: voiceStateOptions,
        },
        {
          label: "Direction",
          field: "direction",
          type: "Enum",
          options: voiceDirectionOptions,
        },
      ],
      dispatchAction: "calls/initializeCallsView",
    },
  },
  {
    path: "/projects/:projectId/voice/recordings/list",
    name: "Recordings List",
    component: CallsRecordingList,
    meta: {
      pageTitle: "Recordings list",
      featureFlipping: {
        key: "calls.list",
        default: false,
      },
      headerImg: "./header-voice.png",
      layout: "LayoutMain",
      tabs: "voice",
      dispatchAction: "calls/initializeRecordingsList",
    },
  },
  {
    path: "/projects/:projectId/voice/:callId/overview",
    name: "Call Overview",
    component: CallsView,
    meta: {
      pageTitle: "Call overview",
      featureFlipping: {
        key: "calls.view",
        default: false,
      },
      headerImg: "./header-voice.png",
      layout: "LayoutMain",
      tabs: "callDetails",
      back: true,
      backLink: "/voice/list",
      dispatchAction: "calls/initializeCallOverview",
      disableDispatchActionError: true,
    },
  },
];
