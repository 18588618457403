export default {
  resetBillingReports(state, payload) {
    state.balance = {};
    state.autoTopUpSetting = {};
    state.paymentMethods = [];
  },
  setBillingPermissions(state, payload) {
    state.billingPermissions = payload;
  },
  setBillingAccounts(state, payload) {
    state.billingAccounts = payload;
  },
  setAllBillingAccounts(state, payload) {
    state.allBillingAccounts = payload;
  },
  setBillingAccount(state, payload) {
    state.billingAccount = payload;
  },
  setBillingAccountDetails(state, payload) {
    state.billingAccountDetails = payload;
  },
  setBillingAccountInfo(state, payload) {
    state.billingAccountInfo = payload;
  },
  setPaymentMethods(state, payload) {
    state.paymentMethods = payload;
  },
  setBalance(state, payload) {
    state.balance = payload;
  },
  setAutoTopUpSetting(state, payload) {
    state.autoTopUpSetting = payload;
  },
};
