import axios from "axios";
export default {
  setUser(state, payload) {
    state.user = payload;
    if (payload) {
      axios.defaults.headers.common.Authorization = `Bearer ${state.user.ma}`;
    }
  },
  setAppNotReady(state) {
    state.appReady = false;
  },
  setAppReady(state) {
    state.appReady = true;
  },
  setProjectSigningKey(state, payload) {
    state.projectSigningKey = payload;
  },
  setPermissions(state, payload) {
    state.permissions = payload;
  },
  setNewProject(state, payload) {
    state.projects = state.projects.push(payload);
  },
  setCurrentProject(state, payload) {
    state.project = payload;
  },
  setProjects(state, payload) {
    state.projects = payload;
  },
  setQueryParameters(state, payload) {
    state.queryParameters = payload;
  },
  setTenantConfig(state, payload) {
    state.tenantConfig = payload;
  },
  setMultiFactors(state, payload) {
    state.multiFactors = payload;
  },
};
