// @ts-nocheck

export const LocalApiUrl = process.env.NODE_ENV === "development" ? "http://localhost:8080/" : "/";

export const CallerreputationApiUrl =
  process.env.NODE_ENV === "development"
    ? process.env.VUE_APP_CALLERREPUTATION_API_URL
    : window.CONFIG.CALLERREPUTATION_API_URL;

export const AnalyticsApiUrl =
  process.env.NODE_ENV === "development"
    ? process.env.VUE_APP_ANALYTICS_API_URL
    : window.CONFIG.ANALYTICS_API_URL;

export const IamApiUrl =
  process.env.NODE_ENV === "development"
    ? process.env.VUE_APP_IAM_API_URL
    : window.CONFIG.IAM_API_URL;

export const BillingApiUrl =
  process.env.NODE_ENV === "development"
    ? process.env.VUE_APP_BILLING_API_URL
    : window.CONFIG.BILLING_API_URL;

export const ResourceManagerApiUrl =
  process.env.NODE_ENV === "development"
    ? process.env.VUE_APP_RESOURCE_MANAGER_API_URL
    : window.CONFIG.RESOURCE_MANAGER_API_URL;

export const NumberingApiUrl =
  process.env.NODE_ENV === "development"
    ? process.env.VUE_APP_NUMBERING_API_URL
    : window.CONFIG.NUMBERING_API_URL;

export const SMS_MARKETING_API_URL =
  process.env.NODE_ENV === "development"
    ? process.env.VUE_APP_SMS_MARKETING_API_URL
    : window.CONFIG.SMS_MARKETING_API_URL;

export const MessagingApiUrl =
  process.env.NODE_ENV === "development"
    ? process.env.VUE_APP_MESSAGING_API_URL
    : window.CONFIG.MESSAGING_API_URL;

export const VoiceApiUrl =
  process.env.NODE_ENV === "development"
    ? process.env.VUE_APP_VOICE_API_URL
    : window.CONFIG.VOICE_API_URL;

export const MediaApiUrl =
  process.env.NODE_ENV === "development"
    ? process.env.VUE_APP_MEDIA_API_URL
    : window.CONFIG.MEDIA_API_URL;

export const FlowApiUrl =
  process.env.NODE_ENV === "development"
    ? process.env.VUE_APP_FLOW_API_URL
    : window.CONFIG.FLOW_API_URL;

export const FirebaseApiKey =
  process.env.NODE_ENV === "development"
    ? process.env.VUE_APP_FIREBASE_API_KEY
    : window.CONFIG.FIREBASE_API_KEY;

export const FirebaseAuthDomain =
  process.env.NODE_ENV === "development"
    ? process.env.VUE_APP_FIREBASE_AUTH_DOMAIN
    : window.CONFIG.FIREBASE_AUTH_DOMAIN;

export const FirebaseProjectId =
  process.env.NODE_ENV === "development"
    ? process.env.VUE_APP_FIREBASE_PROJECT_ID
    : window.CONFIG.FIREBASE_PROJECT_ID;

export const StripePublishableKey =
  process.env.NODE_ENV === "development"
    ? process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY
    : window.CONFIG.STRIPE_PUBLISHABLE_KEY;

export const BUGSNAG_KEY =
  process.env.NODE_ENV === "development"
    ? process.env.VUE_APP_BUGSNAG_KEY
    : window.CONFIG.BUGSNAG_KEY;

export const GTM =
  process.env.NODE_ENV === "development" ? process.env.VUE_APP_GTM : window.CONFIG.GTM;

export const PARENT_DOMAIN =
  process.env.NODE_ENV === "development"
    ? process.env.VUE_APP_PARENT_DOMAIN
    : window.CONFIG.PARENT_DOMAIN;

export const PHONE_NUMBER_REGULATIONS =
  process.env.NODE_ENV === "development"
    ? JSON.parse(
        ` { "requirements": [ { "regionCode": "AU", "numberType": "LOCAL", "address": "ADDRESS_REQUIREMENT_LOCAL" }, { "regionCode": "AU", "numberType": "MOBILE", "address": "ADDRESS_REQUIREMENT_INTERNATIONAL" }, { "regionCode": "AU", "numberType": "TOLL_FREE", "address": "ADDRESS_REQUIREMENT_NONE" }, { "regionCode": "AU", "numberType": "SHARED_RATE", "address": "ADDRESS_REQUIREMENT_NONE" }, { "regionCode": "NZ", "numberType": "LOCAL", "address": "ADDRESS_REQUIREMENT_LOCAL" }, { "regionCode": "NZ", "numberType": "TOLL_FREE", "address": "ADDRESS_REQUIREMENT_NONE" }, { "regionCode": "GB", "numberType": "LOCAL", "address": "ADDRESS_REQUIREMENT_NONE" }, { "regionCode": "US", "numberType": "LOCAL", "address": "ADDRESS_REQUIREMENT_NONE" }, { "regionCode": "US", "numberType": "TOLL_FREE", "address": "ADDRESS_REQUIREMENT_NONE" } ] } `
      )
    : JSON.parse(window.CONFIG.PHONE_NUMBER_REGULATIONS);

export const permissions = [
  "billing.bundles.get",
  "billing.bundles.list",
  "billing.bundles.scale",
  "numbering.portInRequests.create",
  "numbering.portInRequests.get",
  "numbering.portInRequests.list",
  "numbering.portInRequests.update",
  "numbering.portInRequests.delete",
  "numbering.portInRequests.submit",
  "numbering.portInRequests.cancel",
  "resourcemanager.projects.getSigningKey",
  "resourcemanager.projects.renewSigningKey",
  "numbering.phoneNumberInstances.get",
  "numbering.phoneNumberInstances.list",
  "numbering.phoneNumberInstances.create",
  "numbering.phoneNumberInstances.update",
  "numbering.phoneNumberInstances.delete",
  "numbering.regulatoryListings.get",
  "numbering.regulatoryListings.list",
  "numbering.regulatoryListings.create",
  "numbering.regulatoryListings.update",
  "numbering.regulatoryListings.delete",
  "messaging.messages.create",
  "messaging.messages.get",
  "messaging.messages.list",
  "messaging.unsubscribers.get",
  "messaging.unsubscribers.list",
  "resourcemanager.projects.get",
  "resourcemanager.projects.update",
  "resourcemanager.projects.delete",
  "resourcemanager.projects.create",
  "resourcemanager.projects.getIamPolicy",
  "resourcemanager.projects.setIamPolicy",
  "voice.calls.get",
  "voice.calls.list",
  "voice.recordings.get",
  "voice.recordings.list",
  "voice.calls.create",
  "voice.recordings.delete",
  "media.media.get",
  "media.media.list",
  "media.media.update",
  "media.media.upload",
  "media.media.delete",
  "flow.flows.get",
  "flow.flows.list",
  "flow.versions.get",
  "flow.versions.list",
  "flow.flows.create",
  "flow.flows.update",
  "flow.flows.delete",
  "flow.versions.create",
  "flow.versions.saveDraft",
  "flow.versions.delete",
  "callerreputation.checks.get",
  "callerreputation.checks.list",
  "callerreputation.clis.create",
  "callerreputation.clis.get",
  "callerreputation.clis.list",
  "callerreputation.clis.update",
  "callerreputation.clis.delete",
  "callerreputation.clis.search",
  "callerreputation.clis.pause",
  "callerreputation.clis.unpause",
  "callerreputation.comments.get",
  "callerreputation.comments.list",
  "callerreputation.subscriptions.create",
  "callerreputation.subscriptions.get",
  "callerreputation.subscriptions.list",
  "callerreputation.subscriptions.update",
  "callerreputation.subscriptions.delete",
  "voice.aliases.create",
  "voice.aliases.get",
  "voice.aliases.list",
  "voice.aliases.update",
  "voice.aliases.delete",
  "voice.sipDomains.create",
  "voice.sipDomains.get",
  "voice.sipDomains.list",
  "voice.sipDomains.update",
  "voice.sipDomains.delete",
  "voice.users.create",
  "voice.users.get",
  "voice.users.list",
  "voice.users.update",
  "voice.users.delete",
  "iam.serviceAccounts.get",
  "iam.serviceAccounts.list",
  "iam.serviceAccountKeys.get",
  "iam.serviceAccountKeys.list",
  "iam.serviceAccounts.create",
  "iam.serviceAccounts.update",
  "iam.serviceAccounts.delete",
  "iam.serviceAccountKeys.create",
  "iam.serviceAccountKeys.delete",
  "smsmarketing.campaigns.create",
  "smsmarketing.campaigns.update",
  "smsmarketing.campaigns.get",
  "smsmarketing.campaigns.list",
  "smsmarketing.contactLists.create",
  "smsmarketing.contactLists.update",
  "smsmarketing.contactLists.get",
  "smsmarketing.contactLists.list",
  "smsmarketing.contacts.create",
  "smsmarketing.contacts.update",
  "smsmarketing.contacts.get",
  "smsmarketing.contacts.list",
];

const BILLING_BUNDLES_GET = "billing.bundles.get";
const BILLING_BUNDLES_LIST = "billing.bundles.list";
const BILLING_BUNDLES_SCALE = "billing.bundles.scale";

const NUMBERING_PORTINREQUESTS_CREATE = "numbering.portInRequests.create";
const NUMBERING_PORTINREQUESTS_GET = "numbering.portInRequests.get";
const NUMBERING_PORTINREQUESTS_LIST = "numbering.portInRequests.list";
const NUMBERING_PORTINREQUESTS_UPDATE = "numbering.portInRequests.update";
const NUMBERING_PORTINREQUESTS_DELETE = "numbering.portInRequests.delete";
const NUMBERING_PORTINREQUESTS_SUBMIT = "numbering.portInRequests.submit";
const NUMBERING_PORTINREQUESTS_CANCEL = "numbering.portInRequests.cancel";

const SMSMARKETING_CONTACTS_LIST = "smsmarketing.contacts.list";
const SMSMARKETING_CONTACTS_GET = "smsmarketing.contacts.get";
const SMSMARKETING_CONTACTS_UPDATE = "smsmarketing.contacts.update";
const SMSMARKETING_CONTACTS_CREATE = "smsmarketing.contacts.create";

const SMSMARKETING_CONTACTLISTS_LIST = "smsmarketing.contactLists.list";
const SMSMARKETING_CONTACTLISTS_GET = "smsmarketing.contactLists.get";
const SMSMARKETING_CONTACTLISTS_UPDATE = "smsmarketing.contactLists.update";
const SMSMARKETING_CONTACTLISTS_CREATE = "smsmarketing.contactLists.create";

const SMSMARKETING_CAMPAIGNS_LIST = "smsmarketing.campaigns.list";
const SMSMARKETING_CAMPAIGNS_GET = "smsmarketing.campaigns.get";
const SMSMARKETING_CAMPAIGNS_UPDATE = "smsmarketing.campaigns.update";
const SMSMARKETING_CAMPAIGNS_CREATE = "smsmarketing.campaigns.create";

const iamServiceAccountsGet = "iam.serviceAccounts.get";
const iamServiceAccountsList = "iam.serviceAccounts.list";
const iamServiceAccountKeysGet = "iam.serviceAccountKeys.get";
const iamServiceAccountKeysList = "iam.serviceAccountKeys.list";
const iamServiceAccountsCreate = "iam.serviceAccounts.create";
const iamServiceAccountsUpdate = "iam.serviceAccounts.update";
const iamServiceAccountsDelete = "iam.serviceAccounts.delete";
const iamServiceAccountKeysCreate = "iam.serviceAccountKeys.create";
const iamServiceAccountKeysDelete = "iam.serviceAccountKeys.delete";

const callerreputationChecksGet = "callerreputation.checks.get";
const callerreputationChecksList = "callerreputation.checks.list";
const callerreputationClisCreate = "callerreputation.clis.create";
const callerreputationClisGet = "callerreputation.clis.get";
const callerreputationClisList = "callerreputation.clis.list";

const callerreputationClisUpdate = "callerreputation.clis.update";
const callerreputationClisDelete = "callerreputation.clis.delete";
const callerreputationClisSearch = "callerreputation.clis.search";
const callerreputationClisPause = "callerreputation.clis.pause";
const callerreputationClisUnpause = "callerreputation.clis.unpause";
const callerreputationCommentsGet = "callerreputation.comments.get";
const callerreputationCommentsList = "callerreputation.comments.list";
const callerreputationSubscriptionsCreate = "callerreputation.subscriptions.create";
const callerreputationSubscriptionsGet = "callerreputation.subscriptions.get";
const callerreputationSubscriptionsList = "callerreputation.subscriptions.list";
const callerreputationSubscriptionsUpdate = "callerreputation.subscriptions.update";
const callerreputationSubscriptionsDelete = "callerreputation.subscriptions.delete";

const voiceAliasesCreate = "voice.aliases.create";
const voiceAliasesGet = "voice.aliases.get";
const voiceAliasesList = "voice.aliases.list";
const voiceAliasesUpdate = "voice.aliases.update";
const voiceAliasesDelete = "voice.aliases.delete";

const voiceSipDomainsCreate = "voice.sipDomains.create";
const voiceSipDomainsGet = "voice.sipDomains.get";
const voiceSipDomainsList = "voice.sipDomains.list";
const voiceSipDomainsUpdate = "voice.sipDomains.update";
const voiceSipDomainsDelete = "voice.sipDomains.delete";

const voiceUsersCreate = "voice.users.create";
const voiceUsersGet = "voice.users.get";
const voiceUsersList = "voice.users.list";
const voiceUsersUpdate = "voice.users.update";
const voiceUsersDelete = "voice.users.delete";

const getSigningKey = "resourcemanager.projects.getSigningKey";
const renewSigningKey = "resourcemanager.projects.renewSigningKey";
const flowFlowsGet = "flow.flows.get";
const flowFlowsList = "flow.flows.list";
const flowVersionsGet = "flow.versions.get";
const flowVersionsList = "flow.versions.list";
const flowFlowsCreate = "flow.flows.create";
const flowFlowsUpdate = "flow.flows.update";
const flowFlowsDelete = "flow.flows.delete";
const flowVersionsCreate = "flow.versions.create";
const flowVersionsSaveDraft = "flow.versions.saveDraft";
const flowVersionsDelete = "flow.versions.delete";
const voiceCallsGet = "voice.calls.get";
const voiceCallsList = "voice.calls.list";
const voiceRecordingGet = "voice.recording.get";
const voiceRecordingList = "voice.recording.list";
const voiceCallsCreate = "voice.calls.create";
const voiceRecordingsDelete = "voice.recordings.delete";
const mediaMediaGet = "media.media.get";
const mediaMediaList = "media.media.list";
const mediaMediaUpdate = "media.media.update";
const mediaMediaUpload = "media.media.upload";
const mediaMediaDelete = "media.media.delete";
const numberPhoneNumberInstancesGet = "numbering.phoneNumberInstances.get";
const numberPhoneNumberInstancesList = "numbering.phoneNumberInstances.list";
const numberPhoneNumberInstancesCreate = "numbering.phoneNumberInstances.create";
const numberPhoneNumberInstancesUpdate = "numbering.phoneNumberInstances.update";
const numberPhoneNumberInstancesDelete = "numbering.phoneNumberInstances.delete";

const numberRegulatoryListingsGet = "numbering.regulatoryListings.get";
const numberRegulatoryListingsList = "numbering.regulatoryListings.list";
const numberRegulatoryListingsCreate = "numbering.regulatoryListings.create";
const numberRegulatoryListingsUpdate = "numbering.regulatoryListings.update";
const numberRegulatoryListingsDelete = "numbering.regulatoryListings.delete";

const messagingMessagesCreate = "messaging.messages.create";
const messagingMessagesGet = "messaging.messages.get";
const messagingMessagesList = "messaging.messages.list";
const messagingUnsubscribersGet = "messaging.unsubscribers.get";
const messagingUnsubscribersList = "messaging.unsubscribers.list";
const resourcemanagerProjectsGet = "resourcemanager.projects.get";
const resourcemanagerProjectsUpdate = "resourcemanager.projects.update";
const resourcemanagerProjectsDelete = "resourcemanager.projects.delete";
const resourcemanagerProjectsCreate = "resourcemanager.projects.create";
const resourcemanagerProjectsGetIamPolicy = "resourcemanager.projects.getIamPolicy";
const resourcemanagerProjectsSetIamPolicy = "resourcemanager.projects.setIamPolicy";

export const billingPermissions = [
  "billing.billingAccounts.get",
  "billing.billingAccounts.getBalance",
  "billing.billingAccounts.update",
  "billing.billingAccounts.getAccountDetails",
  "billing.billingAccounts.updateAccountDetails",
  "billing.resourceAssociations.list",
  "billing.resourceAssociations.update",
  "billing.billingAccounts.calculatePrices",
  "billing.billingAccounts.getIamPolicy",
  "billing.billingAccounts.setIamPolicy",
];

const billingBillingAccountsGetIamPolicy = "billing.billingAccounts.getIamPolicy";
const billingBillingAccountsSetIamPolicy = "billing.billingAccounts.setIamPolicy";
const billingBillingAccountsGetAccountDetails = "billing.billingAccounts.getAccountDetails";
const billingBillingAccountsUpdateAccountDetails = "billing.billingAccounts.updateAccountDetails";

export const tenantAdminFeatures = [
  "tenants.home",
  "tenants.numbers.list",
  "tenants.users.list",
  "tenants.users.view",
  "tenants.projects.list",
  "tenants.billingAccounts.list",
  "tenants.portInRequests.list",
];

export const adminFeatures = [
  "projects.edit",
  "billingAccounts.edit",
  "billing.view",
  "invoices.list",
  "payments.list",
  "billing.users",
  "settings.view",
  "settings.users",
];

export const serviceAccountFeatures = [
  "serviceAccounts.list",
  "serviceAccounts.delete",
  "serviceAccounts.create",
  "serviceAccounts.view",
];

export const phoneNumberFeatures = [
  "regulatoryListings.list",
  "regulatoryListings.view",
  "phoneNumbers.list",
  "phoneNumbers.view",
  "phoneNumberInstances.create",
  "phoneNumberInstances.list",
  "phoneNumberInstances.view",
  "phoneNumberInstances.update",
  "phoneNumberInstances.delete",
];

export const portInRequestsFeatures = [
  "portInRequests.list",
  "portInRequests.create",
  "portInRequests.view",
];

export const cloudSMSFeatures = [
  "sms.overview",
  "messages.list",
  "messages.view",
  "unsubscribers.list",
];
export const cloudVoiceFeatures = ["voice.overview", "calls.list", "calls.view"];
export const cloudSIPFeatures = [
  "sipDomains.list",
  "sipDomains.view",
  "sipDomains.update",
  "sipDomains.delete",
  "aliases.list",
  "aliases.view",
  "aliases.update",
  "aliases.delete",
  "voice.users.list",
  "voice.users.create",
  "voice.users.update",
  "voice.users.delete",
];
export const flowFeatures = [
  "flows.list",
  "flows.delete",
  "flows.create",
  "flows.update",
  "flows.view",
];

export const repmanFeatures = [
  "clis.view",
  "clis.list",
  "clis.create",
  "clis.update",
  "clis.delete",
  "comments.list",
  "checks.list",
];

export const smsMarketingFeatures = [
  "campaigns.overview",
  "campaigns.list",
  "campaigns.create",
  "campaigns.view",
  "contactLists.list",
  "contactLists.view",
  "contacts.list",
  "contacts.view",
];

export const bundlesFeatures = ["bundles.list", "bundles.view"];
export const pricingFeatures = ["pricing.view"];

export const allPermissions = {
  BILLING_BUNDLES_GET,
  BILLING_BUNDLES_LIST,
  BILLING_BUNDLES_SCALE,
  NUMBERING_PORTINREQUESTS_CREATE,
  NUMBERING_PORTINREQUESTS_GET,
  NUMBERING_PORTINREQUESTS_LIST,
  SMSMARKETING_CONTACTS_LIST,
  NUMBERING_PORTINREQUESTS_UPDATE,
  NUMBERING_PORTINREQUESTS_DELETE,
  NUMBERING_PORTINREQUESTS_SUBMIT,
  NUMBERING_PORTINREQUESTS_CANCEL,
  SMSMARKETING_CONTACTS_GET,
  SMSMARKETING_CONTACTS_UPDATE,
  SMSMARKETING_CONTACTS_CREATE,
  SMSMARKETING_CONTACTLISTS_LIST,
  SMSMARKETING_CONTACTLISTS_GET,
  SMSMARKETING_CONTACTLISTS_UPDATE,
  SMSMARKETING_CONTACTLISTS_CREATE,
  SMSMARKETING_CAMPAIGNS_LIST,
  SMSMARKETING_CAMPAIGNS_GET,
  SMSMARKETING_CAMPAIGNS_UPDATE,
  SMSMARKETING_CAMPAIGNS_CREATE,
  voiceCallsGet,
  voiceCallsList,
  voiceRecordingGet,
  voiceRecordingList,
  voiceCallsCreate,
  voiceRecordingsDelete,
  mediaMediaGet,
  mediaMediaList,
  mediaMediaUpdate,
  mediaMediaUpload,
  mediaMediaDelete,
  flowFlowsGet,
  flowFlowsList,
  flowVersionsGet,
  flowVersionsList,
  flowFlowsCreate,
  flowFlowsUpdate,
  flowFlowsDelete,
  flowVersionsCreate,
  flowVersionsSaveDraft,
  flowVersionsDelete,
  numberPhoneNumberInstancesGet,
  numberPhoneNumberInstancesList,
  numberPhoneNumberInstancesCreate,
  numberPhoneNumberInstancesUpdate,
  numberPhoneNumberInstancesDelete,
  numberRegulatoryListingsGet,
  numberRegulatoryListingsList,
  numberRegulatoryListingsCreate,
  numberRegulatoryListingsUpdate,
  numberRegulatoryListingsDelete,
  messagingMessagesCreate,
  messagingMessagesGet,
  messagingMessagesList,
  messagingUnsubscribersGet,
  messagingUnsubscribersList,
  resourcemanagerProjectsGet,
  resourcemanagerProjectsUpdate,
  resourcemanagerProjectsDelete,
  resourcemanagerProjectsCreate,
  resourcemanagerProjectsGetIamPolicy,
  resourcemanagerProjectsSetIamPolicy,
  billingBillingAccountsGetIamPolicy,
  billingBillingAccountsSetIamPolicy,
  billingBillingAccountsGetAccountDetails,
  billingBillingAccountsUpdateAccountDetails,
  getSigningKey,
  renewSigningKey,
  callerreputationChecksGet,
  callerreputationChecksList,
  callerreputationClisCreate,
  callerreputationClisGet,
  callerreputationClisList,
  callerreputationClisUpdate,
  callerreputationClisDelete,
  callerreputationClisSearch,
  callerreputationClisPause,
  callerreputationClisUnpause,
  callerreputationCommentsGet,
  callerreputationCommentsList,
  callerreputationSubscriptionsCreate,
  callerreputationSubscriptionsGet,
  callerreputationSubscriptionsList,
  callerreputationSubscriptionsUpdate,
  callerreputationSubscriptionsDelete,
  voiceAliasesCreate,
  voiceAliasesGet,
  voiceAliasesList,
  voiceAliasesUpdate,
  voiceAliasesDelete,
  voiceSipDomainsCreate,
  voiceSipDomainsGet,
  voiceSipDomainsList,
  voiceSipDomainsUpdate,
  voiceSipDomainsDelete,
  voiceUsersCreate,
  voiceUsersGet,
  voiceUsersList,
  voiceUsersUpdate,
  voiceUsersDelete,
  iamServiceAccountsGet,
  iamServiceAccountsList,
  iamServiceAccountKeysGet,
  iamServiceAccountKeysList,
  iamServiceAccountsCreate,
  iamServiceAccountsUpdate,
  iamServiceAccountsDelete,
  iamServiceAccountKeysCreate,
  iamServiceAccountKeysDelete,
};
