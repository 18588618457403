export default {
  resetUsers(state, payload) {
    state.users = {
      data: [],
      nextPageToken: "",
    };
  },
  setAll(state, payload) {
    state.all = payload;
  },
  setAllSipDomainUsers(state, payload) {
    state.allSipDomainUsers = payload;
  },
  addSipDomainUser(state, payload) {
    state.allSipDomainUsers.push(payload);
  },
};
