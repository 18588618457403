export default {
  loading: false,
  error: null,
  success: null,
  info: null,
  abortController: null,
  dispatchActionError: null,
  hasUnsavedChanges: false,
  historyCount: 0,
};
