const CampaignsList = () => import("@/views/Campaigns/CampaignsList");
const CampaignsCreate = () => import("@/views/Campaigns/CampaignsCreate");
const CampaignsView = () => import("@/views/Campaigns/CampaignsView");
const CampaignsLinks = () => import("@/views/Campaigns/CampaignsLinks");
const CampaignsRecipients = () => import("@/views/Campaigns/CampaignsRecipients");
const CampaignsOverview = () => import("@/views/Campaigns/CampaignsOverview");

export default [
  {
    path: "/projects/:projectId/campaigns/overview",
    name: "Campaign Overview",
    component: CampaignsOverview,
    meta: {
      pageTitle: "Campaign overview",
      featureFlipping: {
        key: "campaigns.overview",
        default: false,
      },
      headerImg: "./header-sms.png",
      layout: "LayoutMain",
      tabs: "smsMarketing",
      dispatchAction: "campaigns/getOverview",
    },
  },
  {
    path: "/projects/:projectId/campaigns/list",
    name: "ViewCampaignList",
    component: CampaignsList,
    meta: {
      pageTitle: "Campaigns list",
      featureFlipping: {
        key: "campaigns.list",
        default: false,
      },
      headerImg: "./header-sms.png",
      layout: "LayoutMain",
      tabs: "smsMarketing",
      dispatchAction: "campaigns/listAll",
    },
  },
  {
    path: "/projects/:projectId/campaigns/create",
    name: "CreateCampaign",
    component: CampaignsCreate,
    meta: {
      pageTitle: "New campaign",
      featureFlipping: {
        key: "campaigns.create",
        default: false,
      },
      headerImg: "./header-sms.png",
      layout: "LayoutMain",
      tabs: "smsMarketing",
      back: true,
      backLink: "/campaigns/list",
      dispatchAction: "campaigns/initializeCreateView",
    },
  },
  {
    path: "/projects/:projectId/campaigns/:campaignId/overview",
    name: "ViewCampaign",
    component: CampaignsView,
    meta: {
      pageTitle: "View campaign",
      featureFlipping: {
        key: "campaigns.view",
        default: false,
      },
      headerImg: "./header-sms.png",
      layout: "LayoutMain",
      tabs: "campaignView",
      back: true,
      backLink: "/campaigns/list",
      dispatchAction: "campaigns/initializeCampaignView",
    },
  },
  {
    path: "/projects/:projectId/campaigns/:campaignId/links",
    name: "CampaignLinks",
    component: CampaignsLinks,
    meta: {
      pageTitle: "Campaign links",
      featureFlipping: {
        key: "campaigns.view",
        default: false,
      },
      headerImg: "./header-sms.png",
      layout: "LayoutMain",
      tabs: "campaignView",
      back: true,
      backLink: "/campaigns/list",
      dispatchAction: "campaigns/listCampaignLinks",
    },
  },
  {
    path: "/projects/:projectId/campaigns/:campaignId/recipients",
    name: "CampaignRecipients",
    component: CampaignsRecipients,
    meta: {
      pageTitle: "Campaign recipients",
      featureFlipping: {
        key: "campaigns.view",
        default: false,
      },
      headerImg: "./header-sms.png",
      layout: "LayoutMain",
      tabs: "campaignView",
      back: true,
      backLink: "/campaigns/list",
      dispatchAction: "campaigns/listCampaignRecipients",
    },
  },
];
