import { FlowApiUrl, MediaApiUrl } from "@/consts/configuration";
import { httpClient } from "@/helpers/httpClient";
import { CrudService } from "@/api";
const flowsAPI = new CrudService(FlowApiUrl);

export default {
  handleStepForm({ commit }, payload) {
    commit("setStepForm", payload);
  },
  handleSelectedStep({ commit }, payload) {
    commit("setSelectedStep", payload);
  },
  resetSelectedStep({ commit }, payload) {
    commit("setSelectedStep", {});
  },
  handleUpdateStep({ commit }, payload) {
    const flow = payload;
    commit("setFlow", flow);
  },
  handleDeleteStep({ commit }, payload) {
    const flow = payload;
    commit("setFlow", flow);
  },
  async mapPostcodes({ commit }, payload) {
    try {
      const res = await httpClient.post(
        `${FlowApiUrl}/projects/${payload.projectId}:mapPostcodes`,
        {
          ...payload.payload,
          dataSource: "AU_POSTCODE",
        }
      );
      return res.data;
    } catch (error) {
      commit("common/setError", error, { root: true });
      throw error;
    }
  },
  async mapAreas({ commit }, payload) {
    try {
      const res = await httpClient.post(
        `${FlowApiUrl}/projects/${payload.projectId}:mapPostcodes`,
        {
          ...payload.payload,
          dataSource: "AU_LOCATION",
        }
      );
      return res.data;
    } catch (error) {
      commit("common/setError", error, { root: true });
      throw error;
    }
  },
  async handleDeleteVersion({ commit }, payload) {
    try {
      const res = await httpClient.delete(`${FlowApiUrl}/${payload.versionName}`);
      return res.data;
    } catch (error) {
      throw error;
    }
  },
  async handleCreateVersion({ commit }, payload) {
    try {
      const res = await httpClient.post(`${FlowApiUrl}/${payload.flowName}/versions`, {
        ...payload.payload,
      });
      return res.data;
    } catch (error) {
      commit("common/setError", error, { root: true });
      throw error;
    }
  },
  async handleCreateDraftVersion({ commit }, payload) {
    try {
      const res = await httpClient.post(`${FlowApiUrl}/${payload.flowName}/versions:saveDraft`, {
        version: payload.payload,
      });
      return res.data;
    } catch (error) {
      commit("common/setError", error, { root: true });
      throw error;
    }
  },
  async handleUpdateVersion({ commit }, payload) {
    try {
      const res = await httpClient.patch(
        `${FlowApiUrl}/${payload.versionName}?update_mask=steps,ttsVoice`,
        {
          ...payload.payload,
        }
      );
      return res.data;
    } catch (error) {
      commit("common/setError", error, { root: true });
      throw error;
    }
  },
  async handleCreateFlow({ commit }, payload) {
    try {
      const res = await httpClient.post(`${FlowApiUrl}/projects/${payload.projectId}/flows`, {
        ...payload.payload,
      });
      return res.data;
    } catch (error) {
      commit("common/setError", error, { root: true });
      throw error;
    }
  },
  async handleUpdateFlow({ commit }, payload) {
    try {
      const res = await httpClient.patch(
        `${FlowApiUrl}/${payload.flowName}?update_mask=displayName`,
        {
          ...payload.payload,
        }
      );
      return res.data;
    } catch (error) {
      commit("common/setError", error, { root: true });
      throw error;
    }
  },
  async handleDeleteFlow({ commit }, payload) {
    // Delete the flow
    try {
      const res = await httpClient.delete(`${FlowApiUrl}/${payload.flowName}`);
      return res.data;
    } catch (error) {
      commit("common/setError", error, { root: true });
      throw error;
    }
  },
  async handleGetFlow({ commit }, payload) {
    // Transform the response here to work with the flow builder
    // payload.projectId
    // payload.flowId
    let flow;
    if (payload.flowId === "new") {
      flow = {
        version: 1,
        configuration: [
          {
            id: "1",
            parentId: -1,
            stepComponent: "entry-step",
            data: {
              width: 320,
              text: "Parent block",
              title: "Entry",
              background: "#000000",
              configuration: {},
              exits: [
                {
                  zone: 1,
                },
              ],
            },
          },
        ],
      };
      commit("setFlow", flow);
      return flow;
    } else {
      try {
        const res = await httpClient.get(
          `${FlowApiUrl}/projects/${payload.projectId}/flows/${payload.flowId}`
        );
        commit("setFlow", res.data);
        return res.data;
      } catch (error) {
        commit("common/setError", error, { root: true });
        throw error;
      }
    }
  },
  async handleGetVersion({ commit }, payload) {
    try {
      const res = await httpClient.get(`${FlowApiUrl}/${payload.versionName}`);
      commit("setFlowVersion", res.data);
      return res.data;
    } catch (error) {
      commit("common/setError", error, { root: true });
      throw error;
    }
  },
  resetFlows({ commit, state }, payload) {
    commit("resetFlows");
  },
  async initializeFlowListView({ commit, state, dispatch }, to) {
    try {
      const payload = {
        projectName: to.params.projectId,
      };

      if (to.query.search && state.flows.length > 0) {
        return;
      }

      const flowList = await dispatch(
        "flows/handleListFlows",
        { ...payload, includeVersions: true },
        {
          root: true,
        }
      );
      return { flowList };
    } catch (err) {
      throw err;
    }
  },
  async handleListFlows({ commit, state }, payload) {
    async function getCurrentVersion(url: string) {
      const res = await httpClient.get(url);
      return res.data;
    }

    const deleted = payload.includeDeleted ? "&show_deleted=true" : "";
    try {
      let params;
      const allSubs = [];
      do {
        const res = await httpClient.get(
          `${FlowApiUrl}/projects/${payload.projectName}/flows?page_size=100${
            params ? `&page_token=${params}` : ""
          }` + deleted
        );
        allSubs.push(res.data.flows);

        params = res.data.nextPageToken;
      } while (params !== "");

      if (payload.includeVersions) {
        const allFlows = allSubs.flat();
        // Iterate through allSubs and fetch additional data based on the below conditions
        for (const flowItem of allFlows) {
          let currentVersion;
          if (flowItem.activeVersion && !flowItem.hasDraft) {
            currentVersion = await getCurrentVersion(`${FlowApiUrl}/${flowItem.activeVersion}`);
          } else if (flowItem.hasDraft) {
            currentVersion = await getCurrentVersion(
              `${FlowApiUrl}/${flowItem.name}/versions/draft`
            );
          } else {
            currentVersion = null;
          }
          flowItem.currentVersion = currentVersion;
        }

        commit("setFlows", allFlows);
        return allFlows;
      } else {
        commit("setFlows", allSubs.flat());
        return allSubs.flat();
      }
    } catch (error) {
      throw error;
    }
  },
  async handleListVersions({ commit, state }, payload) {
    try {
      const res = await httpClient.get(`${FlowApiUrl}/${payload.flowName}/versions?page_size=100`);
      commit("setVersions", res.data.versions);
      return res.data;
    } catch (error) {
      commit("common/setError", error, { root: true });
      throw error;
    }
  },
  async listVoices({ commit, state }) {
    try {
      const res = await httpClient.get(`${MediaApiUrl}/voices`);
      commit("setVoices", res.data.voices);
      return res.data;
    } catch (error) {
      commit("common/setError", error, { root: true });
      throw error;
    }
  },
};
