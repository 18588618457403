export default {
  overview: null,
  call: {
    price: {},
    recordings: [],
  },
  calls: {
    data: [],
    nextPageToken: "",
  },
  recordingsList: {
    data: [],
    nextPageToken: "",
  },
};
