export default {
  overview: null,
  cli: null,
  clis: {
    data: [],
    nextPageToken: "",
    page: 1,
    itemsPerPage: 20,
  },
};
