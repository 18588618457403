import { VoiceApiUrl, MediaApiUrl } from "@/consts/configuration";
import _ from "lodash";
import { httpClient } from "@/helpers/httpClient";
import moment from "moment";
import apiFilter from "@/helpers/apiFilter";
import { CrudService } from "@/api";
import defaultDate from "@/helpers/defaultDate";
const callsAPI = new CrudService(VoiceApiUrl);

async function getRecording(recordingName: string) {
  const recording = await httpClient.get(`${VoiceApiUrl}/${recordingName}`);
  return recording.data;
}

async function getTranscription(transcriptionName: string) {
  const transcription = await httpClient.get(`${VoiceApiUrl}/${transcriptionName}`);
  return transcription.data;
}

export default {
  async initializeRecordingsList({ commit, state, dispatch }, to) {
    try {
      const numberPayload = {
        projectId: to.params.projectId,
        search: "",
      };

      const recordingsListPayload = {
        projectId: to.params.projectId,
      };

      const phoneNumbers = await dispatch("phoneNumberInstances/getPhoneNumbers", numberPayload, {
        root: true,
      });

      const recordings = await dispatch("calls/getRecordingsList", to, {
        root: true,
      });
      return {
        recordings,
        phoneNumbers,
      };
    } catch (err) {
      throw err;
    }
  },
  resetRecordings({ commit, state }, payload) {
    commit("resetRecordings", "");
  },
  async getRecordingsList({ commit, state, dispatch }, to) {
    if (!to.dontReset) {
      dispatch("calls/resetRecordings", "", { root: true });
    }
    const nextPageToken = state.recordingsList.nextPageToken;
    const projectId = to.params.projectId;
    let payload = {};
    if (nextPageToken) {
      payload = {
        nextPageToken,
        projectId,
      };
    } else {
      payload = {
        projectId,
      };
    }
    const parameters = apiFilter(payload, null);
    try {
      const res = await httpClient.get(
        `${VoiceApiUrl}/projects/${projectId}/recordings/?page_size=25${parameters}`
      );
      commit("setRecordingsList", res.data);
      return res.data;
    } catch (error) {
      throw error;
    }
  },
  async initializeCallOverview({ commit, state, dispatch }, to) {
    try {
      const numberPayload = {
        projectId: to.params.projectId,
        search: "",
      };

      const callPayload = {
        projectId: to.params.projectId,
        callId: to.params.callId,
      };

      const phoneNumbers = await dispatch("phoneNumberInstances/getPhoneNumbers", numberPayload, {
        root: true,
      });

      const call = await dispatch("calls/getCall", callPayload, { root: true });

      return {
        call,
        phoneNumbers,
      };
    } catch (err) {
      throw err;
    }
  },
  async initializeCallsView({ commit, state, dispatch }, to) {
    try {
      const numberPayload = {
        projectId: to.params.projectId,
        search: "",
      };

      const phoneNumbers = await dispatch("phoneNumberInstances/getPhoneNumbers", numberPayload, {
        root: true,
      });

      const calls = await dispatch("calls/getCalls", to, { root: true });
      return {
        calls,
        phoneNumbers,
      };
    } catch (err) {
      throw err;
    }
  },
  async synthesizeSpeech({ commit, state, rootState }, payload) {
    commit("common/setLoading", true, { root: true });
    const url = `${MediaApiUrl}/text:synthesize`;
    try {
      const res = await httpClient.post(url, {
        input: {
          text: payload.text,
        },
        voice: `${rootState.flows.ttsVoice}`,
      });
      commit("common/setLoading", false, { root: true });
      return res.data;
    } catch (error) {
      commit("common/setLoading", false, { root: true });
      throw error;
    }
  },
  async initializeVoiceOverview({ commit, state, rootState }, to) {
    if (rootState.common.abortController) {
      rootState.common.abortController.abort();
      commit("common/setAbortController", null, { root: true });
    }
    if (!to.query.date) {
      return;
    }
    const filtersVal = to.query.date ? JSON.parse(decodeURIComponent(to.query.date)) : "month";
    const payload = {
      date: !Array.isArray(filtersVal) ? defaultDate[filtersVal] : filtersVal,
    };

    const url = `${VoiceApiUrl}/projects/${to.params.projectId}/calls:generateOverviewReport`;
    try {
      const controller = new AbortController();
      commit("common/setAbortController", controller, { root: true });
      const res = await httpClient.post(
        url,
        {
          startDate: {
            year: moment(new Date(payload.date[0]).toISOString()).format("YYYY"),
            month: moment(new Date(payload.date[0]).toISOString()).format("MM"),
            day: moment(new Date(payload.date[0]).toISOString()).format("DD"),
          },
          endDate: {
            year: moment(new Date(payload.date[1]).toISOString()).format("YYYY"),
            month: moment(new Date(payload.date[1]).toISOString()).format("MM"),
            day: moment(new Date(payload.date[1]).toISOString()).format("DD"),
          },
        },
        {
          signal: controller.signal,
          timeout: 60000,
        }
      );
      commit("common/setAbortController", null, { root: true });
      commit("calls/setOverview", res.data, { root: true });
      return res.data;
    } catch (error) {
      if (error.message !== "canceled") {
        commit("common/setAbortController", null, { root: true });
        commit("common/setError", error, { root: true });
      }
      throw error;
    }
  },
  async exportCalls({ commit, state }, payload) {
    const parameters = apiFilter(payload, "voice");

    try {
      let params;
      const currentTime = Math.floor(Date.now() / 1000);
      const allSubs = [];
      do {
        const res = await httpClient.get(
          `${VoiceApiUrl}/projects/${payload.projectId}/calls/?page_size=100&filter=${parameters}${
            params ? `&page_token=${params}` : ""
          }`
        );
        const requestTime = Math.floor(Date.now() / 1000);
        const timeDiff = requestTime - currentTime;
        if (timeDiff > 60) {
          throw Error("request timeout");
        }
        allSubs.push(res.data.calls);
        params = res.data.nextPageToken;
      } while (params !== "");
      commit("common/setLoading", false, { root: true });
      return allSubs.flat();
    } catch (error) {
      commit("common/setLoading", false, { root: true });
      commit("common/setError", error, { root: true });
    }
  },
  async getCall({ commit, state }, payload) {
    const apiUrl = (projectId, callId) => `${VoiceApiUrl}/projects/${projectId}/calls/${callId}`;
    let data = null;
    let call = null;
    let fullRecordings = null;
    let fullTranscriptions = null;
    let sipInfo = null;
    let fax = null;
    try {
      call = await httpClient.get(apiUrl(payload.projectId, payload.callId));
      const recordings = await httpClient.get(
        apiUrl(payload.projectId, payload.callId) + "/recordings"
      );
      const transcriptions = await httpClient.get(
        apiUrl(payload.projectId, payload.callId) + "/transcriptions"
      );

      fullRecordings = await Promise.all(
        recordings.data.recordings.map((recording) => getRecording(recording.name))
      );
      fullTranscriptions = await Promise.all(
        transcriptions.data.transcriptions.map((transcription) =>
          getTranscription(transcription.name)
        )
      );

      if (call.data?.labels?.faxReceived) {
        fax = await httpClient.get(`${VoiceApiUrl}/${call.data.labels.faxReceived}`);
      }

      if (call.data.sipCallId) {
        sipInfo = await httpClient.get(apiUrl(payload.projectId, payload.callId) + "/sipInfo");
      }
    } catch (error) {
      throw error;
    } finally {
      data = {
        ...call.data,
        fullRecordings,
        fullTranscriptions,
        sipInfo: sipInfo?.data,
        fax: fax?.data,
      };
      commit("setCall", data);
    }
  },
  resetCalls({ commit, state }, payload) {
    commit("resetCalls", "");
  },
  async getCalls({ commit, state, dispatch }, to) {
    if (!to.dontReset) {
      dispatch("calls/resetCalls", "", { root: true });
    }

    if (!to.query.filters) {
      return;
    }

    const nextPageToken = state.calls.nextPageToken;
    const projectId = to.params.projectId;
    const filtersVal = to.query.filters ? JSON.parse(decodeURIComponent(to.query.filters)) : [];

    let payload = {};
    const mainPayload = {
      projectId,
      date: filtersVal.filter((el) => el.field === "date")[0]
        ? filtersVal.filter((el) => el.field === "date")[0]
        : null,
      fromFilter: filtersVal.filter((el) => el.field === "from")[0],
      toFilter: filtersVal.filter((el) => el.field === "to")[0],
      stateFilter: filtersVal.filter((el) => el.field === "state")[0],
      directionFilter: filtersVal.filter((el) => el.field === "direction")[0],
    };
    if (nextPageToken) {
      payload = {
        nextPageToken,
        ...mainPayload,
      };
    } else {
      payload = {
        projectId,
        ...mainPayload,
      };
    }

    const parameters = apiFilter(payload, "voice");

    return callsAPI.listResource(`projects/${projectId}/calls`, {
      queryString: `&filter=${parameters}`,
      commit: "calls/setCalls",
    });
  },
  async deleteRecording({ commit, state }, payload) {
    return callsAPI.deleteResource("Recording", {
      resourceName: payload.recordingName,
    });
  },
  async deleteFax({ commit, state }, payload) {
    return callsAPI.deleteResource("Fax", {
      resourceName: payload.resourceName,
    });
  },
};
