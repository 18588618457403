export default function (str1, str2) {
  function findFirstDiffPos(a, b) {
    if (a.length < b.length) {
      [a, b] = [b, a];
    }
    return [...a].findIndex((chr, i) => chr !== b[i]);
  }

  return str2.slice(findFirstDiffPos(str1, str2));
}
