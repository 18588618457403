import { httpClient } from "@/helpers/httpClient";
import { AnalyticsApiUrl } from "@/consts/configuration";
import _ from "lodash";

const stateLine = {
  series: [
    {
      groups: ["COMPLETED"],
      total: 1634,
      values: [
        72, 75, 80, 69, 77, 1, 1, 75, 65, 70, 65, 66, 3, 3, 67, 72, 80, 73, 64, 1, 2, 77, 80, 68,
        64, 64, 2, 1, 68, 65, 64,
      ],
    },
    {
      groups: ["NOT_ANSWERED"],
      total: 267,
      values: [
        10, 8, 7, 10, 8, 11, 7, 9, 8, 5, 10, 7, 12, 11, 9, 10, 6, 5, 9, 11, 7, 8, 8, 6, 6, 10, 13,
        14, 9, 7, 6,
      ],
    },
    {
      groups: ["BUSY"],
      total: 83,
      values: [
        4, 1, 3, 1, 2, 1, 1, 0, 7, 7, 3, 6, 0, 2, 0, 1, 4, 5, 6, 2, 1, 3, 6, 2, 7, 2, 1, 0, 0, 4, 1,
      ],
    },
  ],
  firstValueTime: "2021-07-19T00:00:00.0Z",
};

const stateDirectionLine = {
  series: [
    {
      groups: ["COMPLETED", "OUTGOING"],
      total: 1633,
      values: [
        63, 72, 80, 77, 65, 3, 1, 60, 64, 77, 79, 61, 3, 2, 76, 72, 62, 73, 64, 3, 3, 78, 68, 68,
        67, 61, 1, 2, 77, 74, 77,
      ],
    },
    {
      groups: ["NOT_ANSWERED", "OUTGOING"],
      total: 252,
      values: [
        7, 8, 8, 10, 7, 12, 15, 6, 6, 9, 7, 5, 10, 15, 5, 6, 5, 5, 7, 15, 6, 7, 8, 7, 6, 8, 11, 6,
        9, 6, 10,
      ],
    },
    {
      groups: ["BUSY", "OUTGOING"],
      total: 67,
      values: [
        5, 1, 3, 0, 1, 0, 2, 3, 5, 0, 0, 5, 0, 2, 2, 2, 2, 1, 7, 0, 0, 5, 7, 0, 0, 0, 2, 1, 5, 1, 5,
      ],
    },
    {
      groups: ["COMPLETED", "INCOMING"],
      total: 1633,
      values: [
        63, 72, 80, 77, 65, 3, 1, 60, 64, 77, 79, 61, 3, 2, 76, 72, 62, 73, 64, 3, 3, 78, 68, 68,
        67, 61, 1, 2, 77, 74, 77,
      ],
    },
    {
      groups: ["NOT_ANSWERED", "INCOMING"],
      total: 319,
      values: [
        12, 9, 11, 10, 8, 12, 17, 9, 11, 9, 7, 10, 10, 17, 7, 8, 7, 6, 14, 15, 6, 12, 15, 7, 6, 8,
        13, 7, 14, 7, 15,
      ],
    },
  ],
  firstValueTime: "2021-07-19T00:00:00.0Z",
};

export default {
  async getEntities({ commit }, payload) {
    // TODO: finish implementing when the api is ready. Should get all dashboards.
    try {
      const res = await httpClient.get(`${AnalyticsApiUrl}/entities`);
      commit("setEntities", res.data.entities);
    } catch (error) {
      commit("common/setError", error, { root: true });
      throw error;
    }
  },
  async getDashboards({ commit }, payload) {
    // TODO: finish implementing when the api is ready. Should get all dashboards.
    try {
      const data = await fetch(
        "./test/dashboard-calls.json" // `${AnalyticsApiUrl}/projects/${payload.projectId}/dashboards`
      );
      const dashboards = await data.json();
      commit("setDashboards", [dashboards]);
      return dashboards;
    } catch (error) {
      commit("common/setError", error, { root: true });
      throw error;
    }
  },
  async createDashboard({ commit }, payload) {
    try {
      // const data = await httpClient.post(
      //   `${AnalyticsApiUrl}/projects/${payload.projectId}/dashboards`,
      //   {
      //     displayName: payload.displayName,
      //     description: payload.description
      //   }
      // );

      // const dashboard = await data.data;
      return {
        createTime: "2021-08-19T01:08:05.2Z",
        filter: "",
        name: `projects/first/dashboards/${new Date().toISOString()}`,
        displayName: payload.displayName,
        description: payload.description,
      };
    } catch (error) {
      commit("common/setError", error, { root: true });
      throw error;
    }
  },
  async updateDashboardDisplayName({ commit }, payload) {
    try {
      // const data = await httpClient.patch(
      //   `${AnalyticsApiUrl}/${payload.name}?update_mask=displayName,description`,
      //   {
      //     displayName: payload.displayName,
      //     description: payload.description
      //   }
      // );

      // const dashboard = await data.data;
      return payload;
    } catch (error) {
      commit("common/setError", error, { root: true });
      throw error;
    }
  },
  async updateDashboard({ commit }, payload) {
    try {
      // const data = await httpClient.patch(
      //   `${AnalyticsApiUrl}/${payload.dashboardName}?update_mask=reports`,
      //   {
      //     dashboard: payload.dashboard
      //   }
      // );

      // const dashboard = await data.data;
      // return dashboard;

      return true;
    } catch (error) {
      commit("common/setError", error, { root: true });
      throw error;
    }
  },
  async getReport({ commit }, payload) {
    // TODO: finish implementing when the api is ready.
    try {
      // const data = await httpClient.get(
      //   `${AnalyticsApiUrl}/${payload.reportName}`
      // );

      let filename = "";

      if (payload.reportName === "projects/first/reports/calls-line") {
        filename = "./test/total-calls-by-day-line.json";
      }

      if (payload.reportName === "projects/first/reports/calls-state-line") {
        filename = "./test/total-calls-by-day-grouped-by-state-line.json";
      }

      if (payload.reportName === "projects/first/reports/calls-state-direction-line") {
        filename = "./test/total-calls-by-day-grouped-by-state-and-direction-line.json";
      }

      const data = await fetch(filename);
      const report = await data.json();
      return report.report;
    } catch (error) {
      commit("common/setError", error, { root: true });
      throw error;
    }
  },
  async getReports({ commit }, payload) {
    // TODO: finish implementing when the api is ready.
    try {
      // const data = await httpClient.get(
      //   `${AnalyticsApiUrl}/${payload.reportName}`
      // );

      const data = await fetch("./test/reports.json");
      const reports = await data.json();

      commit("setReports", reports);
      return reports;
    } catch (error) {
      commit("common/setError", error, { root: true });
      throw error;
    }
  },
  async createReport({ commit }, payload) {
    try {
      // const data = await httpClient.post(
      //   `${AnalyticsApiUrl}/projects/${payload.projectId}/dashboards`,
      //   {
      //     displayName: payload.displayName,
      //     description: payload.description
      //   }
      // );

      // const dashboard = await data.data;
      return {
        createTime: "2021-08-19T01:08:05.2Z",
        name: `projects/first/reports/${new Date().toISOString()}`,
        displayName: payload.displayName,
        description: payload.description,
      };
    } catch (error) {
      commit("common/setError", error, { root: true });
      throw error;
    }
  },
  async updateReport({ commit }, payload) {
    try {
      // const data = await httpClient.patch(
      //   `${AnalyticsApiUrl}/${payload.name}?update_mask=displayName,description`,
      //   {
      //     displayName: payload.displayName,
      //     description: payload.description
      //   }
      // );

      // const dashboard = await data.data;
      return payload;
    } catch (error) {
      commit("common/setError", error, { root: true });
      throw error;
    }
  },
  async deleteReport({ commit }, payload) {
    try {
      // const data = await httpClient.delete(
      //   `${AnalyticsApiUrl}/${payload.name}?update_mask=displayName,description`,
      //   {
      //     displayName: payload.displayName,
      //     description: payload.description
      //   }
      // );

      // const dashboard = await data.data;
      return payload;
    } catch (error) {
      commit("common/setError", error, { root: true });
      throw error;
    }
  },
  async runQuery({ commit }, payload) {
    try {
      const data = await httpClient.post(`${AnalyticsApiUrl}/${payload.parent}:runQuery`, {
        queries: payload.queries,
        startTime: payload.startTime,
        endTime: payload.endTime,
      });
      return data.data.results;
    } catch (error) {
      commit("common/setError", error, { root: true });
      throw error;
    }
  },
};
