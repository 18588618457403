export default {
  overview: null,
  campaignOverview: null,
  ideas: [],
  all: [],
  list: {
    data: [],
    nextPageToken: "",
    page: 1,
    itemsPerPage: 20,
    totalSize: 0,
  },
  campaignLinks: {
    data: [],
    nextPageToken: "",
    page: 1,
    itemsPerPage: 20,
    totalSize: 0,
  },
  campaignRecipients: {
    data: [],
    nextPageToken: "",
    page: 1,
    itemsPerPage: 20,
    totalSize: 0,
  },
  resource: null,
  duplicate: null,
};
