export default {
  resetChecks(state, payload) {
    state.checks = {
      data: [],
      nextPageToken: "",
    };
  },
  setChecks(state, payload) {
    state.checks = {
      data: state.checks.data.concat(payload.checks),
      nextPageToken: payload.nextPageToken,
    };
  },
};
