export default {
  all: [],
  list: {
    data: [],
    nextPageToken: "",
    page: 1,
    itemsPerPage: 20,
    totalSize: 0,
  },
  resource: null,
};
