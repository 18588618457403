export default {
  resetCliState(state, payload) {
    state.cli = null;
    state.clis = {
      data: [],
      nextPageToken: "",
      page: 1,
      itemsPerPage: 20,
    };
    (state.check = {}),
      (state.checks = {
        data: [],
        nextPageToken: "",
      });
    state.subscription = null;
    state.subscriptions = {
      data: [],
      nextPageToken: "",
    };
    state.comment = {};
    state.comments = {
      data: [],
      nextPageToken: "",
    };
  },
  resetClis(state, payload) {
    state.clis = {
      data: [],
      nextPageToken: "",
      page: 1,
      itemsPerPage: 20,
    };
  },
  resetCli(state, payload) {
    state.cli = {};
  },
  setOverview(state, payload) {
    state.overview = payload;
  },
  setClis(state, payload) {
    state.clis.data = payload;
  },
  setCli(state, payload) {
    state.cli = payload;
  },
  setClisPagination(state, payload) {
    state.clis.page = payload.page;
    state.clis.itemsPerPage = payload.itemsPerPage;
  },
  UPDATE_CLI(state, payload) {
    const item = state.clis.data.find((i) => i.name === payload.name);
    Object.assign(item, payload);
  },
};
