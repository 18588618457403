export default {
  resetFlowState(state, payload) {
    state.nodeForm = false;
    state.flow = {};
    state.flowVersion = {};
    state.flows = [];
    state.selectedStep = {};
    state.versions = [];
  },
  setStepForm(state, payload) {
    state.nodeForm = !state.nodeForm;
  },
  setSelectedStep(state, payload) {
    state.selectedStep = payload;
  },
  setFlow(state, payload) {
    state.flow = payload;
  },
  setFlowVersion(state, payload) {
    state.flowVersion = payload;
  },
  setVersions(state, payload) {
    state.versions = payload;
  },
  setFlows(state, payload) {
    state.flows = payload;
  },
  setTtsVoice(state, payload) {
    state.ttsVoice = payload;
  },
  setVoices(state, payload) {
    state.voices = payload;
  },
  resetFlows(state, payload) {
    state.flows = [];
  },
};
