import { ResourceManagerApiUrl } from "@/consts/configuration";
import { httpClient } from "@/helpers/httpClient";
import { allPermissions } from "@/consts/configuration";
import FirebaseAuthProvider from "@/api/auth/FirebaseAuthProvider";

let authApi: IAuthProvider = null;
// TODO: update to use the tenant config when ready
// if (AUTH_PROVIDER === "AUTH_PROVIDER_FIREBASE") {
authApi = new FirebaseAuthProvider();
// }

export default {
  async initializeSettingsView({ commit, state, dispatch, rootState, rootGetters }, to) {
    const hasBillingAccountPermission = rootGetters["billingAccounts/checkBillingPermissions"](
      allPermissions.billingBillingAccountsGetAccountDetails
    );

    const hasSigningKeyPermission = rootGetters["user/checkPermissions"](
      allPermissions.getSigningKey
    );

    try {
      if (rootState.billingAccounts.billingAccount.billingAccount && hasBillingAccountPermission) {
        const billingAccountId =
          rootState.billingAccounts.billingAccount.billingAccount.split("/")[1];

        await dispatch(
          "billingAccounts/getBillingAccountDetails",
          {
            billingAccountId,
          },
          { root: true }
        );

        await dispatch(
          "billingAccounts/getBillingAccountInfo",
          {
            billingAccountId,
          },
          { root: true }
        );
      }
      const projectName = `projects/${to.params.projectId}`;
      if (hasSigningKeyPermission) {
        await dispatch(
          "user/getSigningKey",
          {
            projectName,
          },
          { root: true }
        );
      }
      return { success: true };
    } catch (error) {
      throw error;
    }
  },
  async getSigningKey({ commit, state }, payload) {
    try {
      const data = await httpClient.post(
        `${ResourceManagerApiUrl}/${payload.projectName}:getSigningKey`,
        {}
      );
      commit("setProjectSigningKey", data.data);
      return data.data;
    } catch (error) {
      commit("common/setError", error, { root: true });
      throw error;
    }
  },
  async renewSigningKey({ commit, state }, payload) {
    try {
      const data = await httpClient.post(
        `${ResourceManagerApiUrl}/${payload.projectName}:renewSigningKey`
      );
      commit("setProjectSigningKey", data.data);
      return data.data;
    } catch (error) {
      commit("common/setError", error, { root: true });
      throw error;
    }
  },
  async testProjectIamPermissions({ commit, state }, payload) {
    try {
      const data = await httpClient.post(
        `${ResourceManagerApiUrl}/projects/${payload.projectId}:testIamPermissions`,
        {
          permissions: payload.permissions,
        }
      );
      return data.data;
    } catch (error) {
      commit("common/setError", error, { root: true });
      throw error;
    }
  },
  async testIamPermissions({ commit, state }, payload) {
    try {
      const data = await httpClient.post(
        `${ResourceManagerApiUrl}/projects/${payload.projectId}:testIamPermissions`,
        {
          permissions: payload.permissions,
        }
      );
      commit("setPermissions", data.data);
      return data.data;
    } catch (error) {
      commit("common/setError", error, { root: true });
      throw error;
    }
  },
  updateCurrentProject({ commit }, payload) {
    commit("user/setCurrentProject", payload, { root: true });
  },
  async getProjects({ commit, state }) {
    try {
      let params = "";
      const allSubs = [];
      do {
        const data = await httpClient.get(
          `${ResourceManagerApiUrl}/projects?page_size=100${params ? `&page_token=${params}` : ""}`
        );
        allSubs.push(data.data.projects);
        params = data.data.nextPageToken;
      } while (params);
      commit("setProjects", allSubs.flat());
      return allSubs.flat();
    } catch (error) {
      throw error;
    }
  },
  async createProject({ commit }, payload) {
    commit("common/setLoading", true, { root: true });
    try {
      const project = await httpClient.post(`${ResourceManagerApiUrl}/projects`, {
        name: `projects/${payload.name}`,
        displayName: payload.displayName,
      });
      commit("common/setLoading", false, { root: true });
      commit(
        "common/setSuccess",
        {
          data: "Project Created",
        },
        { root: true }
      );
      return project.data;
    } catch (error) {
      commit("common/setLoading", false, { root: true });
      commit("common/setError", error, { root: true });
      throw error;
    }
  },
  async updateProject({ commit }, payload) {
    commit("common/setLoading", true, { root: true });
    try {
      const project = await httpClient.patch(`${ResourceManagerApiUrl}/${payload.name}`, {
        displayName: payload.displayName,
      });
      commit("common/setLoading", false, { root: true });
      commit(
        "common/setSuccess",
        {
          data: "Project Updated",
        },
        { root: true }
      );
      commit("user/setCurrentProject", project.data, { root: true });
      return project.data;
    } catch (error) {
      commit("common/setLoading", false, { root: true });
      commit("common/setError", error, { root: true });
      throw error;
    }
  },
  async deleteProject({ commit }, payload) {
    commit("common/setLoading", true, { root: true });
    try {
      const project = await httpClient.delete(`${ResourceManagerApiUrl}/${payload.name}`);
      commit("common/setLoading", false, { root: true });
      commit(
        "common/setSuccess",
        {
          data: "Project Deleted",
        },
        { root: true }
      );
      return project;
    } catch (error) {
      commit("common/setLoading", false, { root: true });
      commit("common/setError", error, { root: true });
      throw error;
    }
  },
  clearLoading({ commit }) {
    commit("common/setLoading", false, { root: true });
  },
  async loginUser({ dispatch, commit }, payload) {
    commit("common/setLoading", true, { root: true });
    try {
      const user = await authApi.login(
        payload.email,
        payload.password,
        payload.tenant,
        payload.primary,
        payload.otp
      );

      commit("common/setLoading", false, { root: true });
      commit("setUser", user);
      return user;
    } catch (error) {
      commit("common/setLoading", false, { root: true });
      throw error;
    }
  },
  observeAuthState(context, callback) {
    return authApi.stateObserver(callback);
  },
  autoLogin({ commit }, payload) {
    commit("setUser", payload);
    return payload;
  },
  async verifyEmail({ commit }) {
    try {
      const res = await authApi.verifyEmail();
      commit(
        "common/setSuccess",
        {
          data: "Verification email sent",
        },
        { root: true }
      );
      return res;
    } catch (error) {
      commit("common/setError", { message: "Email verfication failed" }, { root: true });
      // throw error;
    }
  },
  async confirmEmailVerification({ commit }, payload) {
    try {
      const res = await authApi.confirmEmailVerification(payload);
      commit(
        "common/setSuccess",
        {
          data: "Email verification complete",
        },
        { root: true }
      );
      return res;
    } catch (error) {
      commit("common/setError", { message: "Email verfication failed" }, { root: true });
      // throw error;
    }
  },
  async cancelEnrollMfa({ commit }, payload) {
    try {
      const res = await authApi.cancelEnrollMfa(payload);
      commit(
        "common/setSuccess",
        {
          data: "Multi-factor authentication disabled",
        },
        { root: true }
      );
      return res;
    } catch (error) {
      commit("common/setError", { message: "Something went wrong" }, { root: true });
      // throw error;
    }
  },

  async getMultiFactors({ commit }, payload) {
    try {
      const res = await authApi.getMultiFactors();

      console.log(res);
      commit("setMultiFactors", res.enrolledFactors);
      return res;
    } catch (error) {
      throw error;
    }
  },
  async getToken({ commit }) {
    try {
      const res = await authApi.getToken();
      return res;
    } catch (error) {
      throw error;
    }
  },
  async getClaims({ commit }) {
    try {
      const res = await authApi.getClaims();
      return res;
    } catch (error) {
      throw error;
    }
  },
  async getCurrentUser({ commit }) {
    try {
      const res = await authApi.getCurrentUser();
      return res;
    } catch (error) {
      throw error;
    }
  },
  async generateTotpSecret({ commit }, payload) {
    try {
      const res = await authApi.generateTotpSecret(payload);
      return res;
    } catch (error) {
      throw error;
    }
  },
  async enrollUser({ commit }, verificationCode) {
    try {
      const res = await authApi.enrollUser(verificationCode);
      return res;
    } catch (error) {
      throw error;
    }
  },
  async unenrollUser({ commit }, payload) {
    try {
      const res = await authApi.unenrollUser(payload.id, payload.password, payload.otp);
      commit(
        "common/setSuccess",
        {
          data: "Multi-factor authentication has been disabled",
        },
        { root: true }
      );
      return res;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  async resetPassword({ commit }, payload) {
    try {
      const { email } = payload;
      commit("common/setLoading", true, { root: true });
      const res = await authApi.requestPasswordReset(email);
      commit("common/setLoading", false, { root: true });
      commit(
        "common/setSuccess",
        {
          data: "Reset password email sent",
        },
        { root: true }
      );
      return res;
    } catch (error) {
      commit("common/setLoading", false, { root: true });
      commit("common/setError", error, { root: true });
      throw error;
    }
  },
  async confirmPasswordReset({ commit }, payload) {
    try {
      const res = await authApi.confirmPasswordReset(payload.code, payload.newPassword);
      commit(
        "common/setSuccess",
        {
          data: "Password updated",
        },
        { root: true }
      );
      return res;
    } catch (error) {
      commit("common/setError", error, { root: true });
      throw error;
    }
  },
  async updatePassword({ commit }, payload) {
    try {
      commit("common/setLoading", true, { root: true });
      const res = await authApi.changePassword(
        payload.email,
        payload.oldPassword,
        payload.newPassword,
        payload.otp
      );
      commit("common/setLoading", false, { root: true });
      commit(
        "common/setSuccess",
        {
          data: "Password updated",
        },
        { root: true }
      );
      return res;
    } catch (error) {
      commit("common/setLoading", false, { root: true });
      throw error;
    }
  },
  resetState({ commit, state, rootState }) {
    commit("calls/resetVoiceState", null, { root: true });
    commit("phoneNumberInstances/resetNumbersState", null, { root: true });
    commit("messages/resetMessagingState", null, { root: true });
    commit("iam/resetIamState", null, { root: true });
    commit("flows/resetFlowState", null, { root: true });
    commit("billingAccounts/resetBillingReports", null, { root: true });
    commit("invoices/resetState", null, { root: true });
    commit("payments/resetState", null, { root: true });
    commit("clis/resetCliState", null, { root: true });
  },
  resetBillingState({ commit, state, rootState }) {
    commit("user/setPermissions", [], { root: true });
    commit("user/setProjectSigningKey", null, { root: true });
    commit("billingAccounts/resetBillingReports", null, { root: true });
    commit("invoices/resetState", null, { root: true });
    commit("payments/resetState", null, { root: true });
    commit("billingAccounts/setBillingPermissions", {}, { root: true });
    commit("billingAccounts/setBillingAccount", {}, { root: true });
    commit("billingAccounts/setBillingAccountInfo", {}, { root: true });
    commit("user/setCurrentProject", null, { root: true });
    commit("user/setProjects", [], { root: true });
  },
  logoutUser({ commit }) {
    authApi.logout();
    commit("user/setUser", null, { root: true });
    commit("user/setPermissions", [], { root: true });
    commit("user/setProjectSigningKey", null, { root: true });
    commit("billingAccounts/resetBillingReports", null, { root: true });
    commit("invoices/resetState", null, { root: true });
    commit("payments/resetState", null, { root: true });
    commit("billingAccounts/setBillingPermissions", {}, { root: true });
    commit("billingAccounts/setBillingAccount", {}, { root: true });
    commit("billingAccounts/setBillingAccountInfo", {}, { root: true });
    commit("user/setProjects", [], { root: true });
  },
};
