<template>
  <div>
    <label
      class="en-label"
      v-if="label"
    >
      {{ label }}
    </label>
    <p
      class="en-p mb-4"
      v-if="description"
    >
      {{ description }}
    </p>
    <v-text-field
      label=""
      outlined
      dense
      solo
      flat
      v-bind="{ ...$attrs }"
      v-on="$listeners"
    >
      <template
        v-for="(_, scopedSlotName) in $scopedSlots"
        #[scopedSlotName]="slotData"
      >
        <slot
          :name="scopedSlotName"
          v-bind="slotData"
        />
      </template>
      <template
        v-for="(_, slotName) in $slots"
        #[slotName]
      >
        <slot :name="slotName" />
      </template>
    </v-text-field>
  </div>
</template>

<script>
export default {
  // Setting inheritAttrs to false enables us to forward all attributes to v-text-field using $attrs.
  // Currently can't support defineOptions in vue 2.7 without a bit of work, so opting not to use script setup for this component
  inheritAttrs: false,
  props: {
    label: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
  },
};
</script>
