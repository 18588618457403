export default {
  resetState(state, payload) {
    state.list.data = [];
    state.list.nextPageToken = "";
    state.list.page = 1;
    state.list.itemsPerPage = 20;
    state.resource = null;
  },
  resetCampaignLinks(state, payload) {
    state.campaignLinks.data = [];
    state.campaignLinks.nextPageToken = "";
    state.campaignLinks.page = 1;
    state.campaignLinks.itemsPerPage = 20;
  },
  resetCampaignRecipients(state, payload) {
    state.campaignRecipients.data = [];
    state.campaignRecipients.nextPageToken = "";
    state.campaignRecipients.page = 1;
    state.campaignRecipients.itemsPerPage = 20;
  },
  setIdeas(state, payload) {
    state.ideas = payload.ideas;
  },
  resetIdeas(state, payload) {
    state.ideas = [];
  },
  setOverview(state, payload) {
    state.overview = payload;
  },
  setCampaignOverview(state, payload) {
    state.campaignOverview = payload;
  },
  setAll(state, payload) {
    state.all = payload;
  },
  setList(state, payload) {
    state.list.data = state.list.data.concat(payload.campaigns);
    state.list.nextPageToken = payload.nextPageToken;
    state.list.totalSize = payload.totalSize;
  },
  setResource(state, payload) {
    state.resource = payload;
  },
  setDuplicate(state, payload) {
    state.duplicate = payload;
  },
  setResourcePagination(state, payload) {
    state.list.page = payload.page;
    state.list.itemsPerPage = payload.itemsPerPage;
  },
  setCampaignLinks(state, payload) {
    state.campaignLinks.data = state.campaignLinks.data.concat(payload.links);
    state.campaignLinks.nextPageToken = payload.nextPageToken;
    state.campaignLinks.totalSize = payload.totalSize;
  },
  setCampaignLinksPagination(state, payload) {
    state.campaignLinks.page = payload.page;
    state.campaignLinks.itemsPerPage = payload.itemsPerPage;
  },
  setCampaignRecipients(state, payload) {
    state.campaignRecipients.data = state.campaignRecipients.data.concat(payload.recipients);
    state.campaignRecipients.nextPageToken = payload.nextPageToken;
    state.campaignRecipients.totalSize = payload.totalSize;
  },
  setCampaignRecipientsPagination(state, payload) {
    state.campaignRecipients.page = payload.page;
    state.campaignRecipients.itemsPerPage = payload.itemsPerPage;
  },
};
