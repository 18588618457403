import _ from "lodash";
import store from "./store";
import { httpClient } from "@/helpers/httpClient";
import {
  LocalApiUrl,
  adminFeatures,
  phoneNumberFeatures,
  cloudSMSFeatures,
  cloudVoiceFeatures,
  cloudSIPFeatures,
  flowFeatures,
  repmanFeatures,
  smsMarketingFeatures,
  tenantAdminFeatures,
  portInRequestsFeatures,
  serviceAccountFeatures,
  bundlesFeatures,
  pricingFeatures,
  billingPermissions,
  permissions,
} from "@/consts/configuration";
import { setEnabledFeatures } from "vue-feature-flipping";

export function initializeFeature($store) {
  const tenantConfigRes = $store.state.user.tenantConfig;

  // TODO: implement api call for this
  const tenantIamPermissions = [];

  // Get actual config using current hostname
  // res.tenantConfig
  const tenantConfig = {
    ...tenantConfigRes,
    products: {
      phoneNumbers: {},
      smsMarketing: {},
      cloudSMS: {},
      cloudVoice: {},
      cloudSip: {},
      flow: {},
    },
  };

  const link = document.querySelector("[rel='icon']");
  link.setAttribute("href", tenantConfig.console.favIconUri);

  const featuresArr = [];
  featuresArr.push(adminFeatures);

  if (tenantConfig.primary) {
    featuresArr.push(serviceAccountFeatures);
  }

  if (tenantConfig.products) {
    if (tenantConfig.products.phoneNumbers) {
      featuresArr.push(phoneNumberFeatures);
    }
    if (tenantConfig.products.smsMarketing && tenantConfig.primary) {
      featuresArr.push(smsMarketingFeatures);
    }
    if (tenantConfig.products.cloudSMS && tenantConfig.primary) {
      featuresArr.push(cloudSMSFeatures);
    }
    if (tenantConfig.products.cloudVoice) {
      featuresArr.push(cloudVoiceFeatures);
    }
    if (tenantConfig.products.cloudSip) {
      featuresArr.push(cloudSIPFeatures);
    }
    if (tenantConfig.products.flow) {
      featuresArr.push(flowFeatures);
    }
  }

  // Tenant admin role will actually be a owner, viewer or editor enum. TODO: handle different admin roles later on.
  if (tenantConfigRes.tenantAdminRole) {
    featuresArr.push(tenantAdminFeatures);
    featuresArr.push(portInRequestsFeatures);
  }

  // Enable service accounts if the user is a tenant admin and the current tenant is not Enfonica.
  if (tenantConfigRes.tenantAdminRole && !tenantConfig.primary) {
    featuresArr.push(serviceAccountFeatures);
  }

  $store.commit("user/setTenantConfig", {
    ...tenantConfig,
    enabledFeatures: featuresArr,
    // @ts-ignore
    userTenantConfig: store.state.user.tenantConfig.userTenantConfig,
  });
  setEnabledFeatures(featuresArr.flat());
}

async function initializeSpecialFeatures(projectId, $store) {
  try {
    const res = await httpClient.post(`${LocalApiUrl}initializeSpecialFeatures`, {
      projectId: projectId.split("/")[1],
    });
    const featuresArr = _.clone($store.state.user.tenantConfig.enabledFeatures);
    const userTenantConfig = $store.state.user.tenantConfig.userTenantConfig;

    if (userTenantConfig?.billing?.showPricing) {
      featuresArr.push(pricingFeatures);
    }

    if (userTenantConfig?.billing?.enableBundles) {
      featuresArr.push(bundlesFeatures);
    }

    if (res.data.success) {
      featuresArr.push(repmanFeatures);
      setEnabledFeatures(featuresArr.flat());
    } else {
      setEnabledFeatures(featuresArr.flat().filter((el) => !el.includes("cli.")));
    }
  } catch (error) {
    // Should display some kind of error page
  }
}
export async function initializeAuth() {
  const res = await httpClient.post(`${LocalApiUrl}initializeConsole`, {
    hostName: location.hostname,
    userClaim: "enfonica",
  });

  const tenantConfig = res.data.tenantConfig;
  const userTenantConfig = res.data.userTenantConfig;

  store.commit("user/setTenantConfig", {
    ...tenantConfig,
    // @ts-ignore
    enabledFeatures: store.state.user.tenantConfig.enabledFeatures,
    userTenantConfig,
  });
  store.commit("user/setAppReady");
}
export async function initializeProject(
  project: {
    name: string;
  },
  $store,
  $router
) {
  try {
    // Get the billing account associated with the project
    $store.commit("user/setCurrentProject", project, { root: true });
    initializeSpecialFeatures(project.name, $store);

    const res = await $store.dispatch("user/testIamPermissions", {
      permissions,
      projectId: project.name.split("/")[1],
    });

    if (!location.hash.includes("#/billingAccounts")) {
      const resBillingAccounts = await $store.dispatch("billingAccounts/getBillingAccount", {
        projectId: project.name,
      });

      if (resBillingAccounts?.data?.billingAccount) {
        $store.commit("billingAccounts/setBillingAccount", resBillingAccounts.data);
        // Get the permissions the user has for the associated billing account
        return $store.dispatch("billingAccounts/testBillingIamPermission", {
          billingPermissions,
          billingAccountId: resBillingAccounts.data.billingAccount,
        });
      }
    } else {
      const val = `billingAccounts/${location.hash.split("/")[2]}`;
      $store.commit("billingAccounts/setBillingAccount", {
        billingAccount: val,
      });

      return $store.dispatch("billingAccounts/testBillingIamPermission", {
        billingPermissions,
        billingAccountId: val,
      });
    }
  } catch (error) {
    console.error("Error initializing project:", error);
  }
}
