import { MediaApiUrl } from "@/consts/configuration";
import { httpClient } from "@/helpers/httpClient";
import { CrudService } from "@/api";
const mediaApi = new CrudService(MediaApiUrl);

export default {
  resetMedia({ commit, state }: any, payload: any) {
    commit("resetMedia");
  },
  async listMedia(
    { commit, state }: any,
    payload: {
      projectName: any;
    }
  ) {
    commit("common/setLoading", true, { root: true });
    try {
      let params: string;
      const allSubs = [];
      do {
        const res = await httpClient.get(
          `${MediaApiUrl}/projects/${payload.projectName}/media?page_size=100${
            params ? `&page_token=${params}` : ""
          }`
        );
        allSubs.push(res.data.media);
        params = res.data.nextPageToken;
      } while (params !== "");
      commit("common/setLoading", false, { root: true });
      commit("setMediaList", allSubs.flat());
      return allSubs.flat();
    } catch (error) {
      commit("common/setLoading", false, { root: true });
      commit("common/setError", error, { root: true });
      throw error;
    }
  },
  async getMedia(
    { commit, state }: any,
    payload: {
      mediaName: any;
    }
  ) {
    return mediaApi.getResource({
      resourceName: payload.mediaName,
      commit: "",
    });
  },
  async updateMedia(
    { commit, state }: any,
    payload: {
      mediaName: string;
      payload: any;
    }
  ) {
    return mediaApi.updateResource("Media", {
      resourceName: payload.mediaName,
      resource: {
        ...payload.payload,
      },
    });
  },
  async deleteMedia(
    { commit, state }: any,
    payload: {
      mediaName: any;
    }
  ) {
    return mediaApi.deleteResource("Media", {
      resourceName: payload.mediaName,
    });
  },
};
