const ContactListsList = () => import("@/views/ContactLists/ContactListsList");
const ContactsListsView = () => import("@/views/ContactLists/ContactListsView");

export default [
  {
    path: "/projects/:projectId/contactLists/list",
    name: "ContactListLists",
    component: ContactListsList,
    meta: {
      pageTitle: "Contact lists",
      featureFlipping: {
        key: "contactLists.list",
        default: false,
      },
      headerImg: "./header-sms.png",
      layout: "LayoutMain",
      tabs: "smsMarketing",
      dispatchAction: "contactLists/listAll",
    },
  },
  {
    path: "/projects/:projectId/contactLists/:contactListId",
    name: "ContactListsView",
    component: ContactsListsView,
    meta: {
      pageTitle: "View contact list",
      featureFlipping: {
        key: "contactLists.view",
        default: false,
      },
      headerImg: "./header-sms.png",
      layout: "LayoutMain",
      tabs: "smsMarketing",
      back: true,
      backLink: "/contactLists/list",
      dispatchAction: "contactLists/getResource",
    },
  },
];
