import _ from "lodash";
import { NumberingApiUrl } from "@/consts/configuration";
import { httpClient } from "@/helpers/httpClient";

export default {
  async listResource({ commit, state }, to) {
    const payload = {
      prefix: to.query.search ? to.query.search : "",
      country_code: to.query.country ? to.query.country : "AU",
      zone: to.query.zone ? JSON.parse(to.query.zone).b : "",
      number_type: to.query.type ? to.query.type : "",
      capabilities: to.query.capability ? JSON.parse(to.query.capability) : "",
    };

    let parameters = "?page_size=100";

    if (payload.country_code) {
      parameters = parameters + "&country_code=" + payload.country_code;
    }

    if (payload.zone) {
      parameters =
        parameters + "&zone=" + `numberingPlans/${payload.country_code}/zones/${payload.zone}`;
    }

    if (payload.capabilities.length !== 0) {
      const sms = payload.capabilities.filter((el) => el.value === "SMS")[0];
      const mms = payload.capabilities.filter((el) => el.value === "MMS")[0];
      const voice = payload.capabilities.filter((el) => el.value === "VOICE")[0];

      if (sms) {
        parameters = parameters + `&supports_sms=true`;
      }
      if (mms) {
        parameters = parameters + `&supports_mms=true`;
      }
      if (voice) {
        parameters = parameters + `&supports_voice=true`;
      }
    }

    if (payload.number_type) {
      parameters = parameters + `&number_type=${payload.number_type}`;
    }

    if (payload.prefix) {
      parameters = parameters + `&prefix=${payload.prefix}`;
    }

    try {
      let params = "";
      const allSubs = [];
      do {
        const data = await httpClient.get(
          `${NumberingApiUrl}/phoneNumbers:search${parameters}${
            params ? `&page_token=${params}` : ""
          }`
        );
        allSubs.push(data.data.phoneNumbers);
        params = data.data.nextPageToken;
      } while (params);
      commit("setList", { phoneNumbers: allSubs.flat() });
      return allSubs.flat();
    } catch (error) {
      throw error;
    }
  },
};
