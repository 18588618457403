import { stateOptions, directionOptions, encodingOptions } from "@/consts/messaging";

// Analytics Routes
const AnalyticsDashboards = () => import("@/views/Analytics/AnalyticsDashboard");
const AnalyticsReports = () => import("@/views/Analytics/AnalyticsReports");
const AnalyticsSubscriptions = () => import("@/views/Analytics/AnalyticsSubscriptions");

export default [
  {
    path: "/projects/:projectId/analytics/dashboard",
    name: "Analytics Dashboard",
    component: AnalyticsDashboards,
    meta: {
      featureFlipping: {
        key: "dashboards.list",
        default: false,
      },
      headerImg: "./header-analytics.png",
      layout: "LayoutMain",
      tabs: "analytics",
      filters: [
        {
          label: "Date",
          field: "date",
          type: "Date",
        },
        {
          label: "To",
          field: "to",
          type: "String",
        },
        {
          label: "From",
          field: "from",
          type: "String",
        },
        {
          label: "State",
          field: "state",
          type: "Multi",
          options: stateOptions,
        },
        {
          label: "Direction",
          field: "direction",
          type: "Enum",
          options: directionOptions,
        },
        {
          label: "Encoding",
          field: "encoding",
          type: "Enum",
          options: encodingOptions,
        },
      ],
    },
  },
  {
    path: "/projects/:projectId/analytics/reports",
    name: "Analytics Reports",
    component: AnalyticsReports,

    meta: {
      featureFlipping: {
        key: "reports.list",
        default: false,
      },
      headerImg: "./header-analytics.png",
      layout: "LayoutMain",
      tabs: "analytics",
    },
  },
  {
    path: "/projects/:projectId/analytics/subscriptions/list",
    name: "Analytics subscriptions",
    component: AnalyticsSubscriptions,
    meta: {
      featureFlipping: {
        key: "subscriptions.list",
        default: false,
      },
      headerImg: "./header-analytics.png",
      layout: "LayoutMain",
      tabs: "analytics",
      back: true,
    },
  },
];
