var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"en-base-component en-phone-number-input en-flex",class:[{ 'en-is-dark': _vm.dark }, `en-phone-number-input--${_vm.size}`],staticStyle:{"position":"relative"},attrs:{"id":_vm.id}},[(!_vm.noCountrySelector)?_c('EnSelect',{ref:"CountrySelector",staticClass:"country-selector",class:{
      'no-padding-left': _vm.noFlags || !_vm.countryCode,
    },attrs:{"value":_vm.countryCode,"options":_vm.countriesSorted,"search":"","position":_vm.position,"search-placeholder":_vm.t.countrySelectorSearchPlaceholder,"items-height":_vm.countriesHeight,"hint":_vm.shouldChooseCountry ? _vm.t.countrySelectorError : null,"size":_vm.size,"success":_vm.isValid && !_vm.noValidation,"error":_vm.asYouTypeNumber && !_vm.isValid,"disabled":_vm.disabled,"input-value":_vm.callingCode,"list-width":480,"config":{
      labelKey: 'dialCode',
      searchKey: 'name',
      valueKey: 'iso2',
    },"color":_vm.color},on:{"input":function($event){return _vm.setCountryCode($event, true)}},scopedSlots:_vm._u([{key:"default",fn:function({ option }){return [_c('div',{staticClass:"en-flex en-align-center"},[(!_vm.noFlags)?_c('div',{staticClass:"country-selector__flag-container mr-2"},[_c('v-img',{attrs:{"width":"30","src":`/countries/${option.iso2.toLowerCase()}.png`}})],1):_vm._e(),(_vm.showCodeOnList)?_c('span',{staticClass:"country-selector__calling-code en-flex-fixed en-text-muted",class:{
            'en-text-muted-dark': option.isSelected,
          }},[_vm._v(" +"+_vm._s(option.dialCode)+" ")]):_vm._e(),_c('div',{staticClass:"en-dots-text en-flex-1 en-text-left en-text-color text-left",class:{
            'en-text-white': option.isSelected,
          },staticStyle:{"font-size":"14px"}},[_vm._v(" "+_vm._s(option.name)+" ")])])]}}],null,false,3394021343)}):_vm._e(),(_vm.countryCode && !_vm.noFlags && !_vm.noCountrySelector)?_c('button',{staticClass:"en-phone-number-input__country-flag mr-2",attrs:{"tabindex":"-1"},on:{"click":_vm.focusCountrySelector}},[_c('v-img',{attrs:{"width":"34","height":"20","src":`/countries/${_vm.countryCode.toLowerCase()}.png`}})],1):_vm._e(),_c('EnInput',_vm._b({ref:"PhoneNumberInput",staticClass:"input-phone-number en-flex-1",class:{
      'has-border-radius': _vm.noCountrySelector,
    },staticStyle:{"cursor":"text !important","font-size":"14px !important"},attrs:{"customId":_vm.customId,"id":_vm.uniqueId ? `${_vm.uniqueId}_phone_number` : null,"value":_vm.asYouTypeNumber,"placeholder":_vm.placeholder || _vm.t.phoneNumberLabel,"hint":_vm.hint || _vm.hintValue,"disabled":_vm.disabled,"size":_vm.size,"success":_vm.isValid && !_vm.noValidation,"error":_vm.asYouTypeNumber && !_vm.isValid,"clearable":"","color":_vm.color},on:{"keydown":[(e) => {
        _vm.lastKeyPressed = e.keyCode;
      },function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"tab",9,$event.key,"Tab"))return null;$event.preventDefault();return _vm.$emit('tabdown', $event)}],"focus":function($event){return _vm.$emit('focus', $event)},"blur":function($event){return _vm.$emit('blur', $event)},"change":function($event){return _vm.$emit('change', $event)},"clear":function($event){return _vm.$emit('clear', $event)},"input":_vm.buildResults}},'EnInput',_vm.$attrs,false),[_vm._t("icon-left",null,{"slot":"icon-left"}),_vm._t("icon-right",null,{"slot":"icon-right"})],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }