import DraggableWrapper from "./helpers/draggableWrapper";
import FlowBuilder from "./components/FlowBuilder";
import FlowBuilderStep from "./components/FlowBuilderStep";
import FlowBuilderBlock from "./components/FlowBuilderBlock";
import FlowBuilderNewStep from "./components/FlowBuilderNewStep";
import FlowBuilderDragHandle from "./components/FlowBuilderDragHandle";

// Declare install function executed by Vue.use()
export function install(_Vue) {
  const Vue = _Vue;
  Vue.use(DraggableWrapper, {
    handle: ".flow-builder-drag-handle",
  });

  Vue.component("FlowBuilder", FlowBuilder);
  Vue.component("FlowBuilderStep", FlowBuilderStep);
  Vue.component("FlowBuilderBlock", FlowBuilderBlock);
  Vue.component("FlowBuilderNewStep", FlowBuilderNewStep);
  Vue.component("FlowBuilderDragHandle", FlowBuilderDragHandle);
}

// Create module definition for Vue.use()
const plugin = {
  install,
};

let GlobalVue = null;
if (typeof window !== "undefined") {
  GlobalVue = window.Vue;
  // @ts-ignore
} else if (typeof global !== "undefined") {
  // @ts-ignore
  GlobalVue = global.Vue;
}
if (GlobalVue) {
  GlobalVue.use(plugin);
}

export {
  FlowBuilder,
  FlowBuilderStep,
  FlowBuilderBlock,
  FlowBuilderNewStep,
  FlowBuilderDragHandle,
};

export default plugin;
