import HttpService from "../http";

export default class CrudService extends HttpService {
  public async genericMethod(payload): Promise<any> {
    const config = {
      ...payload.config,
    };

    if (payload.data) {
      // @ts-ignore
      config.data = payload.data;
    }

    return this.httpCall<any>({
      config,
      success: payload.success,
      enableLoading: payload.enableLoading,
      enableError: payload.enableError,
      commit: payload.commit,
    });
  }

  public async getResource(payload): Promise<any> {
    return this.httpCall<any>({
      config: {
        method: "GET",
        url: `/${payload.resourceName}`,
      },
      success: "",
      enableLoading: false,
      enableError: false,
      commit: `${payload.commit}`,
    });
  }

  public async createResource(route, resourceValue, payload): Promise<any> {
    return this.httpCall<any>({
      config: {
        method: "POST",
        url: `/${route}`,
        data: payload.resource,
      },
      success: resourceValue ? `${resourceValue} created` : "",
      enableLoading: true,
      enableError: true,
      commit: "",
    });
  }

  public async upsertResource(payload): Promise<any> {
    return this.httpCall<any>({
      config: {
        method: "POST",
        url: `/${payload.resourceName}:upsert`,
        data: payload.resource,
      },
      success: "",
      enableLoading: false,
      enableError: false,
      commit: "",
    });
  }

  public async updateResource(resourceValue, payload): Promise<any> {
    const updateMask = Object.keys(payload.resource);
    return this.httpCall<any>({
      config: {
        method: "PATCH",
        url: `/${payload.resourceName}?update_mask=${
          payload.updateMask ? payload.updateMask : updateMask.join(",")
        }`,
        data: payload.resource,
      },
      success: "",
      enableLoading: true,
      enableError: true,
      commit: "contactLists/setResource",
    });
  }

  public async deleteResource(resourceValue, payload): Promise<any> {
    return this.httpCall<any>({
      config: {
        method: "DELETE",
        url: `/${payload.resourceName}`,
      },
      success: resourceValue ? `${resourceValue} deleted` : "Resource deleted",
      enableLoading: true,
      enableError: true,
      commit: "",
    });
  }

  public async listResource(route, payload): Promise<any> {
    let parameters = "";
    if (payload.queryString) {
      parameters = parameters + `${payload.queryString}`;
    }
    return this.httpCall<any>({
      config: {
        method: "GET",
        url: `${route}?page_size=100${parameters}`,
      },
      success: "",
      enableLoading: false,
      enableError: false,
      commit: `${payload.commit}`,
    });
  }
}
