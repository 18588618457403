import Vue from "vue";
// Base Components
import BaseCard from "@/common/display/BaseCard";
// @ts-ignore
import EnMetricCard from "@/components/EnMetricCard";
import EnPhoneNumber from "@/common/form/EnPhoneNumber";
import EnListItem from "@/common/display/EnListItem";
import EnTextField from "@/common/form/EnTextField";
import EnCard from "@/common/display/EnCard";
import EnTableFooter from "@/common/table/EnTableFooter";
import EnRow from "@/common/table/EnRow";
import EnButton from "@/common/actions/EnButton";
import EnInformationItem from "@/common/display/EnInformationItem";
import PhoneNumberDisplay from "@/common/display/PhoneNumberDisplay";
import ContactDisplay from "@/common/display/ContactDisplay";
import EnDatePicker from "@/common/form/EnDatePicker";
import NavHeader from "@/layouts/components/NavHeader";
import ConfirmModal from "@/components/ConfirmModal";
import VuePapaParse from "vue-papa-parse";
import ClickOutside from "vue-click-outside";
import VueApexCharts from "vue-apexcharts";
import EnNumberField from "@/components/EnNumberField";
import ErrorState from "@/components/ErrorState";
import VueViewer from "v-viewer";
Vue.config.productionTip = false;
import "viewerjs/dist/viewer.css";

export default function () {
  Vue.component("EnMetricCard", EnMetricCard);
  Vue.component("BaseCard", BaseCard);
  Vue.component("ErrorState", ErrorState);
  Vue.component("NavHeader", NavHeader);
  Vue.component("EnButton", EnButton);
  Vue.component("EnCard", EnCard);
  Vue.component("EnListItem", EnListItem);
  Vue.component("EnTextField", EnTextField);
  Vue.component("EnPhoneNumber", EnPhoneNumber);
  Vue.component("EnTableFooter", EnTableFooter);
  Vue.component("EnRow", EnRow);
  Vue.component("EnInformationItem", EnInformationItem);
  Vue.component("EnDatePicker", EnDatePicker);
  Vue.component("PhoneNumberDisplay", PhoneNumberDisplay);
  Vue.component("ContactDisplay", ContactDisplay);
  Vue.component("ConfirmModal", ConfirmModal);
  Vue.use(VuePapaParse);
  Vue.use(VueApexCharts);
  Vue.use(VueViewer);
  Vue.component("apexchart", VueApexCharts);
  Vue.component("EnNumberField", EnNumberField);
  Vue.directive("ClickOutside", ClickOutside);
}
