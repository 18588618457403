// Billing Routes
const BillingOverview = () => import("@/views/Billing/BillingOverview");
const BillingPayments = () => import("@/views/Billing/BillingPayments");
const BillingInvoices = () => import("@/views/Billing/BillingInvoices");
const BillingUsers = () => import("@/views/Billing/BillingUsers");

export default [
  {
    path: "/billingAccounts/:billingAccountId/overview",
    name: "Billing Overview",
    component: BillingOverview,
    meta: {
      pageTitle: "Billing overview",
      featureFlipping: {
        key: "billing.view",
        default: false,
      },
      headerImg: "./header-voice.png",
      layout: "LayoutBilling",
      tabs: "billing",
      dispatchAction: "billingAccounts/initializeBillingOverview",
    },
  },
  {
    path: "/billingAccounts/:billingAccountId/payments",
    name: "Billing Payments",
    component: BillingPayments,
    meta: {
      pageTitle: "Billing payments",
      featureFlipping: {
        key: "payments.list",
        default: false,
      },
      headerImg: "./header-voice.png",
      layout: "LayoutBilling",
      tabs: "billing",
      dispatchAction: "payments/getPayments",
    },
  },
  {
    path: "/billingAccounts/:billingAccountId/invoices",
    name: "Billing Invoices",
    component: BillingInvoices,
    meta: {
      pageTitle: "Billing invoices",
      featureFlipping: {
        key: "invoices.list",
        default: false,
      },
      headerImg: "./header-voice.png",
      layout: "LayoutBilling",
      tabs: "billing",
      dispatchAction: "invoices/getInvoices",
    },
  },
  {
    path: "/billingAccounts/:billingAccountId/users",
    name: "Billing Users",
    component: BillingUsers,
    meta: {
      pageTitle: "Billing users",
      featureFlipping: {
        key: "billing.users",
        default: false,
      },
      headerImg: "./header-voice.png",
      layout: "LayoutBilling",
      tabs: "billing",
      dispatchAction: "billingAccounts/getBillingIam",
    },
  },
];
