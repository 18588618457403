export default {
  resetSubscriptions(state, payload) {
    state.subscriptions = {
      data: [],
      nextPageToken: "",
    };
  },
  resetSubscription(state, payload) {
    state.subscription = {};
  },
  setSubscriptions(state, payload) {
    state.subscriptions = {
      data: payload.subscriptions,
      nextPageToken: payload.nextPageToken,
    };
  },
};
