export default {
  setMediaList(state, payload) {
    state.mediaList = {
      data: payload,
    };
  },
  resetMedia(state, payload) {
    state.mediaList = {
      data: [],
      nextPageToken: "",
    };
  },
};
