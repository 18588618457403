import Vue from "vue";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginVue from "@bugsnag/plugin-vue";
import * as configuration from "@/consts/configuration";

export default function () {
  Bugsnag.start({
    enabledErrorTypes: {
      unhandledExceptions: true,
      unhandledRejections: true,
    },
    apiKey: configuration.BUGSNAG_KEY,
    plugins: [new BugsnagPluginVue()],
    releaseStage: "prod",
  });

  Bugsnag.getPlugin("vue").installVueErrorHandler(Vue);
}
