var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"fade-in",class:{
    'step-active': _setup.selectedStep.id === _vm.step.id || _vm.step.highlight,
  },style:(`width: 160px`)},[_c('div',{staticClass:"entry-node"},[_c('v-icon',{attrs:{"color":"white","small":""}},[_vm._v(" $vuetify.icons.call ")])],1),_c('div',{staticClass:"step-container",style:({
      width: `${_vm.width}px`,
      margin: `auto`,
      position: `relative`,
    })},[_c('div',{staticClass:"pa-1 noselect text-center"},[_c('div',[_c('h4',{staticStyle:{"margin-bottom":"-5px"}},[_vm._v(" "+_vm._s(_vm.step.data.configuration.displayName)+" ")]),_c('span',{staticClass:"caption"},[_vm._v("When a call is received")])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }