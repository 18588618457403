export default {
  nodeForm: false,
  flow: {},
  flowVersion: {},
  flows: [],
  selectedStep: {},
  versions: [],
  ttsVoice: "",
  voices: [],
};
