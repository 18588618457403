// Tenant admin views
const TenantsHome = () => import("@/views/Tenants/TenantsHome");
const TenantsPhoneNumbers = () => import("@/views/Tenants/TenantsPhoneNumbers");
const TenantsProjects = () => import("@/views/Tenants/TenantsProjects");
const TenantsUsers = () => import("@/views/Tenants/TenantsUsers");
const TenantsBillingAccounts = () => import("@/views/Tenants/TenantsBillingAccounts");
const TenantsPortInRequestsList = () => import("@/views/Tenants/TenantsPortInRequestsList");

export default [
  {
    path: "/tenants/home",
    name: "TenantsHome",
    component: TenantsHome,
    meta: {
      pageTitle: "Home",
      featureFlipping: {
        key: "tenants.home",
        default: false,
      },
      layout: "LayoutTenant",
      tabs: "home",
    },
  },
  {
    path: "/tenants/numbers",
    name: "TenantsNumbers",
    component: TenantsPhoneNumbers,
    meta: {
      pageTitle: "Phone numbers list",
      featureFlipping: {
        key: "tenants.numbers.list",
        default: false,
      },
      headerImg: "./header-analytics.png",
      layout: "LayoutTenant",
      tabs: "tenantNumbers",
    },
  },
  {
    path: "/tenants/users",
    name: "TenantUsers",
    component: TenantsUsers,
    meta: {
      featureFlipping: {
        key: "tenants.users.list",
        default: false,
      },
      headerImg: "./header-analytics.png",
      layout: "LayoutTenant",
      tabs: "tenantUsers",
    },
  },
  {
    path: "/tenants/projects",
    name: "TenantsProjects",
    component: TenantsProjects,
    meta: {
      pageTitle: "Projects",
      featureFlipping: {
        key: "tenants.projects.list",
        default: false,
      },
      headerImg: "./header-analytics.png",
      layout: "LayoutTenant",
      tabs: "tenantProjects",
    },
  },
  {
    path: "/tenants/billingAccounts",
    name: "TenantsBillingAccounts",
    component: TenantsBillingAccounts,
    meta: {
      pageTitle: "Billing accounts",
      featureFlipping: {
        key: "tenants.billingAccounts.list",
        default: false,
      },
      headerImg: "./header-analytics.png",
      layout: "LayoutTenant",
      tabs: "tenantProjects",
      dispatchAction: "billingAccounts/getBillingAccounts",
    },
  },
  {
    path: "/tenants/portInRequests/list",
    name: "TenantsPortInRequestsList",
    component: TenantsPortInRequestsList,
    meta: {
      pageTitle: "Port in requests list",
      featureFlipping: {
        key: "tenants.portInRequests.list",
        default: false,
      },
      headerImg: "./header-analytics.png",
      layout: "LayoutTenant",
      tabs: "tenantPortInRequests",
      dispatchAction: "portInRequests/tenantsListAll",
    },
  },
];
