export default {
  resetState(state, payload) {
    state.list = [];
    state.nextPageToken = "";
  },
  setInvoices(state, payload) {
    state.list = payload.invoices;
    state.nextPageToken = payload.nextPageToken;
  },
};
