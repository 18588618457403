import { BillingApiUrl } from "@/consts/configuration";
import { CrudService } from "@/api";
const paymentMethodsApi = new CrudService(BillingApiUrl);

export default {
  async getPaymentMethods({ commit, state }, payload) {
    return paymentMethodsApi.listResource(
      `billingAccounts/${payload.billingAccountId}/paymentMethods`,
      {
        queryString: ``,
        commit: "paymentMethods/setPaymentMethods",
      }
    );
  },
  async createPaymentMethod({ commit, state }, payload) {
    return paymentMethodsApi.createResource(
      `billingAccounts/${payload.billingAccountId}/paymentMethods`,
      "Payment Method",
      {
        resource: {
          gateway: "gateways/stripe",
          externalId: payload.paymentMethodId,
        },
      }
    );
  },
  async updatePaymentMethod({ commit, state }, payload) {
    return paymentMethodsApi.updateResource("Payment Method", {
      resourceName: `${payload.paymentMethod.name}`,
      resource: {
        precedence: payload.precedence,
      },
    });
  },
  async deletePaymentMethod({ commit, state }, payload) {
    return paymentMethodsApi.deleteResource("Payment Method", {
      resourceName: `${payload.paymentMethodName}`,
    });
  },
};
