export default {
  setLoading(state, payload) {
    state.loading = payload;
  },
  setDispatchActionError(state, payload) {
    state.dispatchActionError = payload;
  },
  setAbortController(state, payload) {
    state.abortController = payload;
  },
  setError(state, payload) {
    state.error = payload;
  },
  setInfo(state, payload) {
    state.info = payload;
  },
  clearInfo(state) {
    state.info = null;
  },
  clearError(state) {
    state.error = null;
  },
  setSuccess(state, payload) {
    state.success = payload;
  },
  clearSuccess(state) {
    state.success = null;
  },
  setHasUnsavedChanges(state, payload) {
    state.hasUnsavedChanges = payload;
  },
  setHistoryCount(state, payload) {
    state.historyCount = payload;
  },
};
