import moment from "moment";

const today: any = new Date();
const daySec = 86400000;
function xDaysAgo(days) {
  return new Date(today - days * daySec);
}
function currentDay() {
  return [moment(today).startOf("day").toISOString(), moment(today).endOf("day").toISOString()];
}

function dateRange(daysAgo) {
  return [
    moment(xDaysAgo(daysAgo)).startOf("day").toISOString(),
    moment().endOf("day").toISOString(),
  ];
}

function currentMonth() {
  return [
    moment().subtract(1, "month").startOf("day").toISOString(),
    moment().endOf("day").toISOString(),
  ];
}

export default {
  dateRange: dateRange(28),
  today: currentDay(),
  week: dateRange(6),
  month: currentMonth(),
  year: dateRange(364),
};
