// @ts-ignore
import { Draggable } from "enfonica-draggable";
import Dropzone from "./components/Dropzone";
import DraggableComponent from "./components/Draggable";
delete Draggable.Plugins.Focusable;

const VuePlugin = {};
// @ts-ignore
VuePlugin.install = function install(_Vue, options = {}) {
  const Vue = _Vue;
  const draggableClass = "draggable-item";
  const dragHandleClass = "drag-handle";
  const ignoreHandleClassList = ["drag-ignore-handle", "scrollable"];

  const defaultOpts = {
    draggableClass,
    dragHandleClass,
    delay: 0,
    distance: 0,
    thresholdDistance: 2,
    draggable: `.${draggableClass}`,
    handle: `.${dragHandleClass}`,
    ignoreHandleClassList,
    mirror: {
      constrainDimensions: true,
      appendTo: "body",
    },
    appendTo: "body",
    scrollable: {
      speed: 20,
      sensitivity: 80,
    },
    // plugins: [Draggable.Plugins.ResizeMirror]
  };

  Vue.prototype.$draggable = new Draggable([], {
    ...defaultOpts,
    ...options,
  });

  Vue.prototype.$draggable.removePlugin(Draggable.Plugins.Focusable);

  Vue.directive("DragHandle", {
    inserted(el) {
      if (el.getAttribute("drag-disabled") !== "true") {
        el.classList.add(dragHandleClass);
      }
    },
    update(el) {
      if (el.getAttribute("drag-disabled") !== "true") {
        el.classList.add(dragHandleClass);
      }
    },
  });
  Vue.component("Dropzone", Dropzone);
  Vue.component("Draggable", DraggableComponent);

  Vue.mixin({
    mounted() {
      if (!this.$parent) {
        this.$draggable.addContainer(this.$el);
      }
    },
  });
};

export default VuePlugin;
