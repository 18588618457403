import { ref, toRefs, reactive, computed, defineComponent, getCurrentInstance } from "vue";
import _ from "lodash";

export default function stepComposition(store, props): any {
  const state = reactive({
    active: false,
    displayNameEdit: false,
  });

  const selectedStep = computed(() => store.state.flows.selectedStep);

  function openStepForm(step) {
    state.active = !state.active;
    store.dispatch("flows/handleStepForm");
    store.dispatch("flows/handleSelectedStep", {});
    setTimeout(() => {
      store.dispatch("flows/handleSelectedStep", _.cloneDeep(step));
    }, 1000);
  }

  function handleUpdateStep(step) {
    store.dispatch("flows/handleUpdatedStep", step);
  }

  function handleDeleteStep() {
    store.state.flows.selectedStep.delete = true;

    function findChildren(val) {
      val.delete = true;
      const children = _.filter(props.steps, { parentId: val.id });
      if (children.length === 0) {
        return;
      } else {
        children.forEach((element) => {
          findChildren(element);
        });
      }
    }

    findChildren(store.state.flows.selectedStep);
    // @ts-ignore
    props.deleteStep(selectedStep);
  }

  function handleDeleteSingleStep() {
    // TODO Check for grandchildren if its children are exit steps

    const ordered = _.orderBy(props.steps, ["data.title"], ["asc"]);
    const mainChildren = _.filter(ordered, {
      parentId: store.state.flows.selectedStep.id,
    });
    store.state.flows.selectedStep.delete = true;

    function findChildren(val) {
      val.delete = true;
      const children = _.filter(ordered, { parentId: val.id });
      if (children.length === 0) {
        return;
      } else {
        children.forEach((element, index) => {
          findChildren(element);
        });
      }
      return;
    }

    if (mainChildren.length !== 0 && mainChildren.length !== 1) {
      if (mainChildren[0].stepComponent === "exit-step") {
        const grandChild = _.filter(ordered, { parentId: mainChildren[0].id });
        if (grandChild.length !== 1) {
          mainChildren.forEach((element) => {
            findChildren(element);
          });
          // @ts-ignore
          props.deleteSingleStep(selectedStep);
          return;
        }
      }
      mainChildren.slice(1).forEach((element) => {
        findChildren(element);
      });
    }
    // @ts-ignore
    props.deleteSingleStep(selectedStep);
  }

  return {
    ...toRefs(state),
    selectedStep,
    openStepForm,
    handleDeleteStep,
    handleUpdateStep,
    handleDeleteSingleStep,
  };
}
