import { CallerreputationApiUrl } from "@/consts/configuration";
import _ from "lodash";
import { CrudService } from "@/api";
const subscriptionsApi = new CrudService(CallerreputationApiUrl);

export default {
  async getSubscriptions({ commit, state }, to) {
    return subscriptionsApi.listResource(`projects/${to.params.projectId}/subscriptions`, {
      queryString: ``,
      commit: "subscriptions/setSubscriptions",
    });
  },
  async createSubscriptions({ commit, state }, payload) {
    return subscriptionsApi.createResource(
      `projects/${payload.projectId}/subscriptions`,
      "Subscription",
      {
        resource: {
          displayName: payload.displayName,
          recipients: payload.recipients,
          types: payload.types,
        },
      }
    );
  },
  async updateSubscriptions({ commit, state }, payload) {
    return subscriptionsApi.updateResource("Subscription", {
      resourceName: `projects/${payload.projectId}/subscriptions/${payload.subscriptionsId}`,
      resource: {
        displayName: payload.displayName,
        recipients: payload.recipients,
        types: payload.types,
      },
    });
  },
  async deleteSubscriptions({ commit, state }, payload) {
    return subscriptionsApi.deleteResource("Subscription", {
      resourceName: `projects/${payload.projectId}/subscriptions/${payload.subscriptionsId}`,
    });
  },
};
