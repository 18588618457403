import { httpClient } from "@/helpers/httpClient";
import { IamApiUrl, ResourceManagerApiUrl } from "@/consts/configuration";

export default {
  async getIamPolicy({ commit, state }, payload) {
    try {
      const data = await httpClient.post(
        `${ResourceManagerApiUrl}/projects/${payload.projectId}:getIamPolicy`
      );
      const iamPolicy = data.data;
      commit("setIamPolicy", iamPolicy);
      return iamPolicy;
    } catch (error) {
      throw error;
    }
  },
  async setIamPolicy({ commit, state }, payload) {
    commit("common/setLoading", true, { root: true });
    try {
      const data = await httpClient.post(
        `${ResourceManagerApiUrl}/projects/${payload.projectId}:setIamPolicy`,
        { policy: { bindings: payload.body, etag: payload.etag } }
      );
      const iamPolicy = data.data;
      commit("common/setLoading", false, { root: true });
      commit("setIamPolicy", iamPolicy);
      return iamPolicy;
    } catch (error) {
      commit("common/setLoading", false, { root: true });
      commit("common/setError", error, { root: true });
      throw error;
    }
  },
  revertIamPolicy({ commit, state }, payload) {
    commit("setIamPolicy", payload);
  },
  async initializeUsersView({ commit, state, dispatch }, to) {
    try {
      const payload = {
        projectId: to.params.projectId,
      };

      const iamPolicy = await dispatch("iam/getIamPolicy", payload, {
        root: true,
      });

      return { iamPolicy };
    } catch (err) {
      throw err;
    }
  },
  async initializeServiceAccountsView({ commit, state, dispatch }, to) {
    try {
      const payload = {
        projectId: to.params.projectId,
      };

      const serviceAccounts = await dispatch("iam/getServiceAccounts", payload, {
        root: true,
      });

      return { serviceAccounts };
    } catch (err) {
      throw err;
    }
  },
  async getServiceAccounts({ commit, state }, payload) {
    let parameters = "";
    if (payload.nextPageToken) {
      parameters = parameters + `&page_token=${payload.nextPageToken}`;
    }
    try {
      const data = await httpClient.get(
        `${IamApiUrl}/projects/${payload.projectId}/serviceAccounts?page_size=100${parameters}`
      );
      commit("setServiceAccounts", data.data);
    } catch (error) {
      commit("common/setError", error, { root: true });
      throw error;
    }
  },
  async getServiceAccount({ commit, dispatch }, to) {
    const projectId = to.params.projectId;
    const serviceAccountId = to.params.serviceAccountId;

    try {
      const data = await httpClient.get(
        `${IamApiUrl}/projects/${projectId}/serviceAccounts/${serviceAccountId}`
      );
      await dispatch(
        "iam/getServiceAccountKeys",
        {
          projectId,
          serviceAccountId,
        },
        { root: true }
      );
      await dispatch(
        "iam/getIamPolicy",
        {
          projectId,
        },
        { root: true }
      );
      const serviceAccount = data.data;
      commit("setServiceAccount", serviceAccount);
      return serviceAccount;
    } catch (error) {
      throw error;
    }
  },
  async createServiceAccount({ commit, state }, payload) {
    commit("common/setLoading", true, { root: true });
    try {
      const data = await httpClient.post(
        `${IamApiUrl}/projects/${payload.projectId}/serviceAccounts?service_account_id=${payload.serviceAccountId}`,
        payload.serviceAccount
      );
      const serviceAccount = data.data;
      commit("common/setLoading", false, { root: true });
      return serviceAccount;
    } catch (error) {
      commit("common/setLoading", false, { root: true });
      commit("common/setError", error, { root: true });
      throw error;
    }
  },
  async updateServiceAccount({ commit, state }, payload) {
    commit("common/setLoading", true, { root: true });
    try {
      const data = await httpClient.patch(
        `${IamApiUrl}/projects/${payload.projectId}/serviceAccounts/${payload.serviceAccountId}`,
        payload.payload
      );
      const serviceAccount = data.data;
      commit("common/setLoading", false, { root: true });
      commit(
        "common/setSuccess",
        {
          data: "Update successful",
        },
        { root: true }
      );
      return serviceAccount;
    } catch (error) {
      commit("common/setLoading", false, { root: true });
      commit("common/setError", error, { root: true });
      throw error;
    }
  },
  async deleteServiceAccount({ commit, state }, payload) {
    commit("common/setLoading", true, { root: true });
    try {
      const data = await httpClient.delete(
        `${IamApiUrl}/projects/${payload.projectId}/serviceAccounts/${payload.serviceAccountId}`
      );
      commit("common/setLoading", false, { root: true });
      const serviceAccount = data.data;
      return serviceAccount;
    } catch (error) {
      commit("common/setLoading", false, { root: true });
      commit("common/setError", error, { root: true });
      throw error;
    }
  },
  async getServiceAccountKeys({ commit, state }, payload) {
    commit("common/setLoading", true, { root: true });
    try {
      let params;
      const allSubs = [];
      do {
        const res = await httpClient.get(
          `${IamApiUrl}/projects/${payload.projectId}/serviceAccounts/${
            payload.serviceAccountId
          }/keys?page_size=100${params ? `&page_token=${params}` : ""}`
        );
        allSubs.push(res.data.serviceAccountKeys);
        params = res.data.nextPageToken;
      } while (params !== "");
      commit("common/setLoading", false, { root: true });
      commit("setServiceAccountKeys", allSubs.flat());
      return allSubs.flat();
    } catch (error) {
      commit("common/setLoading", false, { root: true });
      commit("common/setError", error, { root: true });
      throw error;
    }
  },
  async getServiceAccountKey({ commit, state }, payload) {
    commit("common/setLoading", true, { root: true });
    try {
      const data = await httpClient.get(`${IamApiUrl}/${payload.serviceAccountKeyName}`);
      const serviceAccountKey = data.data;
      commit("common/setLoading", false, { root: true });
      return serviceAccountKey;
    } catch (error) {
      commit("common/setLoading", false, { root: true });
      commit("common/setError", error, { root: true });
      throw error;
    }
  },
  async createServiceAccountKey({ commit, state }, payload) {
    try {
      const data = await httpClient.post(
        `${IamApiUrl}/projects/${payload.projectId}/serviceAccounts/${payload.serviceAccountId}/keys`
      );
      const serviceAccountKey = data.data;
      return serviceAccountKey;
    } catch (error) {
      commit("common/setError", error, { root: true });
      throw error;
    }
  },
  async deleteServiceAccountKey({ commit, state }, payload) {
    try {
      const data = await httpClient.delete(`${IamApiUrl}/${payload.serviceAccountKeyName}`);
      const serviceAccountKey = data.data;
      return serviceAccountKey;
    } catch (error) {
      commit("common/setError", error, { root: true });
      throw error;
    }
  },
};
