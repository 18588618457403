const ServiceAccountsList = () => import("@/views/ServiceAccounts/ServiceAccountsList");
const ServiceAccountsView = () => import("@/views/ServiceAccounts/ServiceAccountsView");

export default [
  {
    path: "/projects/:projectId/service-accounts/list",
    name: "Settings Service Accounts List",
    component: ServiceAccountsList,
    meta: {
      pageTitle: "Service accounts list",
      featureFlipping: {
        key: "serviceAccounts.list",
        default: false,
      },
      headerImg: "./header-voice.png",
      layout: "LayoutMain",
      tabs: "settings",
      dispatchAction: "iam/initializeServiceAccountsView",
    },
  },
  {
    path: "/projects/:projectId/service-accounts/:serviceAccountId",
    name: "Settings Service Accounts Edit",
    component: ServiceAccountsView,
    meta: {
      pageTitle: "View service account",
      featureFlipping: {
        key: "serviceAccounts.view",
        default: false,
      },
      headerImg: "./header-voice.png",
      tabs: "serviceAccountEdit",
      back: true,
      backLink: "/service-accounts/list",
      layout: "LayoutMain",
      dispatchAction: "iam/getServiceAccount",
    },
  },
];
