import { VoiceApiUrl } from "@/consts/configuration";
import _ from "lodash";
import { CrudService } from "@/api";
import { httpClient } from "@/helpers/httpClient";
const aliasesApi = new CrudService(VoiceApiUrl);

export default {
  resetState({ commit, state }, payload) {
    commit("resetState", "");
  },
  async initializeAliasesView({ commit, state, dispatch }, to) {
    try {
      const payload = {
        projectId: to.params.projectId,
        sipDomainId: to.params.sipDomainId,
      };
      const sipDomain = await dispatch("sipDomains/getSipDomain", to, {
        root: true,
      });

      const aliases = await dispatch("aliases/getAll", payload, { root: true });
      return {
        sipDomain,
        aliases,
      };
    } catch (err) {
      throw err;
    }
  },
  async getAllAliases({ commit, state }, payload) {
    try {
      let params;
      const allSubs = [];
      do {
        const res = await httpClient.get(
          `${VoiceApiUrl}/projects/${payload.projectId}/sipDomains/-/aliases?page_size=100${
            params ? `&page_token=${params}` : ""
          }`
        );
        allSubs.push(res.data.aliases);
        params = res.data.nextPageToken;
      } while (params !== "");
      commit("setAll", allSubs.flat());
      return allSubs.flat();
    } catch (err) {
      throw err;
    }
  },
  async getAll({ commit, state }, payload) {
    try {
      let params;
      const allSubs = [];
      do {
        const res = await httpClient.get(
          `${VoiceApiUrl}/projects/${payload.projectId}/sipDomains/${
            payload.sipDomainId
          }/aliases?page_size=100${params ? `&page_token=${params}` : ""}`
        );
        allSubs.push(res.data.aliases);
        params = res.data.nextPageToken;
      } while (params !== "");
      commit("setAllSipDomainAliases", allSubs.flat());
      return allSubs.flat();
    } catch (err) {
      throw err;
    }
  },
  updateSipDomainAliases({ commit, state }, payload) {
    commit("setAllSipDomainAliases", payload);
  },
  addAlias({ commit, state }, payload) {
    commit("addSipDomainAlias", payload);
  },
  async createResource({ commit, state }, payload) {
    return aliasesApi.createResource(
      `projects/${payload.projectId}/sipDomains/${payload.sipDomainId}/aliases?aliasId=${payload.alias}`,
      "Load balancer",
      payload
    );
  },
  async getResource({ commit, state }, to) {
    return aliasesApi.getResource({
      resourceName: `projects/${to.params.projectId}/sipDomains/${to.params.sipDomainId}/aliases/${to.params.alias}`,
      commit: "aliases/setResource",
    });
  },
  async updateResource({ commit, state }, payload) {
    return aliasesApi.updateResource("Load balancer", {
      resourceName: payload.resourceName,
      resource: payload.resource,
    });
  },
  async deleteAlias({ commit, state }, payload) {
    return aliasesApi.deleteResource("aliases", {
      resourceName: payload.resourceName,
    });
  },
};
