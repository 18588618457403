import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import store from "@/store";

export default class HttpService {
  private httpClient: AxiosInstance;
  constructor(apiUrl) {
    this.httpClient = axios.create({
      baseURL: `${apiUrl}/`,
    });
  }

  public async httpCall<T>(payload: {
    config: AxiosRequestConfig;
    success: string;
    enableLoading: boolean;
    enableError: boolean;
    commit: string;
  }) {
    if (payload.enableLoading) {
      store.commit("common/setLoading", true, { root: true });
    }
    try {
      const token = await store.dispatch("user/getToken");
      const { data } = await this.httpClient.request<T>({
        headers: {
          authorization: `Bearer ${token}`,
        },
        ...payload.config,
      });
      if (payload.enableLoading) {
        store.commit("common/setLoading", false, { root: true });
      }
      if (payload.success) {
        store.commit(
          "common/setSuccess",
          {
            data: payload.success,
          },
          { root: true }
        );
      }
      if (payload.commit) {
        store.commit(payload.commit, data);
      }
      return data;
    } catch (error) {
      if (payload.enableLoading) {
        store.commit("common/setLoading", false, { root: true });
      }
      if (payload.enableError) {
        if (error.response) {
          if (error.response.headers["grpc-message"] && error.response.headers["grpc-status"]) {
            store.commit(
              "common/setError",
              {
                error,
                code: error.response.headers["grpc-status"],
                message: error.response.headers["grpc-message"],
                details: [],
              },
              { root: true }
            );

            throw error;
          } else {
            store.commit(
              "common/setError",
              {
                error,
                ...error.response.data,
              },
              { root: true }
            );

            throw error;
          }
        }
        store.commit("common/setError", error, { root: true });
      }
      throw error;
    }
  }
}
