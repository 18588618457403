import { MessagingApiUrl } from "@/consts/configuration";
import _ from "lodash";
import moment from "moment";
import { httpClient } from "@/helpers/httpClient";
import apiFilter from "@/helpers/apiFilter";
import { CrudService } from "@/api";
import defaultDate from "@/helpers/defaultDate";
const messagesApi = new CrudService(MessagingApiUrl);

export default {
  async initializeSMSOverview({ commit }, to) {
    if (!to.query.date) {
      return;
    }
    const filtersVal = to.query.date ? JSON.parse(decodeURIComponent(to.query.date)) : "month";

    const today: any = new Date();
    let todayDateRange = [];

    if (filtersVal === "today") {
      todayDateRange = [
        moment(today).startOf("day").toISOString(),
        moment(today).add(1, "day").startOf("day").toISOString(),
      ];
    }
    const payload = {
      date: !Array.isArray(filtersVal)
        ? filtersVal === "today"
          ? todayDateRange
          : defaultDate[filtersVal]
        : filtersVal,
    };

    const url = `${MessagingApiUrl}/projects/${to.params.projectId}/messages:generateOverviewReport`;
    try {
      const res = await httpClient.post(url, {
        startDate: {
          year: moment(new Date(payload.date[0]).toISOString()).format("YYYY"),
          month: moment(new Date(payload.date[0]).toISOString()).format("MM"),
          day: moment(new Date(payload.date[0]).toISOString()).format("DD"),
        },
        endDate: {
          year: moment(new Date(payload.date[1]).toISOString()).format("YYYY"),
          month: moment(new Date(payload.date[1]).toISOString()).format("MM"),
          day: moment(new Date(payload.date[1]).toISOString()).format("DD"),
        },
      });
      commit("messages/setOverview", res.data, { root: true });
      return res.data;
    } catch (error) {
      throw error;
    }
  },
  resetMessages({ commit, state }, payload) {
    commit("resetMessages", "");
  },
  resetMessage({ commit, state }, payload) {
    commit("resetMessage", "");
  },
  async getMessages({ commit, state, dispatch }, to) {
    if (!to.query.filters) {
      return;
    }

    if (!to.dontReset) {
      dispatch("messages/resetMessages", "", { root: true });
    }

    const nextPageToken = state.messages.nextPageToken;
    const projectId = to.params.projectId;
    const filtersVal = to.query.filters ? JSON.parse(decodeURIComponent(to.query.filters)) : [];
    let payload = {};
    const mainPayload = {
      projectId,
      date: filtersVal.filter((el) => el.field === "date")[0]
        ? filtersVal.filter((el) => el.field === "date")[0]
        : null,
      fromFilter: filtersVal.filter((el) => el.field === "from")[0],
      toFilter: filtersVal.filter((el) => el.field === "to")[0],
      stateFilter: filtersVal.filter((el) => el.field === "state")[0],
      directionFilter: filtersVal.filter((el) => el.field === "direction")[0],
      encodingFilter: filtersVal.filter((el) => el.field === "encoding")[0],
    };
    if (nextPageToken) {
      payload = {
        nextPageToken,
        ...mainPayload,
      };
    } else {
      payload = {
        projectId,
        ...mainPayload,
      };
    }

    const parameters = apiFilter(payload, "messaging");
    return messagesApi.listResource(`projects/${projectId}/messages`, {
      queryString: `&filter=${parameters}`,
      commit: "messages/setMessages",
    });
  },
  async exportMessages({ commit, state }, to) {
    const filtersVal = to.query.filters ? JSON.parse(decodeURIComponent(to.query.filters)) : [];

    const mainPayload = {
      date: filtersVal.filter((el) => el.field === "date")[0]
        ? filtersVal.filter((el) => el.field === "date")[0]
        : null,
      fromFilter: filtersVal.filter((el) => el.field === "from")[0],
      toFilter: filtersVal.filter((el) => el.field === "to")[0],
      stateFilter: filtersVal.filter((el) => el.field === "state")[0],
      directionFilter: filtersVal.filter((el) => el.field === "direction")[0],
      encodingFilter: filtersVal.filter((el) => el.field === "encoding")[0],
    };

    const parameters = apiFilter(mainPayload, "messaging");
    try {
      let params;
      const allSubs = [];
      do {
        const res = await httpClient.get(
          `${MessagingApiUrl}/projects/${
            to.params.projectId
          }/messages/?page_size=100&filter=${parameters}${params ? `&page_token=${params}` : ""}`
        );
        allSubs.push(res.data.messages);
        params = res.data.nextPageToken;
      } while (params !== "");
      commit("common/setLoading", false, { root: true });
      return allSubs.flat();
    } catch (error) {
      commit("common/setLoading", false, { root: true });
      commit("common/setError", error, { root: true });
      throw error;
    }
  },
  async getMessage({ commit, state }, to) {
    return messagesApi.getResource({
      resourceName: `projects/${to.params.projectId}/messages/${to.params.messageId}`,
      commit: "messages/setMessage",
    });
  },
};
