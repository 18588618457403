export default {
  resetState(state, payload) {
    state.list = {
      data: [],
      nextPageToken: "",
    };
    state.all = [];
    state.resource = null;
  },
  setAll(state, payload) {
    state.all = payload;
  },
  setAllSipDomainAliases(state, payload) {
    state.allSipDomainAliases = payload;
  },
  addSipDomainAlias(state, payload) {
    state.allSipDomainAliases.push(payload);
  },
  setList(state, payload) {
    state.list = {
      data: state.list.data.concat(payload),
      nextPageToken: payload.nextPageToken,
    };
  },
  setResource(state, payload) {
    state.resource = payload;
  },
};
