export default {
  resetState(state, payload) {
    state.list.data = [];
    state.list.nextPageToken = "";
    state.list.page = 1;
    state.list.itemsPerPage = 20;
  },
  setList(state, payload) {
    state.list.data = payload.phoneNumbers;
    state.list.nextPageToken = payload.nextPageToken;
    state.list.totalSize = payload.totalSize;
  },
};
