export default {
  sipDomains: {
    data: [],
    nextPageToken: "",
  },
  sipDomain: {
    name: "",
    sipDomainId: "",
  },
  overview: null,
};
