export default {
  overview: null,
  message: {
    price: {},
    recordings: [],
  },
  messages: {
    data: [],
    nextPageToken: "",
  },
  messagesNextPageToken: "",
  messagesPreviousTokens: [],
};
