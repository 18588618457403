import { httpClient } from "@/helpers/httpClient";
import { CallerreputationApiUrl } from "@/consts/configuration";
import _ from "lodash";
import { CrudService } from "@/api";
const checksApi = new CrudService(CallerreputationApiUrl);

export default {
  async exportChecks({ commit, state }, payload) {
    try {
      let params;
      const allSubs = [];
      do {
        const res = await httpClient.get(
          `${CallerreputationApiUrl}/projects/${payload.projectId}/${
            payload.cliId ? `clis/${payload.cliId}/` : ""
          }checks?page_size=100${params ? `&page_token=${params}` : ""}`
        );
        allSubs.push(res.data.checks);
        params = res.data.nextPageToken;
      } while (params !== "");
      return allSubs.flat();
    } catch (error) {
      commit("common/setError", error, { root: true });
      throw error;
    }
  },
  async getChecks({ commit, state, dispatch }, to) {
    if (!to.dontReset) {
      commit("checks/resetChecks", "", { root: true });
    }
    let parameters = "";
    const nextPageToken = state.checks.nextPageToken;
    if (nextPageToken) {
      parameters = parameters + `&page_token=${nextPageToken}`;
    }
    if (to.params.cliId) {
      dispatch("clis/getCli", to, { root: true });
    }
    return checksApi.listResource(
      `/projects/${to.params.projectId}/${
        to.params.cliId ? `clis/${to.params.cliId}/checks` : "checks"
      }`,
      {
        queryString: parameters,
        commit: "checks/setChecks",
      }
    );
  },
};
