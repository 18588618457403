// Flow Routes
const FlowsList = () => import("@/views/Flows/FlowsList");
const FlowsEdit = () => import("@/views/Flows/FlowsEdit");

export default [
  {
    path: "/projects/:projectId/flow/list",
    name: "Flow",
    component: FlowsList,
    meta: {
      pageTitle: "Flow list",
      featureFlipping: {
        key: "flows.list",
        default: false,
      },
      headerImg: "./header-flow.png",
      layout: "LayoutMain",
      tabs: "flow",
      dispatchAction: "flows/initializeFlowListView",
    },
  },
  {
    path: "/projects/:projectId/flow/:flowId",
    name: "Flow Edit",
    component: FlowsEdit,
    meta: {
      pageTitle: "View flow",
      featureFlipping: {
        key: "flows.view",
        default: false,
      },
      headerImg: "./header-flow.png",
      layout: "LayoutEmpty",
      tabs: "flow",
    },
  },
];
