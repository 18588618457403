import moment from "moment";
import _ from "lodash";

export default function (payload, type) {
  let parameters = "";

  if (payload.date) {
    const formattedEndTime = new Date(
      payload.date.value.length === 1 ? payload.date.value[0] : payload.date.value[1]
    );
    formattedEndTime.setDate(formattedEndTime.getDate() + 1);
    const startTime = moment(new Date(payload.date.value[0])).startOf("day").toISOString();
    const endTime = moment(formattedEndTime).startOf("day").toISOString();
    parameters = parameters + `createTime >= '${startTime}' AND createTime < '${endTime}'`;
  }
  const stateFilter = payload?.stateFilter?.value ? payload?.stateFilter?.value : [];
  const stateOperator = payload?.stateFilter?.operator;
  const directionFilter = payload?.directionFilter?.value;
  const directionOperator = payload?.directionFilter?.operator;
  const toFilter = payload?.toFilter?.value;
  const toOperator = payload?.toFilter?.operator;
  const fromFilter = payload?.fromFilter?.value;
  const fromOperator = payload?.fromFilter?.operator;
  const encodingFilter = payload?.encodingFilter?.value;
  const encodingOperator = payload?.encodingFilter?.operator;

  // This is needed as the messaging api uses the status field over state
  let state = "state";
  if (type === "messaging") {
    state = "status";
  }

  if (stateFilter.length === 1) {
    parameters =
      parameters + `${!parameters ? state : ` AND ${state}`} ${stateOperator} '${stateFilter[0]}'`;
  }
  if (stateFilter.length > 1) {
    let stateString = `${
      !parameters
        ? `${state} ${stateOperator} ${stateFilter[0]}`
        : ` AND ${state} ${stateOperator} ${stateFilter[0]}`
    }`;
    stateFilter.slice(1).forEach((el) => {
      stateString = stateString + ` OR ${state} ${stateOperator} ${el}`;
    });
    parameters = parameters + stateString;
  }
  if (directionFilter) {
    parameters =
      parameters +
      `${!parameters ? "direction" : " AND direction"} ${directionOperator} ${directionFilter}`;
  }
  if (encodingFilter) {
    parameters =
      parameters +
      `${!parameters ? "encoding" : " AND encoding"} ${encodingOperator} ${encodingFilter}`;
  }
  if (toFilter) {
    parameters = parameters + `${!parameters ? "to" : " AND to"} ${toOperator} '${toFilter}*'`;
  }
  if (fromFilter) {
    parameters =
      parameters + `${!parameters ? "from" : " AND from"} ${fromOperator} '${fromFilter}*'`;
  }

  if (payload.nextPageToken) {
    parameters = parameters + `&page_token=${payload.nextPageToken}`;
  }

  return parameters;
}
