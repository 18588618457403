import store from "@/store";
// Dashboard Routes
const Dashboard = () => import("@/views/Dashboard");

export default [
  {
    path: "/",
    redirect: (to, from) => {
      // the function receives the target route as the argument
      // return redirect path/location here.
      let search = "";
      if (location.search.includes("pi=")) {
        search = location.search.substring(1);
        const urlParams = new URLSearchParams(search);
        const params = Object.fromEntries(urlParams);
        localStorage.setItem("queryParameters", JSON.stringify(params));
        store.commit("user/setQueryParameters", params);
        location.href = location.origin + "/#/";
        // @ts-ignore
        if (store?.state?.user?.project?.name) {
          // @ts-ignore
          return `/${store?.state?.user?.project?.name}/home` + search;
        } else {
          return "/404";
        }
      }
      // @ts-ignore
      if (store?.state?.user?.project?.name) {
        // @ts-ignore
        return `/${store?.state?.user?.project?.name}/home`;
      } else {
        return "/404";
      }
    },
  },
  {
    path: "/projects/:projectId/home",
    name: "Dashboard",
    component: Dashboard,
    meta: {
      pageTitle: "Home",
      layout: "LayoutMain",
      tabs: "home",
    },
  },
];
