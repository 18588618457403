const PortInRequestsList = () => import("@/views/PortInRequests/PortInRequestsList");
const PortInRequestsCreate = () => import("@/views/PortInRequests/PortInRequestsCreate");
const PortInRequestsView = () => import("@/views/PortInRequests/PortInRequestsView");

export default [
  {
    path: "/projects/:projectId/portInRequests/list",
    name: "PortInRequestsList",
    component: PortInRequestsList,
    meta: {
      pageTitle: "Port in requests list",
      featureFlipping: {
        key: "portInRequests.list",
        default: false,
      },
      headerImg: "./header-voice.png",
      layout: "LayoutMain",
      tabs: "numbers",
      dispatchAction: "portInRequests/listResource",
    },
  },
  {
    path: "/projects/:projectId/portInRequests/create",
    name: "PortInRequestsCreate",
    component: PortInRequestsCreate,
    meta: {
      pageTitle: "Create port in request",
      featureFlipping: {
        key: "portInRequests.create",
        default: false,
      },
      headerImg: "./header-voice.png",
      layout: "LayoutMain",
      tabs: "PortInRequestsCreate",
      backLink: "/portInRequests/list",
      dispatchAction: "portInRequests/getResource",
    },
  },
  {
    path: "/projects/:projectId/portInRequests/:portInRequestId/overview",
    name: "PortInRequestsView",
    component: PortInRequestsView,
    meta: {
      pageTitle: "View port in request",
      featureFlipping: {
        key: "portInRequests.view",
        default: false,
      },
      headerImg: "./header-voice.png",
      layout: "LayoutMain",
      tabs: "PortInRequestsView",
      back: true,
      backLink: "/portInRequests/list",
      dispatchAction: "portInRequests/getResource",
    },
  },
];
