export default {
  billingPermissions: null,
  billingAccount: {},
  billingAccounts: [],
  allBillingAccounts: [],
  billingAccountInfo: {},
  billingAccountDetails: {},
  balance: {},
  autoTopUpSetting: {},
};
