import { stateOptions, directionOptions, encodingOptions } from "@/consts/messaging";

// Messaging Routes
const MessagesUnsubscribers = () => import("@/views/Messages/MessagesUnsubscribers");
const MessagesList = () => import("@/views/Messages/MessagesList");
const MessagesView = () => import("@/views/Messages/MessagesView");
const MessagesOverview = () => import("@/views/Messages/MessagesOverview");

export default [
  {
    path: "/projects/:projectId/sms/overview",
    name: "SMS Overview",
    component: MessagesOverview,
    meta: {
      pageTitle: "SMS overview",
      featureFlipping: {
        key: "messages.view",
        default: false,
      },
      headerImg: "./header-sms.png",
      layout: "LayoutMain",
      tabs: "sms",
      dispatchAction: "messages/initializeSMSOverview",
    },
  },
  {
    path: "/projects/:projectId/sms/list",
    name: "SMS List",
    component: MessagesList,
    meta: {
      pageTitle: "SMS list",
      featureFlipping: {
        key: "messages.list",
        default: false,
      },
      headerImg: "./header-sms.png",
      layout: "LayoutMain",
      tabs: "sms",
      filters: [
        {
          label: "Date",
          field: "date",
          type: "Date",
        },
        {
          label: "To",
          field: "to",
          type: "String",
        },
        {
          label: "From",
          field: "from",
          type: "String",
        },
        {
          label: "State",
          field: "state",
          type: "Multi",
          options: stateOptions,
        },
        {
          label: "Direction",
          field: "direction",
          type: "Enum",
          options: directionOptions,
        },
        {
          label: "Encoding",
          field: "encoding",
          type: "Enum",
          options: encodingOptions,
        },
      ],
      dispatchAction: "messages/getMessages",
    },
  },
  {
    path: "/projects/:projectId/sms/:messageId/overview",
    name: "MessageOverview",
    component: MessagesView,
    meta: {
      pageTitle: "View message",
      featureFlipping: {
        key: "messages.view",
        default: false,
      },
      headerImg: "./header-sms.png",
      layout: "LayoutMain",
      tabs: "messageDetails",
      back: true,
      backLink: "/sms/list",
      dispatchAction: "messages/getMessage",
      disableDispatchActionError: true,
    },
  },
  {
    path: "/projects/:projectId/sms/unsubscribers",
    name: "Unsubscribers",
    component: MessagesUnsubscribers,
    meta: {
      pageTitle: "Unsubscribers",
      featureFlipping: {
        key: "unsubscribers.list",
        default: false,
      },
      headerImg: "./header-sms.png",
      layout: "LayoutMain",
      tabs: "sms",
      dispatchAction: "unsubscribers/listResource",
    },
  },
];
