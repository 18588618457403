import { BillingApiUrl } from "@/consts/configuration";
import { httpClient } from "@/helpers/httpClient";
import { CrudService } from "@/api";
const invoicesApi = new CrudService(BillingApiUrl);

export default {
  async getInvoices({ commit, state }, to) {
    return invoicesApi.listResource(`billingAccounts/${to.params.billingAccountId}/invoices`, {
      commit: "invoices/setInvoices",
    });
  },
  async getInvoice({ commit, state }, payload) {
    try {
      const data = await httpClient.get(`${BillingApiUrl}/${payload.name}`);
      return data;
    } catch (error) {
      commit("common/setError", error, { root: true });
      throw error;
    }
  },
};
