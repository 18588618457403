export default {
  iamPolicy: { bindings: [] },
  serviceAccounts: {
    data: [],
    nextPageToken: "",
  },
  serviceAccount: {},
  serviceAccountKeys: {
    data: [],
    nextPageToken: "",
  },
  serviceAccountKey: {},
};
