export default {
  resource: {},
  chatMessages: [],
  duplicate: null,
  all: [],
  list: {
    data: [],
    nextPageToken: "",
    page: 1,
    itemsPerPage: 20,
  },
};
