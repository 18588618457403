import { BillingApiUrl } from "@/consts/configuration";
import { CrudService } from "@/api";
const paymentsApi = new CrudService(BillingApiUrl);

export default {
  async getPayments({ commit, state }, to) {
    return paymentsApi.listResource(`billingAccounts/${to.params.billingAccountId}/payments`, {
      commit: "payments/setPayments",
    });
  },
};
