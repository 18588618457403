const ContactsList = () => import("@/views/Contacts/ContactsList");
const ContactsView = () => import("@/views/Contacts/ContactsView");

export default [
  {
    path: "/projects/:projectId/contacts/list",
    name: "ContactsList",
    component: ContactsList,
    meta: {
      pageTitle: "Contacts list",
      featureFlipping: {
        key: "contacts.list",
        default: false,
      },
      headerImg: "./header-sms.png",
      layout: "LayoutMain",
      tabs: "smsMarketing",
      dispatchAction: "contacts/listResource",
    },
  },
  {
    path: "/projects/:projectId/contacts/:contactId",
    name: "ContactsView",
    component: ContactsView,
    meta: {
      pageTitle: "View contact",
      featureFlipping: {
        key: "contacts.view",
        default: false,
      },
      headerImg: "./header-sms.png",
      layout: "LayoutMain",
      tabs: "smsMarketing",
      back: true,
      backLink: "/contacts/list",
      dispatchAction: "contacts/getResource",
      disableDispatchActionError: true,
    },
  },
];
