export default {
  setEntities(state, payload) {
    state.entities = payload;
  },
  setDashboards(state, payload) {
    state.dashboards = payload;
  },
  setReports(state, payload) {
    state.reports = payload;
  },
  resetDashboards(state, payload) {
    state.dashboards = [];
  },
};
