export default {
  resetSipDomainState(state, payload) {
    state.sipDomains = {
      data: [],
      nextPageToken: "",
    };
    state.sipDomain = {
      name: "",
      sipDomainId: "",
    };
    state.users = {
      data: [],
      nextPageToken: "",
    };
    state.aliases = {
      data: [],
      nextPageToken: "",
    };
    state.alias = {};
  },
  resetSipDomains(state, payload) {
    state.sipDomains = {
      data: [],
      nextPageToken: "",
    };
  },
  setSipDomains(state, payload) {
    state.sipDomains = {
      data: payload.sipDomains,
      nextPageToken: payload.nextPageToken,
    };
  },
  setSipDomain(state, payload) {
    state.sipDomain = payload;
  },
  setOverview(state, payload) {
    state.overview = payload;
  },
};
