import { render, staticRenderFns } from "./FaxStep.vue?vue&type=template&id=2b8db8fa&"
import script from "./FaxStep.vue?vue&type=script&setup=true&lang=ts&"
export * from "./FaxStep.vue?vue&type=script&setup=true&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports