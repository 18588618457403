import { httpClient } from "@/helpers/httpClient";
import { CallerreputationApiUrl } from "@/consts/configuration";
import _ from "lodash";
import { CrudService } from "@/api";
const commentsApi = new CrudService(CallerreputationApiUrl);

export default {
  async exportComments({ commit, state }, payload) {
    try {
      let params;
      const allSubs = [];
      do {
        const res = await httpClient.get(
          `${CallerreputationApiUrl}/projects/${payload.projectId}/${
            payload.cliId ? `clis/${payload.cliId}/` : ""
          }comments?page_size=100${params ? `&page_token=${params}` : ""}`
        );
        allSubs.push(res.data.comments);
        params = res.data.nextPageToken;
      } while (params !== "");
      return allSubs.flat();
    } catch (error) {
      commit("common/setError", error, { root: true });
      throw error;
    }
  },
  async getComments({ commit, state, dispatch }, to) {
    if (!to.dontReset) {
      commit("comments/resetComments", "", { root: true });
    }
    let parameters = "";
    const nextPageToken = state.comments.nextPageToken;
    if (nextPageToken) {
      parameters = parameters + `&page_token=${nextPageToken}`;
    }
    if (to.params.cliId) {
      dispatch("clis/getCli", to, { root: true });
    }
    return commentsApi.listResource(
      `/projects/${to.params.projectId}/${
        to.params.cliId ? `clis/${to.params.cliId}/comments` : "comments"
      }`,
      {
        queryString: parameters,
        commit: "comments/setComments",
      }
    );
  },
};
