import { SMS_MARKETING_API_URL } from "@/consts/configuration";
import { httpClient } from "@/helpers/httpClient";
import { CrudService } from "@/api";
import currentTimezone from "@/helpers/currentTimezone";
import defaultDate from "@/helpers/defaultDate";
import moment from "moment";

const campaignsAPI = new CrudService(SMS_MARKETING_API_URL);

export default {
  async listAll({ commit }, to) {
    try {
      let params;
      const allSubs = [];
      do {
        const res = await httpClient.get(
          `${SMS_MARKETING_API_URL}/projects/${to.params.projectId}/campaigns?page_size=100${
            params ? `&page_token=${params}` : ""
          }`
        );
        allSubs.push(res.data.campaigns);
        params = res.data.nextPageToken;
      } while (params !== "");
      commit("setAll", allSubs.flat());
      return allSubs.flat();
    } catch (error) {
      throw error;
    }
  },
  async getOverview({ dispatch, commit }, to) {
    try {
      if (!to.query.date) {
        return;
      }

      const list = await dispatch("listAll", to);
      const contactLists = await dispatch("contactLists/listAll", to, {
        root: true,
      });
      const filtersVal = to.query.date ? JSON.parse(decodeURIComponent(to.query.date)) : "month";
      const payload = {
        date: !Array.isArray(filtersVal) ? defaultDate[filtersVal] : filtersVal,
      };

      const overview = await campaignsAPI.genericMethod({
        config: {
          method: "POST",
          url: `/projects/${to.params.projectId}/campaigns/-:generateOverviewReport`,
        },
        commit: "campaigns/setOverview",
        data: {
          timeZone: currentTimezone()["0"].name,
          startDate: {
            year: moment(new Date(payload.date[0]).toISOString()).format("YYYY"),
            month: moment(new Date(payload.date[0]).toISOString()).format("MM"),
            day: moment(new Date(payload.date[0]).toISOString()).format("DD"),
          },
          endDate: {
            year: moment(new Date(payload.date[1]).toISOString()).format("YYYY"),
            month: moment(new Date(payload.date[1]).toISOString()).format("MM"),
            day: moment(new Date(payload.date[1]).toISOString()).format("DD"),
          },
        },
        enableError: false,
      });

      return { list, overview, contactLists };
    } catch (error) {
      console.log(error);
    }
  },
  async listResource({}, payload) {
    return campaignsAPI.listResource("campaigns", payload);
  },
  async getResource({}, to) {
    const payload = {
      resourceName: `projects/${to.params.projectId}/campaigns/${to.params.campaignId}`,
      commit: "campaigns/setResource",
    };
    return campaignsAPI.getResource(payload);
  },
  async createResource({}, payload) {
    return campaignsAPI.createResource(
      `projects/${payload.projectId}/campaigns`,
      "Campaign",
      payload
    );
  },
  async updateResource({}, payload) {
    return campaignsAPI.updateResource("Campaign", payload);
  },
  async deleteResource({}, payload) {
    return campaignsAPI.deleteResource("Campaign", payload);
  },
  async sendCampaign({}, payload) {
    return campaignsAPI.genericMethod({
      config: {
        method: "POST",
        url: `/${payload.resourceName}:send`,
      },
      data: {},
      enableError: false,
    });
  },
  async generateIdeas({}, payload) {
    return campaignsAPI.genericMethod({
      config: {
        method: "POST",
        url: `/projects/${payload.projectId}/campaigns:generateIdeas`,
      },
      commit: "campaigns/setIdeas",
      success: "Ideas generated",
      data: { ...payload.ideaPayload },
      enableError: false,
    });
  },
  async previewCampaign({}, payload) {
    return campaignsAPI.genericMethod({
      config: {
        method: "POST",
        url: `/${payload.resourceName}:preview`,
      },
      data: {},
      enableError: false,
    });
  },
  async cancelCampaign({}, payload) {
    return campaignsAPI.genericMethod({
      config: {
        method: "POST",
        url: `/${payload.resourceName}:cancel`,
      },
      data: {},
      enableError: false,
    });
  },
  async initializeCampaignView({ dispatch }, to) {
    const payload = {
      params: {
        projectId: to.params.projectId,
        campaignId: to.query.campaign,
      },
    };
    try {
      const campaign = await dispatch("getResource", to);
      const campaignOverview = await campaignsAPI.genericMethod({
        config: {
          method: "POST",
          url: `/projects/${to.params.projectId}/campaigns/${to.params.campaignId}:generateOverviewReport`,
        },
        commit: "campaigns/setCampaignOverview",
        data: {
          timeZone: currentTimezone()["0"].name,
        },
        enableError: false,
      });
      await dispatch("contactLists/listAll", to, { root: true });
      return { campaign, campaignOverview };
    } catch (error) {
      throw error;
    }
  },
  async initializeCreateView({ dispatch }, to) {
    const payload = {
      params: {
        projectId: to.params.projectId,
        campaignId: to.query.campaign,
      },
    };
    try {
      if (to.query.campaign && to.query.campaign !== "duplicate") {
        await dispatch("getResource", payload);
      }
      const phoneNumbers = await dispatch(
        "phoneNumberInstances/getPhoneNumbers",
        {
          projectId: to.params.projectId,
        },
        {
          root: true,
        }
      );
      return { phoneNumbers };
    } catch (error) {
      throw error;
    }
  },
  async listCampaignLinks({ commit, dispatch, state }, to) {
    if (!to.dontReset) {
      commit("campaigns/resetCampaignLinks", "", { root: true });
    }

    const nextPageToken = state.campaignLinks.nextPageToken;
    const payload = {
      queryString: nextPageToken ? `&page_token=${nextPageToken}` : "",
      commit: "campaigns/setCampaignLinks",
    };
    return campaignsAPI.listResource(
      `/projects/${to.params.projectId}/campaigns/${to.params.campaignId}/links`,
      payload
    );
  },
  async listCampaignRecipients({ commit, dispatch, state }, to) {
    if (!to.dontReset) {
      commit("campaigns/resetCampaignRecipients", "", { root: true });
    }

    const nextPageToken = state.campaignRecipients.nextPageToken;
    const payload = {
      queryString: nextPageToken ? `&page_token=${nextPageToken}` : "",
      commit: "campaigns/setCampaignRecipients",
    };
    return campaignsAPI.listResource(
      `/projects/${to.params.projectId}/campaigns/${to.params.campaignId}/recipients`,
      payload
    );
  },
};
