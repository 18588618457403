var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"en-base-component en-select",class:[
    {
      'has-list-open': _vm.hasOpenList,
      'en-is-dark': _vm.dark,
    },
    `en-select--${_vm.color}`,
    `en-select--${_vm.size}`,
  ],on:{"!blur":function($event){return _vm.closeList($event)}}},[(_vm.multiple)?_c('div',{ref:"SelectedTags",staticClass:"en-select__tags en-flex en-align-center",class:{
      'en-left-offset': _vm.hasLeftIcon,
    }},[_c('transition-group',{ref:"SelectedTagsContainer",staticClass:"en-flex en-align-center en-h-100",attrs:{"tag":"div","name":"en-tags"}})],1):_vm._e(),_c('EnInput',_vm._b({ref:"textField",staticStyle:{"font-size":"16px"},attrs:{"value":_vm.valueShown,"readonly":"","no-label":_vm.hasNoLabel,"color":_vm.color,"size":_vm.size,"disabled":_vm.disabled,"error":_vm.error,"focus":_vm.hasOpenList},on:{"clear":function($event){return _vm.emitValues(null)},"keydown":function($event){_vm.search ? null : _vm.keyboardNav($event)},"keyup":function($event){return _vm.$emit('keyup', $event)},"blur":function($event){return _vm.$emit('blur', $event)},"change":function($event){return _vm.$emit('change', $event)},"paste":function($event){return _vm.$emit('paste', $event)},"click":function($event){return _vm.$emit('click', $event)},"focus":_vm.openList}},'EnInput',_vm.$attrs,false),[_vm._t("icon-left",null,{"slot":"icon-left"}),_c('div',{staticClass:"en-select__toggle en-flex en-flex-center",attrs:{"slot":"icon-right","tabindex":"-1"},slot:"icon-right"},[_vm._t("arrow",function(){return [_c('svg',{staticClass:"en-select__toggle__arrow",attrs:{"mlns":"http://www.w3.org/2000/svg","width":"24","height":"24","viewBox":"0 0 24 24"}},[_c('path',{staticClass:"arrow",attrs:{"d":"M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z"}}),_c('path',{attrs:{"fill":"none","d":"M0 0h24v24H0V0z"}})])]})],2)],2),_c('transition',{attrs:{"name":_vm.listTransition}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.hasOpenList),expression:"hasOpenList"}],staticClass:"en-select__options-list en-flex en-elevation",class:[
        _vm.hasPositionTop
          ? 'en-select__options-list--top en-direction-column-reverse'
          : 'en-direction-column',
        { 'en-select__options-list--right': _vm.hasPositionRight },
      ],style:([_vm.itemListSize])},[(_vm.search)?_c('EnInput',{ref:"SearchInput",staticClass:"en-m-1",attrs:{"color":_vm.color,"value":_vm.searchQuery,"size":"sm","no-label":"","name":"new_search_in_options","autocomplete":"off"},on:{"input":_vm.searchInOptions,"keydown":[_vm.keyboardNav,function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"]))return null;return _vm.closeList.apply(null, arguments)}]}}):_vm._e(),_c('div',{ref:"optionsList",staticClass:"en-select__options-list__items en-flex en-direction-column"},[_vm._l((_vm.optionsShown),function(option,i){return _c('button',{key:i,staticClass:"en-select__options-list__item flex en-align-center en-text-left",class:[
            {
              selected: _vm.values.length && _vm.values.includes(option[_vm.config.valueKey]),
            },
            { 'keyboard-selected': _vm.tmpValue === option[_vm.config.valueKey] },
          ],style:([_vm.optionHeight]),attrs:{"tabindex":"-1","type":"button"},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.updateValue(option[_vm.config.valueKey])}}},[_vm._t("default",function(){return [_c('span',{staticClass:"en-dots-text",class:[
                { 'en-text-muted': !option[_vm.config.valueKey] },
                _vm.values.includes(option[_vm.config.valueKey]) ? 'en-text-white' : 'en-text-color',
              ]},[_vm._v(" "+_vm._s(option[_vm.config.labelKey])+" ")])]},{"option":{
              ...option,
              isSelected: _vm.values.includes(option[_vm.config.valueKey]),
            },"tag":"div"})],2)}),(!_vm.optionsShown.length)?_vm._t("no-results",function(){return [_c('div',{staticClass:"en-select__options-list__no-results en-p-1 en-flex en-flex-center"},[_c('i',{staticClass:"material-icons en-text-danger"},[_vm._v("search_off")])])]},{"tag":"div"}):_vm._e()],2)],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }