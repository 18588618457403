const RegulatoryListingsList = () => import("@/views/RegulatoryListings/RegulatoryListingsList");
const RegulatoryListingsView = () => import("@/views/RegulatoryListings/RegulatoryListingsView");

export default [
  {
    path: "/projects/:projectId/regulatoryListings/list",
    name: "RegulatoryListings List",
    component: RegulatoryListingsList,
    meta: {
      pageTitle: "Regulatory listings",
      featureFlipping: {
        key: "regulatoryListings.list",
        default: false,
      },
      headerImg: "./header-numbers.png",
      layout: "LayoutMain",
      tabs: "numbers",
      dispatchAction: "regulatoryListings/listResource",
    },
  },
  {
    path: "/projects/:projectId/regulatoryListings/:regulatoryListingId",
    name: "RegulatoryListing Edit",
    component: RegulatoryListingsView,
    meta: {
      pageTitle: "View regulatory listing",
      featureFlipping: {
        key: "regulatoryListings.view",
        default: false,
      },
      headerImg: "./header-numbers.png",
      layout: "LayoutMain",
      tabs: "regulatoryListingView",
      dispatchAction: "regulatoryListings/getResource",
    },
  },
];
