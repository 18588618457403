export default {
  number: {
    name: "asdf",
  },
  numberList: {
    phoneNumberInstances: [],
    page: 1,
    itemsPerPage: 20,
  },
};
