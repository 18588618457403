export default {
  resetVoiceState(state, payload) {
    state = {
      call: {
        price: {},
        recordings: [],
      },
      calls: {
        data: [],
        nextPageToken: "",
      },
    };
  },
  setOverview(state, payload) {
    state.overview = payload;
  },
  setCall(state, payload) {
    state.call = payload;
  },
  resetCall(state, payload) {
    state.call = {};
  },
  resetCalls(state, payload) {
    state.calls = {
      data: [],
      nextPageToken: "",
    };
  },
  setCalls(state, payload) {
    state.calls = {
      data: state.calls.data.concat(payload.calls),
      nextPageToken: payload.nextPageToken,
    };
  },
  resetRecordings(state, payload) {
    state.recordingsList = {
      data: [],
      nextPageToken: "",
    };
  },
  setRecordingsList(state, payload) {
    state.recordingsList = {
      data: state.recordingsList.data.concat(payload.recordings),
      nextPageToken: payload.nextPageToken,
    };
  },
};
