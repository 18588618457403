const stateOptions = [
  {
    displayName: "Queued",
    value: "QUEUED",
  },
  {
    displayName: "Sending",
    value: "SENDING",
  },
  {
    displayName: "Sent",
    value: "SENT",
  },
  {
    displayName: "Failed",
    value: "FAILED",
  },
  {
    displayName: "Delivered",
    value: "DELIVERED",
  },
  {
    displayName: "Undelivered",
    value: "UNDELIVERED",
  },
  {
    displayName: "Received",
    value: "RECEIVED",
  },
];
const directionOptions = [
  {
    displayName: "Outgoing",
    value: "OUTGOING",
  },
  {
    displayName: "Incoming",
    value: "INCOMING",
  },
];
const encodingOptions = [
  {
    displayName: "GSM 7-bit encoding",
    value: "GSM7",
  },
  {
    displayName: "UCS-2 encoding",
    value: "UCS2",
  },
];

export { stateOptions, directionOptions, encodingOptions };
