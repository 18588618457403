export default {
  resetNumbersState(state, payload) {
    state.number = {};
    state.numberList = {
      phoneNumberInstances: [],
      page: 1,
      itemsPerPage: 20,
    };
    state.purchaseNumbers = {};
  },
  setNumber(state, payload) {
    state.number = payload;
  },
  resetNumber(state, payload) {
    state.number = {};
  },
  setNumberList(state, payload) {
    state.numberList.phoneNumberInstances = payload.phoneNumberInstances;
  },
  setNumberListPagination(state, payload) {
    state.numberList.page = payload.page;
    state.numberList.itemsPerPage = payload.itemsPerPage;
  },
};
