export default {
  setError({ commit }, payload) {
    commit("setError", payload);
  },
  clearError({ commit }) {
    commit("clearError");
  },
  setSuccess({ commit }, payload) {
    commit("setSuccess", payload);
  },
  clearSuccess({ commit }) {
    commit("clearSuccess");
  },
  clearInfo({ commit }) {
    commit("clearInfo");
  },
  clearLoading({ commit }, payload) {
    commit("setLoading", false);
  },
  setHasUnsavedChanges({ commit }, payload) {
    commit("setHasUnsavedChanges", payload);
  },
  setHistoryCount({ commit }, payload) {
    commit("setHistoryCount", payload);
  },
};
