import Vue from "vue";
import { computed, getCurrentInstance } from "vue";
import router from "@/router";

const routeData = Vue.observable({ params: {}, query: {}, meta: {} });

router.afterEach((route) => {
  routeData.query = route.query;
  routeData.params = route.params;
  routeData.meta = route.meta;
});

export default function () {
  return computed(() => routeData);
}
