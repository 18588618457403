import { MessagingApiUrl } from "@/consts/configuration";
import _ from "lodash";
import { httpClient } from "@/helpers/httpClient";
import { CrudService } from "@/api";
const messagesApi = new CrudService(MessagingApiUrl);

export default {
  async listResource({ commit, state }, to) {
    try {
      let params;
      const allSubs = [];
      do {
        const res = await httpClient.get(
          `${MessagingApiUrl}/projects/${to.params.projectId}/unsubscribers?page_size=100${
            params ? `&page_token=${params}` : ""
          }`
        );
        allSubs.push(res.data.unsubscribers);
        params = res.data.nextPageToken;
      } while (params !== "");
      commit("setUnsubscribers", allSubs.flat());
      return allSubs.flat();
    } catch (error) {
      throw error;
    }
  },
  async deleteUnsubscriber({ commit, state }, payload) {
    return messagesApi.deleteResource("Unsubscriber", {
      resourceName: payload.unsubscriberName,
    });
  },
};
