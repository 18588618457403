import { BillingApiUrl, LocalApiUrl } from "@/consts/configuration";
import { CrudService } from "@/api";
import { httpClient } from "@/helpers/httpClient";
import { allPermissions } from "@/consts/configuration";
const billingAccountsAPI = new CrudService(BillingApiUrl);

export default {
  resetBillingReports({ commit }) {
    commit("resetBillingReports", "");
  },
  async initializeBillingOverview({ commit, state, dispatch, rootState, rootGetters }, to) {
    const hasBillingAccountPermission = rootGetters["billingAccounts/checkBillingPermissions"](
      allPermissions.billingBillingAccountsGetAccountDetails
    );
    const billingAccountId = to.params.billingAccountId;
    try {
      if (billingAccountId && hasBillingAccountPermission) {
        await dispatch("billingAccounts/getBalance", { billingAccountId }, { root: true });
        await dispatch(
          "billingAccounts/getAutoTopUpSettings",
          {
            billingAccountId,
          },
          { root: true }
        );
        await dispatch(
          "billingAccounts/getBillingAccountInfo",
          {
            billingAccountId,
          },
          { root: true }
        );
        await dispatch(
          "billingAccounts/getBillingAccountDetails",
          {
            billingAccountId,
          },
          { root: true }
        );
        await dispatch("payments/getPayments", to, { root: true });
        await dispatch("invoices/getInvoices", to, { root: true });
        await dispatch(
          "paymentMethods/getPaymentMethods",
          {
            billingAccountId,
          },
          { root: true }
        );
      }
      return { success: true };
    } catch (error) {
      throw error;
    }
  },
  async testBillingIamPermission({ commit }, payload) {
    const data = {
      permissions: payload.billingPermissions,
    };
    return billingAccountsAPI.genericMethod({
      config: { method: "POST", url: `/${payload.billingAccountId}:testIamPermissions` },
      commit: "billingAccounts/setBillingPermissions",
      data,
      enableError: true,
    });
  },
  async createBillingAccount({ commit }, data) {
    return billingAccountsAPI.genericMethod({
      config: { method: "POST", url: `/billingAccounts` },
      commit: "billingAccounts/setBillingAccount",
      data,
      enableError: true,
    });
  },
  async updateBillingAccount({ commit, state }, payload) {
    const mask = "?update_mask=displayName";
    const data = { displayName: payload.displayName };
    return billingAccountsAPI.genericMethod({
      config: { method: "PATCH", url: `/billingAccounts/${payload.billingAccountId}${mask}` },
      commit: "billingAccounts/setBillingAccountDetails",
      data,
      enableError: true,
    });
  },
  async updateProjectBillingInfo({ commit }, payload) {
    const data = {
      billingAccount: payload.billingAccount.value.name,
    };
    return billingAccountsAPI.genericMethod({
      config: { method: "PUT", url: `/${payload.projectId}/billingInfo` },
      commit: "billingAccounts/setBillingAccount",
      data,
      enableError: true,
    });
  },
  async testBillingAccountIamPermission({ commit }, payload) {
    const data = {
      permissions: ["billing.resourceAssociations.update"],
    };
    return billingAccountsAPI.genericMethod({
      config: { method: "POST", url: `/${payload.billingAccountId}:testIamPermissions` },
      commit: "",
      data,
      enableError: true,
    });
  },
  async getBillingAccounts({ commit }) {
    return billingAccountsAPI.genericMethod({
      config: { method: "GET", url: `/billingAccounts?page_size=100` },
      commit: "billingAccounts/setBillingAccounts",
      enableError: true,
    });
  },
  async getAllBillingAccounts({ commit }) {
    try {
      let params = "";
      const allSubs = [];
      do {
        const res = await httpClient.get(
          `${BillingApiUrl}/billingAccounts?page_size=100${params ? `&page_token=${params}` : ""}`
        );
        allSubs.push(res.data.billingAccounts);
        params = res.data.nextPageToken;
      } while (params);
      commit("setAllBillingAccounts", allSubs.flat());
      return allSubs.flat();
    } catch (error) {
      throw error;
    }
  },
  async getBillingIam({ commit, state }, to) {
    return billingAccountsAPI.genericMethod({
      config: {
        method: "POST",
        url: `/billingAccounts/${to.params.billingAccountId}:getIamPolicy`,
      },
      commit: "iam/setIamPolicy",
      enableError: true,
    });
  },
  async setBillingIam({ commit, state }, payload) {
    const data = {
      policy: { bindings: payload.body, etag: payload.etag },
    };
    return billingAccountsAPI.genericMethod({
      config: { method: "POST", url: `/billingAccounts/${payload.billingAccountId}:setIamPolicy` },
      commit: "iam/setIamPolicy",
      data,
      enableError: true,
    });
  },
  async autoTopUpUpdate({ commit, state }, payload) {
    const data = {
      enabled: payload.enabled,
      threshold: {
        currencyCode: payload.currencyCode,
        nanos: 0,
        units: parseInt(payload.balanceThreshold.value, 10),
      },
      targetBalance: {
        currencyCode: payload.currencyCode,
        nanos: 0,
        units: parseInt(payload.targetBalance.value, 10),
      },
    };
    return billingAccountsAPI.genericMethod({
      config: {
        method: "PATCH",
        url: `/billingAccounts/${payload.billingAccountId}/autoTopupSettings?update_mask=threshold,targetBalance,enabled`,
      },
      commit: "",
      data,
      enableError: true,
    });
  },
  async topUp({ commit, state }, payload) {
    try {
      const data = await httpClient.post(`${LocalApiUrl}payment-intent`, {
        billingAccountName: payload.billingAccountName,
        amount: payload.topUpAmount,
        paymentMethodId: payload.paymentMethod.externalId,
        currencyCode: payload.currencyCode,
      });
      return data;
    } catch (error) {
      throw error;
    }
  },
  async getBillingAccount({ commit, state }, payload) {
    // Don't throw errors if billing info request fails.
    return httpClient.get(`${BillingApiUrl}/${payload.projectId}/billingInfo`, {
      validateStatus: (status) => true,
    });
  },
  async getBillingAccountInfo({ commit, state }, payload) {
    return billingAccountsAPI.genericMethod({
      config: { method: "GET", url: `/billingAccounts/${payload.billingAccountId}` },
      commit: "billingAccounts/setBillingAccountInfo",
      enableError: true,
    });
  },
  async getBillingAccountDetails({ commit, state }, payload) {
    return billingAccountsAPI.genericMethod({
      config: { method: "GET", url: `/billingAccounts/${payload.billingAccountId}/accountDetails` },
      commit: "billingAccounts/setBillingAccountDetails",
      enableError: true,
    });
  },
  async updateBillingAccountDetails({ commit, state }, payload) {
    let mask = "?update_mask=";
    const accountDetailsPayload = {};
    if (payload.billingAccountDetails) {
      mask = mask + "address";
      accountDetailsPayload["address"] = payload.billingAccountDetails;
    }
    if (payload.businessName) {
      mask = mask + ",businessName";
      accountDetailsPayload["businessName"] = payload.businessName.value;
    }

    return billingAccountsAPI.genericMethod({
      config: {
        method: "PATCH",
        url: `/billingAccounts/${payload.billingAccountId}/accountDetails${mask}`,
      },
      commit: "billingAccounts/setBillingAccountDetails",
      data: accountDetailsPayload,
      enableError: true,
    });
  },
  async getBalance({ commit, state }, payload) {
    return billingAccountsAPI.genericMethod({
      config: { method: "POST", url: `/billingAccounts/${payload.billingAccountId}:getBalance` },
      commit: "billingAccounts/setBalance",
      enableError: true,
    });
  },
  async getAutoTopUpSettings({ commit, state }, payload) {
    return billingAccountsAPI.genericMethod({
      config: {
        method: "GET",
        url: `/billingAccounts/${payload.billingAccountId}/autoTopupSettings`,
      },
      commit: "billingAccounts/setAutoTopUpSetting",
      enableError: true,
    });
  },
};
