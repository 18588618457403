import { BillingApiUrl } from "@/consts/configuration";
import { CrudService } from "@/api";
const billingAccountsAPI = new CrudService(BillingApiUrl);

export default {
  async listBundles({ commit }, to) {
    return billingAccountsAPI.genericMethod({
      config: { method: "GET", url: `projects/${to.params.projectId}/bundles` },
      commit: "bundles/setBundles",
      enableError: true,
    });
  },
  async getBundle({ commit }, payload) {
    return billingAccountsAPI.genericMethod({
      config: { method: "GET", url: `${payload.resourceName}` },
      commit: "bundles/setBundle",
      enableError: true,
    });
  },
  async scaleBundle({ commit }, payload) {
    return billingAccountsAPI.genericMethod({
      config: { method: "POST", url: `${payload.resourceName}:scale` },
      data: {
        quantity: payload.scale,
      },
      commit: "",
      enableError: true,
    });
  },
};
