export default {
  resetState(state, payload) {
    state.list.data = [];
    state.list.nextPageToken = "";
    state.list.page = 1;
    state.list.itemsPerPage = 20;
    state.resource = null;
  },
  setAll(state, payload) {
    state.all = payload;
  },
  setList(state, payload) {
    state.list.data = state.list.data.concat(payload.contactLists);
    state.list.nextPageToken = payload.nextPageToken;
    state.list.totalSize = payload.totalSize;
  },
  setResource(state, payload) {
    state.resource = payload;
  },
  setResourcePagination(state, payload) {
    state.list.page = payload.page;
    state.list.itemsPerPage = payload.itemsPerPage;
  },
};
