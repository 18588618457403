import { SMS_MARKETING_API_URL } from "@/consts/configuration";
import { httpClient } from "@/helpers/httpClient";
import { CrudService } from "@/api";
const contactsApi = new CrudService(SMS_MARKETING_API_URL);

export default {
  async listAll({ commit, state }, payload) {
    try {
      let params;
      const allSubs = [];
      do {
        const res = await httpClient.get(
          `${SMS_MARKETING_API_URL}/projects/${payload.projectId}/contacts?page_size=100${
            params ? `&page_token=${params}` : ""
          }`
        );
        allSubs.push(res.data.customers);
        params = res.data.nextPageToken;
      } while (params !== "");
      commit("setAll", allSubs.flat());
      return allSubs.flat();
    } catch (error) {
      throw error;
    }
  },
  async listSubResource({ commit, dispatch, state }, to) {
    if (!to.dontReset) {
      commit("contacts/resetList", "", { root: true });
    }

    const search = to.query.search;
    const nextPageToken = state.list.nextPageToken;
    let queryString = "";

    if (search) {
      queryString = queryString + `&query=${search}`;
    }

    if (nextPageToken) {
      queryString = queryString + `&page_token=${nextPageToken}`;
    }

    const payload = {
      queryString,
      commit: "contacts/setList",
    };
    return contactsApi.listResource(
      `projects/${to.params.projectId}/contactLists/${to.params.contactListId}/contacts` +
        (search ? ":search" : ""),
      payload
    );
  },
  async listResource({ commit, dispatch, state }, to) {
    if (!to.dontReset) {
      commit("contacts/resetList", "", { root: true });
    }

    const search = to.query.search;
    const nextPageToken = state.list.nextPageToken;
    let query = "";

    if (search) {
      query = query + `&query=${search}`;
    }

    if (nextPageToken) {
      query = query + `&page_token=${nextPageToken}`;
    }

    const payload = {
      queryString: query,
      commit: "contacts/setList",
    };

    return contactsApi.listResource(
      `/projects/${to.params.projectId}/contacts` + (search ? ":search" : ""),
      payload
    );
  },
  async getResource({}, to) {
    const payload = {
      resourceName: `projects/${to.params.projectId}/contacts/${to.params.contactId}`,
      commit: "contacts/setResource",
    };
    return contactsApi.getResource(payload);
  },
  async createResource({}, payload) {
    return contactsApi.createResource(`projects/${payload.projectId}/contacts`, "Contact", payload);
  },
  async updateResource({}, payload) {
    return contactsApi.updateResource("Contact", payload);
  },
  async upsertResource({}, payload) {
    return contactsApi.upsertResource(payload);
  },
  async deleteResource({}, payload) {
    return contactsApi.deleteResource("Contact", payload);
  },
  async searchContacts({ commit, dispatch, state }, payload) {
    const search = payload.search;
    let query = "";

    if (search) {
      query = query + `&query=${search}`;
    }

    const apiPayload = {
      queryString: query,
      commit: "",
    };

    return contactsApi.listResource(
      `/projects/${payload.projectId}/contacts` + (search ? ":search" : ""),
      apiPayload
    );
  },
};
