import AbstractEvent from "./AbstractEvent";

export default class VueDragStart extends AbstractEvent {
  public static type = "vue:drag:start";

  get sourceComponent() {
    // @ts-ignore
    return this.data.sourceComponent;
  }
}
