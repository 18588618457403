export default {
  resetMessagingState(state, payload) {
    state.message = {
      price: {},
      recordings: [],
    };
    state.messages = {
      data: [],
      nextPageToken: "",
    };
    state.unsubscribers = [];
  },
  setOverview(state, payload) {
    state.overview = payload;
  },
  setMessage(state, payload) {
    state.message = payload;
  },
  resetMessage(state, payload) {
    state.message = {
      price: {},
      recordings: [],
    };
  },
  resetMessages(state, payload) {
    state.messages = {
      data: [],
      nextPageToken: "",
    };
  },
  setMessages(state, payload) {
    state.messages = {
      data: state.messages.data.concat(payload.messages),
      nextPageToken: payload.nextPageToken,
    };
  },
};
