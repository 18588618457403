export default {
  resetIamState(state, payload) {
    state.iamPolicy = { bindings: [] };
    // state.serviceAccounts = {
    //   data: [],
    //   nextPageToken: "",
    // };
    // state.serviceAccount = {};
    // state.serviceAccountKeys = {
    //   data: [],
    //   nextPageToken: "",
    // };
    // state.serviceAccountKey = {};
  },
  setIamPolicy(state, payload) {
    state.iamPolicy = payload;
  },
  resetServiceAccounts(state, payload) {
    state.serviceAccounts = { nextPageToken: "", data: [] };
  },
  setServiceAccounts(state, payload) {
    state.serviceAccounts.data = payload.serviceAccounts;
    state.serviceAccounts.nextPageToken = payload.nextPageToken;
  },
  setServiceAccount(state, payload) {
    state.serviceAccount = payload;
  },
  resetServiceAccountKeys(state, payload) {
    state.serviceAccountKeys = { nextPageToken: "", data: [] };
  },
  setServiceAccountKeys(state, payload) {
    state.serviceAccountKeys = payload;
  },
  setServiceAccountKey(state, payload) {
    state.serviceAccountKey = payload;
  },
};
