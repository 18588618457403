var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"parent",staticClass:"en-base-component en-input en-border en-border-color en-border-color-hover en-border-solid en-border-radius",class:[
    {
      'is-focused': _vm.isFocus || _vm.focus,
      'is-valid': _vm.success,
      'has-value': _vm.value,
      'is-textarea': _vm.textarea,
      'has-error': _vm.error,
      'has-warning': _vm.warning,
      'is-disabled': _vm.disabled,
      'en-is-dark': _vm.dark,
      'has-hint': _vm.hint,
      'has-no-label': !_vm.hasLabel && !_vm.hint,
      'has-left-icon': _vm.hasLeftIcon(),
    },
    `en-input--${_vm.size}`,
    `has-${_vm.leftNumberIcon}-right-icon`,
    `en-input--${_vm.color}`,
  ],on:{"click":_vm.focusInput}},[(_vm.hasLeftIcon())?_c('div',{staticClass:"en-input__icon en-flex left",class:[_vm.textarea ? 'en-align-start en-pt-2' : 'en-align-center']},[_vm._t(`icon-left`,function(){return [_c('i',{staticClass:"material-icons"},[_vm._v(_vm._s(_vm.leftIconName))])]})],2):_vm._e(),(_vm.hasRightIcon())?_c('div',{staticClass:"en-input__icon en-flex right",class:[_vm.textarea ? 'en-align-start en-pt-2' : 'en-align-center']},[_vm._t(`icon-right`,function(){return [_c('i',{staticClass:"material-icons"},[_vm._v(_vm._s(_vm.rightIconName))])]})],2):_vm._e(),((_vm.getType)==='checkbox'&&(!_vm.textarea))?_c('input',_vm._b({directives:[{name:"model",rawName:"v-model",value:(_vm.inputValue),expression:"inputValue"}],ref:"EnInput",staticClass:"en-input__input en-border-radius",class:{
      'has-right-icon': _vm.hasClearBtn || _vm.hasPasswordBtn || _vm.hasRightIcon(),
    },attrs:{"id":_vm.customId ? _vm.customId : _vm.uniqueId,"disabled":_vm.disabled,"required":_vm.required,"readonly":_vm.readonly,"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.inputValue)?_vm._i(_vm.inputValue,null)>-1:(_vm.inputValue)},on:{"keydown":_vm.keyDown,"keyup":_vm.keyUp,"focus":_vm.onFocus,"blur":_vm.onBlur,"paste":_vm.onPaste,"change":[function($event){var $$a=_vm.inputValue,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.inputValue=$$a.concat([$$v]))}else{$$i>-1&&(_vm.inputValue=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.inputValue=$$c}},_vm.onChange],"click":function($event){return _vm.$emit('click', $event)}}},'input',_vm.$attrs,false)):((_vm.getType)==='radio'&&(!_vm.textarea))?_c('input',_vm._b({directives:[{name:"model",rawName:"v-model",value:(_vm.inputValue),expression:"inputValue"}],ref:"EnInput",staticClass:"en-input__input en-border-radius",class:{
      'has-right-icon': _vm.hasClearBtn || _vm.hasPasswordBtn || _vm.hasRightIcon(),
    },attrs:{"id":_vm.customId ? _vm.customId : _vm.uniqueId,"disabled":_vm.disabled,"required":_vm.required,"readonly":_vm.readonly,"type":"radio"},domProps:{"checked":_vm._q(_vm.inputValue,null)},on:{"keydown":_vm.keyDown,"keyup":_vm.keyUp,"focus":_vm.onFocus,"blur":_vm.onBlur,"paste":_vm.onPaste,"change":[function($event){_vm.inputValue=null},_vm.onChange],"click":function($event){return _vm.$emit('click', $event)}}},'input',_vm.$attrs,false)):(!_vm.textarea)?_c('input',_vm._b({directives:[{name:"model",rawName:"v-model",value:(_vm.inputValue),expression:"inputValue"}],ref:"EnInput",staticClass:"en-input__input en-border-radius",class:{
      'has-right-icon': _vm.hasClearBtn || _vm.hasPasswordBtn || _vm.hasRightIcon(),
    },attrs:{"id":_vm.customId ? _vm.customId : _vm.uniqueId,"disabled":_vm.disabled,"required":_vm.required,"readonly":_vm.readonly,"type":_vm.getType},domProps:{"value":(_vm.inputValue)},on:{"keydown":_vm.keyDown,"keyup":_vm.keyUp,"focus":_vm.onFocus,"blur":_vm.onBlur,"paste":_vm.onPaste,"change":_vm.onChange,"click":function($event){return _vm.$emit('click', $event)},"input":function($event){if($event.target.composing)return;_vm.inputValue=$event.target.value}}},'input',_vm.$attrs,false)):_c('textarea',_vm._b({directives:[{name:"model",rawName:"v-model",value:(_vm.inputValue),expression:"inputValue"}],ref:"EnInput",staticClass:"en-input__input en-textarea",attrs:{"id":_vm.customId ? _vm.customId : _vm.uniqueId,"type":_vm.type,"required":_vm.required,"readonly":_vm.readonly,"autofocus":""},domProps:{"value":(_vm.inputValue)},on:{"keydown":_vm.keyDown,"keyup":_vm.keyUp,"focus":_vm.onFocus,"blur":_vm.onBlur,"paste":_vm.onPaste,"change":_vm.onChange,"click":function($event){return _vm.$emit('click', $event)},"input":function($event){if($event.target.composing)return;_vm.inputValue=$event.target.value}}},'textarea',_vm.$attrs,false)),_vm._v(" "),_c('transition-group',{attrs:{"name":"en-scale"}},[(_vm.hasClearBtn)?_c('button',{key:"clear-button",staticClass:"en-input__toggle-btn --clear en-flex en-flex-center",class:{ 'has-right-icon': _vm.hasRightIcon() },attrs:{"title":"clear","type":"button","tabindex":"-1"},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.clear.apply(null, arguments)}}},[_c('i',{staticClass:"en-input__toggle-btn__icon material-icons"},[_vm._v("close")])]):_vm._e(),(_vm.hasPasswordBtn)?_c('button',{key:"password-button",staticClass:"en-input__toggle-btn password en-flex en-flex-center",class:{
        'has-clear-btn': _vm.hasClearBtn,
        'has-right-icon': _vm.hasRightIcon(),
      },attrs:{"title":"clear","type":"button","tabindex":"-1"},on:{"click":function($event){_vm.showPassword = !_vm.showPassword}}},[_c('i',{staticClass:"en-input__toggle-btn__icon material-icons"},[_vm._v(" "+_vm._s(_vm.showPassword ? "visibility_off" : "visibility")+" ")])]):_vm._e()]),(_vm.loading)?_c('div',{staticClass:"en-input__loader",class:{ textarea: _vm.textarea }},[_c('div',{staticClass:"en-input__loader__progress-bar"})]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }