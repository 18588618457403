const ClisList = () => import("@/views/Clis/ClisList");
const ClisView = () => import("@/views/Clis/ClisView");
const ClisOverview = () => import("@/views/Clis/ClisOverview");
const ClisSocial = () => import("@/views/Clis/ClisSocial");
const ClisChecks = () => import("@/views/Clis/ClisChecks");
const ClisSubscriptions = () => import("@/views/Clis/ClisSubscriptions");

export default [
  {
    path: "/projects/:projectId/clis/overview",
    name: "Caller ID Overview",
    component: ClisOverview,
    meta: {
      pageTitle: "Caller ID overview",
      featureFlipping: {
        key: "clis.view",
        default: false,
      },
      headerImg: "./header-analytics.png",
      layout: "LayoutMain",
      tabs: "clis",
      dispatchAction: "clis/initializeCliOverview",
    },
  },
  {
    path: "/projects/:projectId/clis/list",
    name: "Caller ID List",
    component: ClisList,
    meta: {
      pageTitle: "Caller IDs",
      featureFlipping: {
        key: "clis.list",
        default: false,
      },
      headerImg: "./header-analytics.png",
      layout: "LayoutMain",
      tabs: "clis",
      dispatchAction: "clis/searchClis",
    },
  },
  {
    path: "/projects/:projectId/clis/checks",
    name: "Caller ID Checks",
    component: ClisChecks,
    meta: {
      pageTitle: "Caller ID checks",
      featureFlipping: {
        key: "checks.list",
        default: false,
      },
      headerImg: "./header-analytics.png",
      layout: "LayoutMain",
      tabs: "clis",
      dispatchAction: "checks/getChecks",
    },
  },
  {
    path: "/projects/:projectId/clis/social",
    name: "Caller ID Social",
    component: ClisSocial,
    meta: {
      pageTitle: "Caller ID social",
      featureFlipping: {
        key: "comments.list",
        default: false,
      },
      headerImg: "./header-analytics.png",
      layout: "LayoutMain",
      tabs: "clis",
      dispatchAction: "comments/getComments",
    },
  },
  {
    path: "/projects/:projectId/clis/subscriptions/list",
    name: "Caller ID Email Subscriptions",
    component: ClisSubscriptions,
    meta: {
      pageTitle: "Caller ID subscriptions",
      featureFlipping: {
        key: "clis.view",
        default: false,
      },
      headerImg: "./header-analytics.png",
      layout: "LayoutMain",
      tabs: "clis",
      back: false,
      backLink: "/clis/overview",
      dispatchAction: "subscriptions/getSubscriptions",
    },
  },
  {
    path: "/projects/:projectId/clis/:cliId/overview",
    name: "Caller ID View Overview",
    component: ClisView,
    meta: {
      pageTitle: "Caller ID view",
      featureFlipping: {
        key: "clis.view",
        default: false,
      },
      headerImg: "./header-analytics.png",
      countryCode: "AU",
      back: true,
      backLink: "/clis/list",
      layout: "LayoutMain",
      tabs: "clisView",
      dispatchAction: "clis/initializeCliView",
    },
  },
  {
    path: "/projects/:projectId/clis/:cliId/social",
    name: "Caller ID View Social",
    component: ClisSocial,
    meta: {
      pageTitle: "Caller ID view social",
      featureFlipping: {
        key: "comments.list",
        default: false,
      },
      headerImg: "./header-analytics.png",
      back: true,
      countryCode: "AU",
      backLink: "/clis/list",
      layout: "LayoutMain",
      tabs: "clisView",
      dispatchAction: "comments/getComments",
    },
  },
  {
    path: "/projects/:projectId/clis/:cliId/checks",
    name: "Caller ID View Checks",
    component: ClisChecks,
    meta: {
      pageTitle: "Caller ID view checks",
      featureFlipping: {
        key: "checks.list",
        default: false,
      },
      headerImg: "./header-analytics.png",
      countryCode: "AU",
      back: true,
      backLink: "/clis/list",
      layout: "LayoutMain",
      tabs: "clisView",
      dispatchAction: "checks/getChecks",
    },
  },
];
