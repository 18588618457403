export default {
  resetComments(state, payload) {
    state.comments = {
      data: [],
      nextPageToken: "",
    };
  },
  setComments(state, payload) {
    state.comments = {
      data: state.comments.data.concat(payload.comments),
      nextPageToken: payload.nextPageToken,
    };
  },
};
