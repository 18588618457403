import { ref, toRefs, reactive, computed, defineComponent, getCurrentInstance } from "vue";
import _ from "lodash";

export default function stepComposition(store, props): any {
  const state = reactive({
    active: false,
  });

  const selectedStep = computed(() => store.state.flows.selectedStep);

  function openStepForm(step) {
    if (
      !step.data.title.includes("COMPLETE") &&
      !step.data.title.includes("INCOMPLETE") &&
      !step.data.title.includes("MATCH") &&
      !step.data.title.includes("NO MATCH") &&
      !step.data.title.includes("QLD") &&
      !step.data.title.includes("NSW") &&
      !step.data.title.includes("VIC") &&
      !step.data.title.includes("ACT") &&
      !step.data.title.includes("TAS") &&
      !step.data.title.includes("SA") &&
      !step.data.title.includes("WA") &&
      !step.data.title.includes("NT")
    ) {
      store.dispatch("flows/handleStepForm");
      store.dispatch("flows/handleSelectedStep", {});
      setTimeout(() => {
        store.dispatch("flows/handleSelectedStep", step);
      }, 100);
    }
  }

  function closeStepForm(mouseEvent) {
    let stepFormClicked = false;
    mouseEvent.path.forEach((element) => {
      if (element.classList) {
        if (
          element.classList.value.includes("step-form") ||
          element.classList.value.includes("v-dialog") ||
          element.classList.value.includes("v-overlay__scrim") ||
          element.classList.value.includes("v-overlay") ||
          element.classList.value.includes("v-menu__content")
        ) {
          stepFormClicked = true;
        }
      }
    });
    // @ts-ignore
    if (selectedStep.value.id === props.step.id && !stepFormClicked) {
      store.dispatch("flows/handleSelectedStep", {});
    }
  }

  return { ...toRefs(state), selectedStep, openStepForm, closeStepForm };
}
