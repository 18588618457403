export default {
  user: null,
  projectSigningKey: null,
  permissions: [],
  project: null,
  projects: [],
  appReady: false,
  queryParameters: {},
  tenantConfig: {},
  multiFactors: [],
};
