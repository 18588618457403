import { CallerreputationApiUrl } from "@/consts/configuration";
import _ from "lodash";
import { httpClient } from "@/helpers/httpClient";
import { CrudService } from "@/api";
import store from "..";
const clisApi = new CrudService(CallerreputationApiUrl);

export default {
  async bulkCli({ commit, state }, payload) {
    try {
      const data = await httpClient.patch(
        `${CallerreputationApiUrl}/projects/${payload.projectId}/clis/${payload.cliId}?update_mask=displayName,brand,checkProfile&allow_missing=true`,
        {
          displayName: payload.cli.displayName,
          brand: payload.cli.brand,
          checkProfile: payload.cli.checkProfile,
        }
      );
      return data.data;
    } catch (error) {
      throw error;
    }
  },
  async exportClis({ commit, state }, payload) {
    try {
      let params;
      const allSubs = [];
      do {
        const res = await httpClient.get(
          `${CallerreputationApiUrl}/projects/${
            payload.projectId
          }/clis:search?page_size=100&query=${payload.search}${
            params ? `&page_token=${params}` : ""
          }`
        );
        allSubs.push(res.data.clis);
        params = res.data.nextPageToken;
      } while (params !== "");
      return allSubs.flat();
    } catch (error) {
      commit("common/setError", error, { root: true });
      throw error;
    }
  },
  async searchClis({ commit, state }, to) {
    try {
      let params;
      const allSubs = [];
      do {
        const res = await httpClient.get(
          `${CallerreputationApiUrl}/projects/${
            to.params.projectId
          }/clis:search?page_size=100&query=${to.query.search ? to.query.search : ""}${
            params ? `&page_token=${params}` : ""
          }`
        );
        allSubs.push(res.data.clis);
        params = res.data.nextPageToken;
      } while (params !== "");
      store.commit("clis/setClis", allSubs.flat());
      return allSubs.flat();
    } catch (error) {
      throw error;
    }
  },
  async initializeCliView({ commit, dispatch, rootState }, to) {
    try {
      const cli = await dispatch("clis/getCli", to, { root: true });
      const comments = await dispatch("comments/getComments", to, {
        root: true,
      });
      const checks = await dispatch("checks/getChecks", to, {
        root: true,
      });
      const clis = await dispatch("clis/searchClis", to, {
        root: true,
      });

      return {
        cli,
        comments,
        checks,
        clis,
      };
    } catch (error) {
      throw error;
    }
  },
  async getCli({ commit, state }, to) {
    return clisApi.getResource({
      resourceName: `projects/${to.params.projectId}/clis/${to.params.cliId}`,
      commit: "clis/setCli",
    });
  },
  async createCli({ commit, state }, payload) {
    return clisApi.createResource(
      `projects/${payload.projectId}/clis?cli_id=${payload.cliId}`,
      "Caller ID",
      { resource: payload.cli }
    );
  },
  async updateCli({ commit, state }, payload) {
    return clisApi.updateResource("Caller ID", {
      resourceName: `projects/${payload.projectId}/clis/${payload.cliId}`,
      resource: {
        displayName: payload.body.displayName,
        brand: payload.body.brand,
        checkProfile: payload.body.checkProfile,
      },
    });
  },
  async deleteCli({ commit, state }, payload) {
    return clisApi.deleteResource("Caller ID", {
      resourceName: `projects/${payload.projectId}/clis/${payload.cliId}`,
    });
  },
  async pauseCli({ commit, state }, payload) {
    return clisApi.genericMethod({
      config: {
        method: "POST",
        url: `projects/${payload.projectId}/clis/${payload.cliId}:pause`,
      },
      success: "Caller ID paused",
      enableLoading: true,
      enableError: true,
      commit: "clis/setCli",
    });
  },
  async unpauseCli({ commit, state }, payload) {
    return clisApi.genericMethod({
      config: {
        method: "POST",
        url: `projects/${payload.projectId}/clis/${payload.cliId}:unpause`,
      },
      success: "Caller ID unpaused",
      enableLoading: true,
      enableError: true,
      commit: "clis/setCli",
    });
  },
  async getCliReport({ commit, state, rootState }, payload) {
    const url = `${CallerreputationApiUrl}/projects/${payload.projectId}:generateOverviewReport`;
    try {
      const res = await httpClient.post(url, {});
      commit("clis/setOverview", res.data, { root: true });
      return res.data;
    } catch (error) {
      throw error;
    }
  },
  async initializeCliOverview({ commit, dispatch, rootState }, to) {
    try {
      const cliReport = await dispatch(
        "clis/getCliReport",
        {
          projectId: to.params.projectId,
        },
        { root: true }
      );
      const comments = await dispatch("comments/getComments", to, {
        root: true,
      });
      return {
        cliReport,
        comments,
      };
    } catch (error) {
      throw error;
    }
  },
};
