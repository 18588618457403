import { NumberingApiUrl } from "@/consts/configuration";
import { httpClient } from "@/helpers/httpClient";
import { CrudService } from "@/api";
const crudService = new CrudService(NumberingApiUrl);

export default {
  async listResource({ commit, state }, to) {
    const payload = {
      projectId: to.params.projectId,
      portInRequestId: to.params.portInRequestId,
    };

    try {
      let params;
      const allSubs = [];
      do {
        const res = await httpClient.get(
          `${NumberingApiUrl}/projects/${payload.projectId}/portInRequests?page_size=100${
            params ? `&page_token=${params}` : ""
          }`
        );
        allSubs.push(res.data.portInRequests);
        params = res.data.nextPageToken;
      } while (params !== "");
      commit("setAll", allSubs.flat());
      return allSubs.flat();
    } catch (error) {
      throw error;
    }
  },
  async tenantsListAll({ commit, state }, to) {
    try {
      let params;
      const allSubs = [];
      do {
        const res = await httpClient.get(
          `${NumberingApiUrl}/projects/-/portInRequests?page_size=100${
            params ? `&page_token=${params}` : ""
          }`
        );
        allSubs.push(res.data.portInRequests);
        params = res.data.nextPageToken;
      } while (params !== "");
      commit("setAll", allSubs.flat());
      return allSubs.flat();
    } catch (error) {
      throw error;
    }
  },
  async listChatMessages({ commit, state }, to) {
    const payload = {
      projectId: to.params.projectId,
      portInRequestId: to.query.portInRequestId
        ? to.query.portInRequestId
        : to.params.portInRequestId,
    };

    try {
      let params;
      const allSubs = [];
      do {
        const res = await httpClient.get(
          `${NumberingApiUrl}/projects/${payload.projectId}/portInRequests/${
            payload.portInRequestId
          }/chatMessages?page_size=100${params ? `&page_token=${params}` : ""}`
        );
        allSubs.push(res.data.chatMessages);
        params = res.data.nextPageToken;
      } while (params !== "");
      commit("setChatMessages", allSubs.flat());
      return allSubs.flat();
    } catch (error) {
      throw error;
    }
  },
  async getResource({ dispatch, commit }, to) {
    if (to.query.portInRequestId === "duplicate") {
      return;
    }

    try {
      const regulatoryListingRes = await dispatch(
        "regulatoryListings/listAllAndInvalid",
        {
          projectId: to.params.projectId,
        },
        { root: true }
      );

      if (to.params.portInRequestId || to.query.portInRequestId) {
        const payload = {
          projectId: to.params.projectId,
          portInRequestId: to.query.portInRequestId
            ? to.query.portInRequestId
            : to.params.portInRequestId,
        };

        await dispatch("listChatMessages", to);

        const res = await crudService.getResource({
          resourceName: `projects/${payload.projectId}/portInRequests/${payload.portInRequestId}`,
          commit: "",
        });

        commit("setResource", res);
      }

      return {
        regulatoryListingRes,
      };
    } catch (error) {
      console.log(error);
    }
  },
  async createResource({}, payload) {
    return crudService.createResource(`projects/${payload.projectId}/portInRequests`, "", {
      resource: payload.resource,
    });
  },
  async updateResource({}, payload) {
    return crudService.updateResource("Port in request", {
      resourceName: `${payload.resourceName}`,
      resource: payload.resource,
      updateMask: payload.updateMask,
    });
  },
  async deleteResource({}, payload) {
    return crudService.deleteResource("Port in request", {
      resourceName: `${payload.resourceName}`,
    });
  },
  async submitPortInRequest({ commit, state }, payload) {
    try {
      const res = await httpClient.post(`${NumberingApiUrl}/${payload.resourceName}:submit`, {
        redirectUri: payload.redirectUri,
      });
      return res.data;
    } catch (error) {
      throw error;
    }
  },
  async cancelPortInRequest({ commit, state }, payload) {
    try {
      const res = await httpClient.post(
        `${NumberingApiUrl}/projects/${payload.projectId}/portInRequests/${payload.portInRequestId}:cancel`
      );
      commit(
        "common/setSuccess",
        {
          data: "Port in request cancelled",
        },
        { root: true }
      );
      return res.data;
    } catch (error) {
      throw error;
    }
  },
  async getAttachment({ commit, state }, payload) {
    try {
      const res = await httpClient.get(`${NumberingApiUrl}/${payload.resourceName}`);
      return res.data;
    } catch (error) {
      throw error;
    }
  },
  async createChatMessage({ commit, state }, payload) {
    try {
      const res = await httpClient.post(`${NumberingApiUrl}/${payload.resourceName}/chatMessages`, {
        body: payload.body,
      });
      return res.data;
    } catch (error) {
      throw error;
    }
  },
  async updateSchedule({ commit, state }, payload) {
    try {
      const res = await httpClient.post(`${NumberingApiUrl}/${payload.resourceName}:schedule`, {
        ...payload.scheduleDate,
      });
      commit(
        "common/setSuccess",
        {
          data: "Port in request updated",
        },
        { root: true }
      );
      return res.data;
    } catch (error) {
      throw error;
    }
  },
};
