import Vue from "vue";

import FlowBuilder from "@/components/FlowBuilder";
import Preview from "@/components/Steps/Preview";
import CallStep from "@/components/Steps/CallStep";
import DateStep from "@/components/Steps/DateStep";
import MenuStep from "@/components/Steps/MenuStep";
import PlayStep from "@/components/Steps/PlayStep";
import RecordStep from "@/components/Steps/RecordStep";
import TimeDayStep from "@/components/Steps/TimeDayStep";
import VoicemailStep from "@/components/Steps/VoicemailStep";
import FaxStep from "@/components/Steps/FaxStep";
import EntryStep from "@/components/Steps/EntryStep";
import ExitStep from "@/components/Steps/ExitStep";
import AustateStep from "@/components/Steps/AustateStep";
import AupostcodeStep from "@/components/Steps/AupostcodeStep";
import GotoStep from "@/components/Steps/GotoStep";
import proportionalStep from "@/components/Steps/ProportionalStep";
import LoadBalancerStep from "@/components/Steps/LoadBalancerStep";
import AulocationStep from "@/components/Steps/AulocationStep";

import CallStepForm from "@/components/Forms/CallStepForm";
import DateStepForm from "@/components/Forms/DateStepForm";
import MenuStepForm from "@/components/Forms/MenuStepForm";
import PlayStepForm from "@/components/Forms/PlayStepForm";
import RecordStepForm from "@/components/Forms/RecordStepForm";
import TimeDayStepForm from "@/components/Forms/TimeDayStepForm";
import VoicemailStepForm from "@/components/Forms/VoicemailStepForm";
import FaxStepForm from "@/components/Forms/FaxStepForm";
import ExitStepForm from "@/components/Forms/ExitStepForm";
import EntryStepForm from "@/components/Forms/EntryStepForm";
import AustateStepForm from "@/components/Forms/AustateStepForm";
import AupostcodeStepForm from "@/components/Forms/AupostcodeStepForm";
import GotoStepForm from "@/components/Forms/GotoStepForm";
import proportionalStepForm from "@/components/Forms/ProportionalStepForm";
import LoadBalancerStepForm from "@/components/Forms/LoadBalancerStepForm";
import AulocationStepForm from "@/components/Forms/AulocationStepForm";

// Component folder structure for node should be split into 2 Step/Form.
// The node which is what is displayed in the chart itself.
// The form which is the configurable form to be used to configure the node.

export default function () {
  // Flow
  Vue.use(FlowBuilder);
  // Steps
  Vue.component("Preview", Preview);
  Vue.component("CallStep", CallStep);
  Vue.component("DateStep", DateStep);
  Vue.component("MenuStep", MenuStep);
  Vue.component("PlayStep", PlayStep);
  Vue.component("RecordStep", RecordStep);
  Vue.component("TimeDayStep", TimeDayStep);
  Vue.component("VoicemailStep", VoicemailStep);
  Vue.component("FaxStep", FaxStep);
  Vue.component("EntryStep", EntryStep);
  Vue.component("ExitStep", ExitStep);
  Vue.component("AustateStep", AustateStep);
  Vue.component("AupostcodeStep", AupostcodeStep);
  Vue.component("GotoStep", GotoStep);
  Vue.component("proportionalStep", proportionalStep);
  Vue.component("LoadBalancerStep", LoadBalancerStep);
  Vue.component("AulocationStep", AulocationStep);
  // Forms
  Vue.component("CallStepForm", CallStepForm);
  Vue.component("DateStepForm", DateStepForm);
  Vue.component("MenuStepForm", MenuStepForm);
  Vue.component("PlayStepForm", PlayStepForm);
  Vue.component("RecordStepForm", RecordStepForm);
  Vue.component("TimeDayStepForm", TimeDayStepForm);
  Vue.component("VoicemailStepForm", VoicemailStepForm);
  Vue.component("FaxStepForm", FaxStepForm);
  Vue.component("ExitStepForm", ExitStepForm);
  Vue.component("EntryStepForm", EntryStepForm);
  Vue.component("AustateStepForm", AustateStepForm);
  Vue.component("AupostcodeStepForm", AupostcodeStepForm);
  Vue.component("GotoStepForm", GotoStepForm);
  Vue.component("proportionalStepForm", proportionalStepForm);
  Vue.component("LoadBalancerStepForm", LoadBalancerStepForm);
  Vue.component("AulocationStepForm", AulocationStepForm);
}
