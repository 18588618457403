import { NumberingApiUrl } from "@/consts/configuration";
import { httpClient } from "@/helpers/httpClient";
import { CrudService } from "@/api";
const crudService = new CrudService(NumberingApiUrl);

export default {
  async listAllAndInvalid({ commit, state }, payload) {
    let search = "";
    if (payload.regionCode && payload.numberType) {
      search = ":search";
    }

    try {
      let params;
      const allSubs = [];
      do {
        const res = await httpClient.get(
          `${NumberingApiUrl}/projects/${
            payload.projectId
          }/regulatoryListings${search}?page_size=100${params ? `&page_token=${params}` : ""}`
        );
        allSubs.push(res.data.regulatoryListings);
        params = res.data.nextPageToken;
      } while (params !== "");
      commit("setAllAndInvalid", allSubs.flat());
      return allSubs.flat();
    } catch (error) {
      throw error;
    }
  },
  async listAll({ commit, state }, payload) {
    let search = "";
    let regionCode = "";
    let numberType = "";
    if (payload.regionCode && payload.numberType) {
      search = ":search";
      regionCode = `&regionCode=${payload.regionCode}`;
      numberType = `&numberType=${payload.numberType}`;
    }

    try {
      let params;
      const allSubs = [];
      do {
        const res = await httpClient.get(
          `${NumberingApiUrl}/projects/${
            payload.projectId
          }/regulatoryListings${search}?page_size=100${
            params ? `&page_token=${params}` : ""
          }${regionCode}${numberType}`
        );
        allSubs.push(res.data.regulatoryListings);
        params = res.data.nextPageToken;
      } while (params !== "");
      commit("setAll", allSubs.flat());
      return allSubs.flat();
    } catch (error) {
      throw error;
    }
  },
  async listResource({ commit, state }, to) {
    const payload = {
      projectId: to.params.projectId,
      regulatoryListingId: to.params.regulatoryListingId,
    };

    const search = "";
    const regionCode = "";
    const numberType = "";

    if (to.query.search && state.all.length > 0) {
      return;
    }

    try {
      let params;
      const allSubs = [];
      do {
        const res = await httpClient.get(
          `${NumberingApiUrl}/projects/${
            payload.projectId
          }/regulatoryListings${search}?page_size=100${
            params ? `&page_token=${params}` : ""
          }${regionCode}${numberType}`
        );
        allSubs.push(res.data.regulatoryListings);
        params = res.data.nextPageToken;
      } while (params !== "");
      commit("setAll", allSubs.flat());
      return allSubs.flat();
    } catch (error) {
      throw error;
    }
  },
  async getResource({}, to) {
    const payload = {
      projectId: to.params.projectId,
      regulatoryListingId: to.params.regulatoryListingId,
    };

    return crudService.getResource({
      resourceName: `projects/${payload.projectId}/regulatoryListings/${payload.regulatoryListingId}`,
      commit: "regulatoryListings/setResource",
    });
  },
  async createResource({}, payload) {
    return crudService.createResource(
      `projects/${payload.projectId}/regulatoryListings`,
      "Regulatory listings",
      { resource: payload.resource }
    );
  },
  async updateResource({}, payload) {
    return crudService.updateResource("Regulatory listing", {
      resourceName: `projects/${payload.projectId}/regulatoryListings/${payload.regulatoryListingId}`,
      resource: {
        displayName: payload.displayName,
      },
    });
  },
  async deleteResource({}, payload) {
    return crudService.deleteResource("Regulatory listing", {
      resourceName: `projects/${payload.projectId}/regulatoryListings/${payload.regulatoryListingId}`,
    });
  },
};
