import { VoiceApiUrl } from "@/consts/configuration";
import _ from "lodash";
import { httpClient } from "@/helpers/httpClient";
import apiFilter from "@/helpers/apiFilter";
import { CrudService } from "@/api";
import moment from "moment";
import defaultDate from "@/helpers/defaultDate";
const sipDomainsApi = new CrudService(VoiceApiUrl);

export default {
  resetSipDomains({ commit, state }, payload) {
    commit("resetSipDomains", "");
  },
  async getSipDomains({ commit, state, dispatch }, to) {
    const nextPageToken = state.sipDomains.nextPageToken;
    const projectId = to.params.projectId;
    let payload = {};
    if (nextPageToken) {
      payload = {
        nextPageToken,
        projectId,
      };
    } else {
      payload = {
        projectId,
      };
    }

    if (to.query.search && state.sipDomains.data.length > 0) {
      return;
    }

    const parameters = apiFilter(payload, null);
    try {
      const res = await httpClient.get(
        `${VoiceApiUrl}/projects/${projectId}/sipDomains/?page_size=100${parameters}`
      );
      commit("setSipDomains", res.data);
      return res.data;
    } catch (error) {
      throw error;
    }
  },
  async getSipDomain({ commit, state }, to) {
    try {
      const res = await httpClient.get(
        `${VoiceApiUrl}/projects/${to.params.projectId}/sipDomains/${to.params.sipDomainId}`
      );
      commit("setSipDomain", res.data);
      return res.data;
    } catch (error) {
      throw error;
    }
  },
  async createSipDomain({ commit, state }, payload) {
    commit("common/setLoading", true, { root: true });
    let channelLimitPayload = {};

    if (payload.channelLimit) {
      channelLimitPayload = {
        channelLimit: payload.channelLimit,
      };
    }
    try {
      const res = await httpClient.post(
        `${VoiceApiUrl}/projects/${payload.projectId}/sipDomains?sip_domain_id=${payload.sipDomainId}`,
        {
          displayName: payload.displayName,
          callTerminationMode: "PSTN_TRUNK",
          callTerminationRecordingTrigger: "DISABLED",
          type: payload.sipDomainType,
          ...channelLimitPayload,
        }
      );
      commit("common/setLoading", false, { root: true });
      return res.data;
    } catch (error) {
      commit("common/setLoading", false, { root: true });
      if (error) {
        if (error.code !== 6) {
          commit("common/setError", error, { root: true });
        } else {
          throw new Error(JSON.stringify(error.code));
        }
      } else {
        throw new Error();
      }
    }
  },
  async updateSipDomain({ dispatch, commit, state }, payload) {
    delete payload.sipDomain.domainOptional;
    delete payload.sipDomain.type;
    delete payload.sipDomain.teamsDirectRoutingStatus;
    delete payload.sipDomain.fqdn;
    const updateMask = Object.keys(payload.sipDomain);
    commit("common/setLoading", true, { root: true });
    let recordingUpdateMask = [];
    if (payload.recordingPayload) {
      recordingUpdateMask = Object.keys(payload.recordingPayload);
      recordingUpdateMask.forEach(
        (val, index) => (recordingUpdateMask[index] = `callRecording.${val}`)
      );
    }

    const aliases = payload.aliases;
    const users = payload.users;

    function createAlias(alias) {
      return dispatch("aliases/createResource", alias, { root: true });
    }

    function updateAlias(alias) {
      return dispatch("aliases/updateResource", alias, { root: true });
    }

    function createUser(user) {
      return dispatch("users/createResource", user, { root: true });
    }

    function updateUser(user) {
      return dispatch("users/updateResource", user, { root: true });
    }

    let aliasesResponse = null;
    let usersResponse = null;

    try {
      if (payload.sipDomainType !== "DOMAIN_TYPE_TEAMS_DIRECT_ROUTING") {
        aliasesResponse = await Promise.all(
          aliases.map(async (el) => {
            let aliasRes = null;
            if (el.deleted && el.name) {
              aliasRes = await dispatch(
                "aliases/deleteAlias",
                {
                  resourceName: el.name,
                },
                { root: true }
              );
              return aliasRes;
            }

            if (el.name && !el.deleted) {
              if (el.updated) {
                aliasRes = await updateAlias({
                  resourceName: el.name,
                  resource: {
                    displayName: el.displayName,
                    loadBalancerConfig: el.loadBalancerConfig,
                  },
                });
              }
            } else if (!el.deleted) {
              aliasRes = await createAlias({
                alias: el.aliasId,
                projectId: payload.projectId,
                sipDomainId: payload.sipDomainId,
                resource: el,
              });
            }
            return aliasRes;
          })
        );

        usersResponse = await Promise.all(
          users.map(async (el) => {
            let userRes = null;
            if (el.deleted && el.name) {
              userRes = await dispatch(
                "users/deleteResource",
                {
                  resourceName: el.name,
                },
                { root: true }
              );
              return userRes;
            }

            if (el.name && !el.deleted) {
              if (el.updated) {
                userRes = await updateUser({
                  resourceName: el.name,
                  password: el.password,
                });
              }
            } else if (!el.deleted) {
              userRes = await createUser({
                userId: el.userId,
                projectId: payload.projectId,
                sipDomainId: payload.sipDomainId,
                password: el.password,
              });
            }
            return userRes;
          })
        );
      }

      const res = await httpClient.patch(
        `${VoiceApiUrl}/projects/${payload.projectId}/sipDomains/${
          payload.sipDomainId
        }?update_mask=${updateMask.concat(recordingUpdateMask).join(",")}`,
        {
          ...payload.sipDomain,
          callRecording: { ...payload.recordingPayload },
        }
      );
      if (payload.bundlesEnabled) {
        if (payload.existingChannels || payload.existingChannels === 0) {
          await dispatch(
            "bundles/scaleBundle",
            {
              resourceName: `projects/${payload.projectId}/sipDomains/${payload.sipDomainId}/bundles/sip-channel-unlimited`,
              scale: payload.existingChannels,
            },
            {
              root: true,
            }
          );

          await dispatch(
            "bundles/getBundle",
            {
              resourceName: `projects/${payload.projectId}/sipDomains/${payload.sipDomainId}/bundles/sip-channel-unlimited`,
            },
            {
              root: true,
            }
          );
        }
      }

      await dispatch(
        "aliases/getAll",
        {
          projectId: payload.projectId,
          sipDomainId: payload.sipDomainId,
        },
        { root: true }
      );

      await dispatch(
        "users/getAll",
        {
          projectId: payload.projectId,
          sipDomainId: payload.sipDomainId,
        },
        { root: true }
      );

      commit("common/setLoading", false, { root: true });
      commit(
        "common/setSuccess",
        {
          data: "SIP Domain updated",
        },
        { root: true }
      );
      return { sipDomain: res.data, aliasesResponse, usersResponse };
    } catch (error) {
      commit("common/setLoading", false, { root: true });
      commit("common/setError", error, { root: true });
      throw error;
    }
  },
  async deleteSipDomain({ commit, state }, payload) {
    return sipDomainsApi.deleteResource("SIP Domain", {
      resourceName: `projects/${payload.projectId}/sipDomains/${payload.sipDomainId}`,
    });
  },
  async initializeSipDomainView({ commit, state, dispatch }, to) {
    try {
      const payload = {
        projectId: to.params.projectId,
        sipDomainId: to.params.sipDomainId,
      };
      dispatch("aliases/resetState", to, {
        root: true,
      });
      const sipDomain = await dispatch("sipDomains/getSipDomain", to, {
        root: true,
      });
      const sipDomainBundle = await dispatch(
        "bundles/getBundle",
        {
          resourceName: `projects/${payload.projectId}/sipDomains/${payload.sipDomainId}/bundles/sip-channel-unlimited`,
        },
        {
          root: true,
        }
      );

      const aliases = await dispatch("aliases/getAll", payload, { root: true });
      const users = await dispatch("users/getAll", payload, { root: true });
      return {
        sipDomainBundle,
        sipDomain,
        aliases,
        users,
      };
    } catch (err) {
      throw err;
    }
  },
  async verifyTeamsDirectRouting({ commit, state }, payload) {
    try {
      const res = await httpClient.post(
        `${VoiceApiUrl}/projects/${payload.projectId}/sipDomains/${payload.sipDomainId}:verifyTeamsDirectRouting?txt_record_value=${payload.textRecordValue}`
      );
      commit(
        "common/setSuccess",
        {
          data: "SIP Domain verified",
        },
        { root: true }
      );
      return res.data;
    } catch (error) {
      throw error;
    }
  },
  async getUsage({ commit, state, rootState }, payload) {
    if (rootState.common.abortController) {
      rootState.common.abortController.abort();
      commit("common/setAbortController", null, { root: true });
    }
    if (!payload.date) {
      return;
    }
    const filtersVal = payload.date ? payload.date : "month";
    const dateObject = {
      date: !Array.isArray(filtersVal) ? defaultDate[filtersVal] : filtersVal,
    };

    const url = `${VoiceApiUrl}/projects/${payload.projectId}/calls:generateOverviewReport`;
    try {
      const controller = new AbortController();
      commit("common/setAbortController", controller, { root: true });
      const res = await httpClient.post(
        url,
        {
          startDate: {
            year: moment(new Date(dateObject.date[0]).toISOString()).format("YYYY"),
            month: moment(new Date(dateObject.date[0]).toISOString()).format("MM"),
            day: moment(new Date(dateObject.date[0]).toISOString()).format("DD"),
          },
          endDate: {
            year: moment(new Date(dateObject.date[1]).toISOString()).format("YYYY"),
            month: moment(new Date(dateObject.date[1]).toISOString()).format("MM"),
            day: moment(new Date(dateObject.date[1]).toISOString()).format("DD"),
          },
        },
        {
          signal: controller.signal,
          timeout: 60000,
        }
      );
      commit("common/setAbortController", null, { root: true });
      commit("sipDomains/setOverview", res.data, { root: true });
      return res.data;
    } catch (error) {
      if (error.message !== "canceled") {
        commit("common/setAbortController", null, { root: true });
        commit("common/setError", error, { root: true });
      }
      throw error;
    }
  },
};
