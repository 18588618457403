import Vue from "vue";
import Vuetify from "vuetify/lib";
import BaseIcon from "@/common/display/BaseIcon";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#4384F5",
        secondary: "#424242",
        accent: "#82B1FF",
        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FFC107",
        background: "#F6F7F9",
      },
    },
  },
  icons: {
    values: {
      home: {
        component: BaseIcon, // you can use string here if component is registered globally
        props: {
          // pass props to your component if needed
          name: "home",
        },
      },
      numbers: {
        component: BaseIcon, // you can use string here if component is registered globally
        props: {
          // pass props to your component if needed
          name: "numbers",
        },
      },
      sms: {
        component: BaseIcon, // you can use string here if component is registered globally
        props: {
          // pass props to your component if needed
          name: "sms",
        },
      },
      voice: {
        component: BaseIcon, // you can use string here if component is registered globally
        props: {
          // pass props to your component if needed
          name: "phone",
        },
      },
      flow: {
        component: BaseIcon, // you can use string here if component is registered globally
        props: {
          // pass props to your component if needed
          name: "flow",
        },
      },
      analytics: {
        component: BaseIcon, // you can use string here if component is registered globally
        props: {
          // pass props to your component if needed
          name: "analytics",
        },
      },
      bin: {
        component: BaseIcon, // you can use string here if component is registered globally
        props: {
          // pass props to your component if needed
          name: "bin",
        },
      },
      boolean: {
        component: BaseIcon, // you can use string here if component is registered globally
        props: {
          // pass props to your component if needed
          name: "boolean",
        },
      },
      calendar: {
        component: BaseIcon, // you can use string here if component is registered globally
        props: {
          // pass props to your component if needed
          name: "calendar",
        },
      },
      chevrondown: {
        component: BaseIcon, // you can use string here if component is registered globally
        props: {
          // pass props to your component if needed
          name: "chevron-down",
        },
      },
      download: {
        component: BaseIcon, // you can use string here if component is registered globally
        props: {
          // pass props to your component if needed
          name: "download",
        },
      },
      email: {
        component: BaseIcon, // you can use string here if component is registered globally
        props: {
          // pass props to your component if needed
          name: "email",
        },
      },
      filter: {
        component: BaseIcon, // you can use string here if component is registered globally
        props: {
          // pass props to your component if needed
          name: "filter",
        },
      },
      graph: {
        component: BaseIcon, // you can use string here if component is registered globally
        props: {
          // pass props to your component if needed
          name: "graph",
        },
      },
      multiselect: {
        component: BaseIcon, // you can use string here if component is registered globally
        props: {
          // pass props to your component if needed
          name: "multi-select",
        },
      },
      pen: {
        component: BaseIcon, // you can use string here if component is registered globally
        props: {
          // pass props to your component if needed
          name: "pen",
        },
      },
      plus: {
        component: BaseIcon, // you can use string here if component is registered globally
        props: {
          // pass props to your component if needed
          name: "plus",
        },
      },
      settings: {
        component: BaseIcon, // you can use string here if component is registered globally
        props: {
          // pass props to your component if needed
          name: "settings",
        },
      },
      upload: {
        component: BaseIcon, // you can use string here if component is registered globally
        props: {
          // pass props to your component if needed
          name: "upload",
        },
      },
      valueinput: {
        component: BaseIcon, // you can use string here if component is registered globally
        props: {
          // pass props to your component if needed
          name: "value-input",
        },
      },
      cross: {
        component: BaseIcon, // you can use string here if component is registered globally
        props: {
          // pass props to your component if needed
          name: "cross",
        },
      },
      dots: {
        component: BaseIcon, // you can use string here if component is registered globally
        props: {
          // pass props to your component if needed
          name: "dots",
        },
      },
      voicemail: {
        component: BaseIcon, // you can use string here if component is registered globally
        props: {
          // pass props to your component if needed
          name: "voicemail",
        },
      },
      record: {
        component: BaseIcon, // you can use string here if component is registered globally
        props: {
          // pass props to your component if needed
          name: "record",
        },
      },
      play: {
        component: BaseIcon, // you can use string here if component is registered globally
        props: {
          // pass props to your component if needed
          name: "play",
        },
      },
      time: {
        component: BaseIcon, // you can use string here if component is registered globally
        props: {
          // pass props to your component if needed
          name: "time",
        },
      },
      date: {
        component: BaseIcon, // you can use string here if component is registered globally
        props: {
          // pass props to your component if needed
          name: "date",
        },
      },
      menu: {
        component: BaseIcon, // you can use string here if component is registered globally
        props: {
          // pass props to your component if needed
          name: "menu",
        },
      },
      call: {
        component: BaseIcon, // you can use string here if component is registered globally
        props: {
          // pass props to your component if needed
          name: "call",
        },
      },
      details: {
        component: BaseIcon, // you can use string here if component is registered globally
        props: {
          // pass props to your component if needed
          name: "details",
        },
      },
      duplicate: {
        component: BaseIcon, // you can use string here if component is registered globally
        props: {
          // pass props to your component if needed
          name: "duplicate",
        },
      },
      draft: {
        component: BaseIcon, // you can use string here if component is registered globally
        props: {
          // pass props to your component if needed
          name: "draft",
        },
      },
    },
  },
});
