import { SMS_MARKETING_API_URL } from "@/consts/configuration";
import { httpClient } from "@/helpers/httpClient";
import { CrudService } from "@/api";
const contactListsApi = new CrudService(SMS_MARKETING_API_URL);

export default {
  async listAll({ commit, state }, to) {
    try {
      let params;
      const allSubs = [];
      do {
        const res = await httpClient.get(
          `${SMS_MARKETING_API_URL}/projects/${to.params.projectId}/contactLists?page_size=100${
            params ? `&page_token=${params}` : ""
          }`
        );
        allSubs.push(res.data.contactLists);
        params = res.data.nextPageToken;
      } while (params !== "");
      commit("setAll", allSubs.flat());
      return allSubs.flat();
    } catch (error) {
      throw error;
    }
  },
  async listResource({}, payload) {
    return contactListsApi.listResource("contactLists", payload);
  },
  async getResource({ dispatch }, to) {
    const payload = {
      resourceName: `projects/${to.params.projectId}/contactLists/${to.params.contactListId}`,
      commit: "contactLists/setResource",
    };
    try {
      await contactListsApi.getResource(payload);
      await dispatch("contacts/listSubResource", to, { root: true });
    } catch (error) {
      throw error;
    }
  },
  async createResource({}, payload) {
    return contactListsApi.createResource(
      `projects/${payload.projectId}/contactLists`,
      "Contact list",
      {
        resource: { displayName: payload.displayName },
      }
    );
  },
  async updateResource({}, payload) {
    return contactListsApi.updateResource("Contact list", payload);
  },
  async deleteResource({}, payload) {
    const deleteContacts = payload.deleteContacts ? "?delete_contacts=1" : "";
    return contactListsApi.genericMethod({
      config: {
        method: "DELETE",
        url: `${payload.resourceName}${deleteContacts}`,
      },
      data: {
        contact: payload.contactName,
      },
      enableError: true,
      enableLoading: true,
      success: "Contact list deleted",
    });
  },
  async addContact({}, payload) {
    return contactListsApi.genericMethod({
      config: {
        method: "POST",
        url: `${payload.contactListName}:addContact`,
      },
      data: {
        contact: payload.contactName,
      },
      enableError: false,
      enableLoading: false,
      success: "Contact added",
    });
  },
  async removeContact({}, payload) {
    return contactListsApi.genericMethod({
      config: {
        method: "POST",
        url: `${payload.contactListName}:removeContact`,
      },
      data: {
        contact: payload.contactName,
      },
      enableError: false,
      enableLoading: false,
      success: "Contact removed",
    });
  },
  async getPropertyUsage({}, payload) {
    return contactListsApi.genericMethod({
      config: {
        method: "POST",
        url: `${payload.contactListName}:getPropertyUsage`,
      },
      data: {},
      enableError: false,
      enableLoading: false,
    });
  },
};
