import { getTimeZones } from "@vvo/tzdb";
const timeZones = getTimeZones();

export default function () {
  const matchedTimezone = timeZones.filter(
    (el) => Intl.DateTimeFormat().resolvedOptions().timeZone === el.name
  );

  // If no matched timezone is found then return the below default.
  if (matchedTimezone.length === 0) {
    return [{ countryCode: "AU", name: "Australia/Brisbane" }];
  }

  return {
    ...timeZones.filter((el) => Intl.DateTimeFormat().resolvedOptions().timeZone === el.name),
  };
}
