export default {
  resetState(state, payload) {
    state.message = {
      price: {},
      recordings: [],
    };
    state.messages = {
      data: [],
      nextPageToken: "",
    };
    state.unsubscribers = [];
  },
  setUnsubscribers(state, payload) {
    state.unsubscribers = payload;
  },
};
