// User Routes
const NotFound = () => import("@/views/NotFound");
const AuthLogin = () => import("@/views/Auth/AuthLogin");
const AuthPassword = () => import("@/views/Auth/AuthPassword");
const AuthChangePassword = () => import("@/views/Auth/AuthChangePassword");

export default [
  {
    path: "/change-password",
    name: "ChangePassword",
    component: AuthChangePassword,
    meta: {
      pageTitle: "Change password",
      layout: "LayoutEmpty",
    },
  },
  {
    path: "/login",
    name: "Login",
    component: AuthLogin,
    meta: {
      pageTitle: "Login",
      layout: "LayoutEmpty",
    },
  },
  {
    path: "/password",
    name: "Password",
    component: AuthPassword,
    meta: {
      pageTitle: "Reset password",
      layout: "LayoutEmpty",
    },
  },
  {
    path: "*",
    name: "NotFound",
    component: NotFound,
    meta: {
      pageTitle: "Not found",
    },
  },
];
