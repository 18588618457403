const canceled = Symbol("canceled");

/**
 * All events fired by draggable inherit this class. You can call `cancel()` to
 * cancel a specific event or you can check if an event has been canceled by
 * calling `canceled()`.
 * @abstract
 * @class AbstractEvent
 * @module AbstractEvent
 */
export default class AbstractEvent {
  /**
   * Event type
   * @static
   * @abstract
   * @property type
   * @type {String}
   */
  public static type: string = "event";

  /**
   * Event cancelable
   * @static
   * @abstract
   * @property cancelable
   * @type {Boolean}
   */
  public static cancelable = false;
  public data: any;

  /**
   * AbstractEvent constructor.
   * @constructs AbstractEvent
   * @param {object} data - Event data
   */
  constructor(data) {
    this[canceled] = false;
    this.data = data;
  }

  /**
   * Read-only type
   * @abstract
   * @return {String}
   */
  get type() {
    // @ts-ignore
    return this.constructor.type;
  }

  /**
   * Read-only cancelable
   * @abstract
   * @return {Boolean}
   */
  get cancelable() {
    // @ts-ignore
    return this.constructor.cancelable;
  }

  /**
   * Cancels the event instance
   * @abstract
   */
  public cancel() {
    this[canceled] = true;
  }

  /**
   * Check if event has been canceled
   * @abstract
   * @return {Boolean}
   */
  public canceled() {
    return Boolean(this[canceled]);
  }

  /**
   * Returns new event instance with existing event data.
   * This method allows for overriding of event data.
   * @param {Object} data
   * @return {AbstractEvent}
   */
  public clone(data) {
    // @ts-ignore
    return new this.constructor({
      ...this.data,
      ...data,
    });
  }
}
