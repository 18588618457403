export default {
  user(state) {
    return state.user;
  },
  projects(state) {
    return state.projects;
  },
  projectById: (state) => (name) => {
    return state.projects.find((project) => project.name === name);
  },
  checkPermissions: (state) => (permission) => {
    if (state.permissions.permissions) {
      return state.permissions.permissions.includes(permission);
    } else {
      return false;
    }
  },
};
