const voiceStateOptions = [
  {
    displayName: "Queued",
    value: "QUEUED",
  },
  {
    displayName: "Starting",
    value: "STARTING",
  },
  {
    displayName: "Ringing",
    value: "RINGING",
  },
  {
    displayName: "In progress",
    value: "IN_PROGRESS",
  },
  {
    displayName: "Completed",
    value: "COMPLETED",
  },
  {
    displayName: "Failed",
    value: "FAILED",
  },
  {
    displayName: "Not answered",
    value: "NOT_ANSWERED",
  },
  {
    displayName: "Busy",
    value: "BUSY",
  },
  {
    displayName: "Not accepted",
    value: "NOT_ACCEPTED",
  },
];

const voiceDirectionOptions = [
  {
    displayName: "Outgoing",
    value: "OUTGOING",
  },
  {
    displayName: "Incoming",
    value: "INCOMING",
  },
];

export { voiceStateOptions, voiceDirectionOptions };
