const SettingsGeneral = () => import("@/views/Settings/SettingsGeneral");
const SettingsUsers = () => import("@/views/Settings/SettingsUsers");
const SettingsAccount = () => import("@/views/Settings/SettingsAccount");

export default [
  {
    path: "/projects/:projectId/settings/general",
    name: "Settings General",
    component: SettingsGeneral,
    meta: {
      pageTitle: "Settings",
      featureFlipping: {
        key: "settings.view",
        default: false,
      },
      headerImg: "./header-voice.png",
      layout: "LayoutMain",
      tabs: "settings",
      dispatchAction: "user/initializeSettingsView",
    },
  },
  {
    path: "/projects/:projectId/settings/users",
    name: "Settings Users",
    component: SettingsUsers,
    meta: {
      pageTitle: "Users",
      featureFlipping: {
        key: "settings.users",
        default: false,
      },
      headerImg: "./header-voice.png",
      layout: "LayoutMain",
      tabs: "settings",
      dispatchAction: "iam/initializeUsersView",
    },
  },
  {
    path: "/projects/:projectId/settings/account",
    name: "Settings Account",
    component: SettingsAccount,
    meta: {
      pageTitle: "Account",
      featureFlipping: {
        key: "settings.view",
        default: false,
      },
      headerImg: "./header-voice.png",
      layout: "LayoutMain",
      tabs: "account",
      dispatchAction: "user/initializeSettingsView",
    },
  },
];
